import { fetchLogout } from "../store/actions/auth.actions";
import { getEnv, isOktaLogin } from "./urls";

const { fetch: originalFetch } = window;

const getAuthHeaders = (state) => {
  return {
    token: state?.auth?.token || "",
    isAuth: state?.auth?.isAuth || "",
  };
};

export default {
  setup: (store) => {
    window.fetch = async (...args) => {
      try {
        let apiUrl = getEnv().url;
        let [resource, config] = args;
        //TODO: Need to move setting up of okta-accesstoken at higher level.
        const isFaqPage = `${getEnv().url}/content/faqs` == resource;
        const isTermsConditionsPage = `${getEnv().url}/content?tags[]=termsCondition` == resource;

        // request interceptor here
        let { isAuth, token } = getAuthHeaders(store.getState());
        if (config?.headers && isAuth && token) {
          if (!isOktaLogin) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          if (isOktaLogin && !isFaqPage && !isTermsConditionsPage) {
            config.headers["okta-accesstoken"] = token;
          }
        }
        let response = await originalFetch(resource, config);
        // response interceptor here
        if (resource?.match(apiUrl) && !response.ok) {
          // error handling
          let data = await response.json()
          if (!data.success && data?.msg.match(/token is expired|token is not valid|provide JWT Token/i)) {
            store.dispatch(fetchLogout())
            window.location.reload();
          }
          return Promise.reject(data);
        }
        if (resource?.match(apiUrl) && response?.ok) {
          let data = await response.json()
          if (data?.msg && data?.msg?.match(/token is expired|token is not valid|provide jwt token/i)) {
            store.dispatch(fetchLogout())
            window.location.reload();
          }
          if (data) {
            response.json = async () => data;
          }
        }
        return response;
      } catch (error) {
        return Promise.reject(error);
      }
    };
  },
};
