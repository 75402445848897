import makeStyles from "@mui/styles/makeStyles";
import { colors, fontSizes } from "../../styles/variables";
export const useFullModalStyles = makeStyles((theme) => ({
  headingText: {
    fontSize: `${fontSizes.font26}`,
    lineHeight: "29px",
  },
  button1: {
    [theme.breakpoints.down("sm")]: {
      width: "15rem",
    },
    width: "20rem",
    maxWidth: "32rem",
    padding: "15px 0px",
    background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
    boxShadow: theme.customShadow.shad2,
    borderRadius: "33px",
    // padding: "10px 150px",
    fontSize: `${fontSizes.font14}`,
    fontWeight: "bold",
    color: `${colors.color25}`,
    textTransform: "uppercase",
    "&:hover": {
      background: `${colors.color25}`,
      color: `${theme.palette.common.white}`,
    },
  },
  helperText: {
    textAlign: "center",
  },
  paper: {
    [theme.breakpoints.up("sm")]: {
      // position: "absolute",
      // top: "50%",
      // left: "50%",
      // transform: "translate(-50%, -50%)",
      width: "100vh !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "50vh !important",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important",
      margin: "0px",
      width: "100% !important",
      height: "100%",
    },
    paddingBottom: "50px",
    background: `${colors.color26}`,
    color: `${theme.palette.common.white}`,
  },
  buttonText: {
    fontSize: `${fontSizes.font14}`,
    color: `${theme.palette.common.white}`,
    textTransform: "uppercase",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  bottomText: {
    color: `${theme.palette.success.main}`,
    fontSize: `${fontSizes.font17}`,
    lineHeight: "25px",
    textAlign: "center",
  },
  bottomContainer: {
    minHeight: "50vh",
    [theme.breakpoints.between("sm", "md")]: {
      minHeight: "40vh",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "80vh",
    },
  },
}));

export const useDefaultModalStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
    borderRadius: "24px",
    opacity: 1,
    minWidth: "1000px",
    maxHeight: "80vh",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      minWidth: "750px",
    },
    // padding: "1rem 1.5rem 2rem 2rem"
  },
  closeIcon: {
    cursor: "pointer",
    // [theme.breakpoints.down("sm")]: {
    //   display: "none",
    // },
  },
  title: {
    fontSize: `${fontSizes.font23}`,
    lineHeight: "30px",
    fontWeight: "bold",
    color: `${colors.color6}`,
    marginTop: "1rem",
    marginLeft: "4rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: `${fontSizes.font18}`,
      lineHeight: "24px",
    },
  },
  titleContainer: {
    padding: "1rem 1.5rem 0rem 4rem !important",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1.5rem 0rem 2rem !important",
    },
  },
}));
//Styles for add product Modal
export const useAddProductModalStyles = makeStyles((theme) => ({
  dialogContainer: {
    "&  .MuiPaper-root": {
      maxWidth: "70%",
      height: "90%",
      borderRadius: "20px !important",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0px !important",
        maxWidth: "100%",
        height: "100%",
      },
    },
  },
  dialogHeader: {
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "0px 3px 6px #0000001A",
    },
  },
  title: {
    fontSize: `${fontSizes.font26}`,
    lineHeight: "30px",
    fontWeight: "bold",
    color: "#1E1E1E",
    [theme.breakpoints.down("sm")]: {
      fontSize: `${fontSizes.font19}`,
      lineHeight: "24px",
      fontWeight: "900",
      textAlign: "center",
    },
  },
  titleContainer: {
    padding: "1rem 1.5rem 0rem 4rem !important",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1.5rem 0rem 2rem !important",
    },
  },
  closeIcon: {
    cursor: "pointer",
  },
}));

//Styles for cancel order modal
export const useCancelModalStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1rem",
    fontWeight: "bold"
  },
  closeIcon: {
    cursor: "pointer"
  },
  outlinedButton: {
    width: "100px",
    height: "36px",
    border: "2px solid #0047BA",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dialogContainer: {
    "&  .MuiPaper-root": {
      maxWidth: "30%",
      height: "35%",
      borderRadius: "20px !important",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0px !important",
        maxWidth: "100%",
        height: "100%",
      },
    },
  },
  dialogContent: {
    padding: "1rem 3rem",
    height: "100%"
  },
  gridContainer: {
    [theme.breakpoints.up('sm')]: {
      height: "100%"
    }
  }
}))