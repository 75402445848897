import { helpActionTypes } from "../actions/help.actions";

export default function helpReducer(state = null, action) {
  switch (action.type) {
    case helpActionTypes.FETCH_HELP_DATA_SUCCESS:
       return action.data
    default:
      return state;
  }
}
