export const getAccountData = (state) =>
  state.myAccount && state.myAccount.myAccountDetails
    ? state.myAccount.myAccountDetails
    : null;
//This selector is speicific to address
export const getCustomerAddress = (state) => 
  state.myAccount && state.myAccount.myAccountDetails?
    state.myAccount.myAccountDetails.customers?.contact[0]?.addresses : ""

export const minimumCartValue = (state) =>
  state.myAccount && state.myAccount.myAccountDetails ?
    state.myAccount.myAccountDetails?.customers?.contact[0]?.minimumOrderAmount : ""

export const getStoreId = (state) => {
  return state.myAccount?.myAccountDetails?.customers?.contact[0]?.storeId ?
    state.myAccount?.myAccountDetails?.customers?.contact[0]?.storeId : null
}