import { Box, FormControl, Grid, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import React from "react";
import { rewardDetailStyles } from "./DeliveryPreferences.styles";


const RewardDetail = (props) => {
    const { data } = props;
    const [tabState, setTabState] = React.useState(0);

    const tabList = [{
        id: 1,
        label: "Email"
    },
    {
        id: 2,
        label: "Text"
    },
    {
        id: 3,
        label: "Mailing Address"
    }];

    const addressList = [{
        id: 1,
        text: "Carlos Gaich, Santa Rosa, La Pampa, Argentina"
    },
    {
        id: 2,
        text: "Santa Rosa, La Pampa, Argentina"
    }
    ]

    const handleChange = (e, newValue) => {
        // console.log("25", e.target)
        console.log("26", newValue)
        setTabState(newValue);
    };

    function TabPanel(props) {

        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const AntTab = withStyles((theme) => ({}))((props) => (
        <Tab disableRipple {...props} />
    ));

    const classes = rewardDetailStyles();
    return (

        <Grid container direction="row" className={classes.container}
            columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 2 }}
        >
            <Grid item md={4} xs={12}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={3}>
                        <img width="100%" src={data.image} />
                    </Grid>
                    <Grid item md={6} xs={9} className={classes.imageTitle}>
                        {data.materialName}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={7} xs={12}>
                <Grid container direction="column" spacing={4} rowGap="20px">
                    <Grid item md={12} xs={12} className={classes.tabular}>
                        <Tabs
                            value={tabState}
                            onChange={handleChange}
                            TabIndicatorProps={{ style: { display: "none" } }}
                        >
                            {tabList.map((tab) => {
                                return (
                                    <AntTab
                                        label={tab.label}
                                        {...a11yProps(tab.id)}
                                        className={classes.tab}
                                    />
                                );
                            })}
                        </Tabs>
                        {tabList.map((tab) => {
                            return (
                                <TabPanel
                                    value={tabState}
                                    index={tab.id}
                                    className={classes.tabPanel}
                                // onClick={handleTabChange}
                                >
                                </TabPanel>
                            );
                        })}
                    </Grid>
                    {(tabState === 0 || tabState === 1) &&
                        <Grid item md={12} xs={12} className={classes.textField} style={{ padding: "8px 16px 8px 16px" }}>
                            {/* <FormControl fullWidth> */}
                            <TextField
                                fullWidth
                                variant="standard"
                                // variant="filled"
                                hiddenLabel
                                defaultValue="Normal"
                                style={{ height: "12px", display: "contents" }}
                                placeholder="Enter email address"
                                InputProps={{ disableUnderline: true }}
                            />
                            {/* </FormControl> */}
                        </Grid>
                    }
                    {
                        tabState === 2 &&
                        <Grid item md={12} xs={12} style={{ paddingTop: "8px" }}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                sx={{
                                    border: "1px solid #9EBDE6",
                                    borderRadius: "10px"
                                }}
                                size="small"
                            >
                                <Select className={classes.select} sx={{ height: "50px" }}>

                                    {addressList.map((item) => (
                                        <MenuItem key={item.id} value={item.text} className={classes.menuItem}>
                                            {item.text}
                                        </MenuItem>
                                    ))
                                    }
                                    {/* // <MenuItem style={{ margin: "0rem 1rem 0rem 1rem", borderBottom: "1px solid #000", minHeight: "40px" }} value={90}>Last 90 days</MenuItem>
                                // <MenuItem value={60}>Last 60 days</MenuItem>
                                // <MenuItem value={30}>Last 30 days</MenuItem> */}
                                </Select>
                            </FormControl>

                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid >
    )
}

export default RewardDetail;