import React, { useState, useEffect, useContext } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { ButtonBase, Button, Menu, MenuItem, useMediaQuery } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import { useGetCartHook } from "../../hooks/useGetCart.hook";
import CustomButton from "../common/button/Button.component";
import MarginWrapper from "../styled/MarginWrapper.styled";
import SingleColumn from "../styled/SingleColumn.styled";
import { useStyles } from "./Appbar.styles";
import CartIconButton from "./CartIconButton.component";
import MobileDrawer from "./Drawer.component";
import { NavList, SubMenuList } from "./NavList.component";
import { useGetCartApiHook } from "../../hooks/useGetCartApi.hook";
import { getEnv } from "../../utils/urls";
import { useSelector } from "react-redux";
import { getHeaderNavData } from "../../store/selectors/navItems.selector";
import LanguageMenu from "../common/language/LanguageSelector.component";
import { LocalContext } from "../../LocalProvider";
import { getAuthToken } from "../../store/selectors/auth.selector";
import { mediaQueries} from "../../styles/variables";


export default function Appbar({ isAuth }) {
  const {translate} = useContext(LocalContext);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [checkedSubMenu, setCheckedSubMenu] = useState(false);
  const [selectedSubMenu, setSelectedSubMenu] = useState([]);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const siteId = getEnv().siteId;
  const [lang, setLang] = useState({name: "English",value: "en"})
  useGetCartApiHook();
  const { cartTotalCount } = useGetCartHook();
  const headerData = useSelector(getHeaderNavData);
  const token = useSelector(getAuthToken);

  let isMobile = useMediaQuery(`${mediaQueries.belowiPad}`, {
    noSsr: true,
  });


  // options for profile menu dropdown
  const profileOptions = [
    { name: translate('mx.whatsapp.my_account'), value: "/my-account" }, // My Account
    { name: translate('mx.whatsapp.logout'), value: "logout" }, // Logout
    { name: translate('mx.whatsapp.help'), value: "help" }, // Help
  ];
  
  
  // change selected language
  const handleChangeLang = (item) => {
    setLang(prev => (
      {...prev, name: item.name, value: item.value}
    ))
  }

  // toggle visibility of mobile drawer menu
  const toggleDrawer = (e='', value) => {
    setOpen(value);
  };

  const handleChange = (value, subMenu) => {
    // console.log('handleChange', value, subMenu);
    setChecked(value);
    setSelectedSubMenu(subMenu);
  };

  const onSubMenuClick = (value) => {
    setCheckedSubMenu(value);
  };

  // handle navbar logo click
  const onLogoClick = () => {
    if(token){
      navigate("/home")
    } else {
      navigate("/welcome");
    }
  };

  return (
    
    <MuiAppBar position="static" className={classes.appbar} sx={ isMobile ? {boxShadow: "0px 3px 6px #00000021" } : {boxShadow: !!checked && selectedSubMenu?.length? "none": "0px 3px 6px #00000021"}}>
      <SingleColumn className={classes.singleColumn}>
        <Toolbar sx={{ paddingX: { xs: 0, md: "3rem", xl: "6rem" } }}>

          {/** ======================================================== Mobile Drawer menu ======================================================== */}
          <Box
            sx={{ display: { xs: "flex", md: "none" }, width: "100%" }}
            justifyContent={isAuth ? "space-between" : "center"}
            alignItems="center"
          >
            {isAuth && (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e)=>toggleDrawer(e, true)}
                color="inherit"
                sx={{ color: "#000" }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <ButtonBase disableRipple onClick={onLogoClick}>
              <img
                src="/images/pepsico-logo-bw.png"
                className={classes.mobileLogo}
                alt="PepsiCo"
                width="166px"
              />
            </ButtonBase>
            <MobileDrawer 
              open={open} 
              toggleDrawer={toggleDrawer} 
              profileOptions={profileOptions} 
              headerData={headerData}
              lang={lang}
              handleChangeLang={handleChangeLang} 
            />
            {isAuth && <CartIconButton cartTotalCount={cartTotalCount} />}
          </Box>
          {/** ======================================================== Mobile Drawer menu ======================================================== */}


          {/** Web Header menu */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ButtonBase disableRipple onClick={onLogoClick}>
              <img
                src="/images/pepsico-logo-bw.png"
                className={classes.desktopLogo}
                alt="PepsiCo"
                style={{width: '166px', height: '39px'}}
              ></img>
            </ButtonBase>

            <Box display="flex" alignItems="center">
              <Box className={classes.topBtn} marginTop={2}> 
                <LanguageMenu/>
              </Box>


              {(isAuth && siteId === "7") && (
                <>
                  <CustomButton disabled className={classes.topBtn}>
                    <span
                      style={{ color: "#616161", textTransform: "capitalize" }}
                    >
                      Credit
                    </span>{" "}
                    &nbsp; COP$ 20,000.00
                  </CustomButton>
                  <CustomButton disabled className={classes.topBtn}>
                    12,072 &nbsp;
                    <span
                      style={{ color: "#616161", textTransform: "lowercase" }}
                    >
                      pts
                    </span>
                  </CustomButton>
                </>
              )}
            </Box>
          </Box>
        </Toolbar>
        <Toolbar
          className={classes.toolbar}
          sx={{
            paddingX: { xs: 0, md: "3rem", xl: "6rem" },
          }}
        >
          <NavList
            selected={checked}
            handleSelected={handleChange}
            isAuth={isAuth}
            setSelectedSubMenu={setSelectedSubMenu}
            setCheckedSubMenu={setCheckedSubMenu}
            cartTotalCount={cartTotalCount}
            profileOptions={profileOptions}
          />
        </Toolbar>
      </SingleColumn>


      {/** ======================================================== Sub Menu Links ======================================================== */}
      <Collapse
          in={ (checked && selectedSubMenu?.length) ? true : false }
          className={classes.subMenu}>
        <Paper className={classes.paper}>
          <SingleColumn className={classes.singleColumn}>
            <MarginWrapper>
              {
                <SubMenuList
                  subMenuList={selectedSubMenu}
                  selected={checkedSubMenu}
                  setSelectedSubMenu={onSubMenuClick}
                />
              }
            </MarginWrapper>
          </SingleColumn>
        </Paper>
      </Collapse>
      {/** ======================================================== Sub Menu Links ======================================================== */}


    </MuiAppBar>
  );
}
