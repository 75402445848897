import { welcomePageActionTypes } from '../actions/welcomePage.actions';

export default function welcomePageReducer(state = null, action){
    switch(action.type) {
        case welcomePageActionTypes.SET_WELCOME_PAGE_CONTENT:
            return { content: action.data};
        default:
            return state;

    }
}
