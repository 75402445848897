import { getMethod } from "../../utils/api";
import { deliveryEstimateAPIUrl, holidayListAPIUrl } from "../../utils/urls";
export const deliveryEstimateActionTypes = {
    ESTIMATED_DELIVERY_DATE_SUCCESS: "ESTIMATED_DELIVERY_DATE_SUCCESS",
    ESTIMATED_DELIVERY_DATE_FAILURE: "ESTIMATED_DELIVERY_DATE_FAILURE",
    HOLIDAY_LIST_SUCCESS: "HOLIDAY_LIST_SUCCESS",
    HOLIDAY_LIST_FAILURE: "HOLIDAY_LIST_FAILURE"
}


const fetchEstimatedDeliveryDateSuccessAction = (data) => {
    return {
        type: deliveryEstimateActionTypes.ESTIMATED_DELIVERY_DATE_SUCCESS,
        data,
    }
}

const fetchHolidayListSuccessAction = (data) => {
    return {
        type: deliveryEstimateActionTypes.HOLIDAY_LIST_SUCCESS,
        data,
    }
}

export const fetchEstimatedDeliveryDateAction = (storeName) => async (dispatch) => {
    // dispatch(loadingStart());
    try {
        let data = await getMethod(`${deliveryEstimateAPIUrl}?storeId=${storeName}`);
        dispatch(fetchEstimatedDeliveryDateSuccessAction(data));
        return data;
    }
    catch (error) {
        console.log(error);
    }
}

export const fetchHolidayListAction = () => async (dispatch) => {
    try {
        let data = await getMethod(holidayListAPIUrl);
        dispatch(fetchHolidayListSuccessAction(data));
        return data;
    }
    catch (error) {
        console.log(error);
    }
}