import { CircularProgress } from "@mui/material";
import React from "react";
import { StyledButton } from "./Button.styles";

export default function Button({
  children,
  onClick,
  type = "button",
  disabled,
  isLoading = false,
  variant = "contained",
  className = "",
  styleChange,
  loaderSize
}) {
  const handleClick = (e) => {
    typeof onClick === "function" && onClick(e);
  };
  return (
    <StyledButton
      onClick={handleClick}
      sx={styleChange}
      variant={variant}
      type={type}
      disabled={disabled}
      className={className}
    >
      {!isLoading && children}
      {isLoading && <CircularProgress color="primary" size={loaderSize || 30} />}
    </StyledButton>
  );
}
