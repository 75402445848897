import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "block",
    borderRadius: "1.25rem",
    fontSize: "0.8rem",
    boxShadow: theme.customShadow.shad1,
    border: "0.1px solid #ececec",
    overflow: "hidden",
    marginBottom: "2.1875rem",
    padding: "3rem 4rem 2rem 4rem",
    "& div:last-child": {
      "& .MuiGrid-root": {
        [theme.breakpoints.down("md")]: {
          padding: "5px 12px",
        },
        "& .MuiPaper-root": {
          [theme.breakpoints.down("md")]: {
            padding: "2px 8px",
          },
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 1rem 1rem 1rem",
    },
  },
  imgGrid: {
    margin: "auto 0",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "0px !important",
    },
  },
  img: {
    maxWidth: "100%",
    paddingLeft: "0px !important",
    maxHeight: "80px",
  },
  productQuantity: {
    display: "inline-block",
    width: "51px",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      width: "51px",
      margin: "0 0.2rem",
    },
    "& > div": {
      "&:before": {
        borderBottomStyle: "none !important",
      },
    },
    "& input": {
      height: "33px",
      padding: "0px",
      textDecoration: "none",
      border: "1px solid #C4C4C4",
      textAlign: "center",
      fontSize: "16px",
      borderRadius: "10px",
      "&:hover": {
        textDecoration: "none",
      },
      "&::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "&::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
    },
  },
  rewardContainer: {
    paddingBottom: "1rem",
    // alignItems: "center",
    "&:not(:first-child)": {
      marginTop: "2rem",
    },
    "&:last-child": {
      borderBottom: "none !important",
    },
    "&:not(:last-child)": {
      borderBottom: "1px solid #E6E6E6",
    },
    "&:last-child": {
      marginBottom: "1rem",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "0",
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0",
    },
  },
  actionWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: props => props.isProduct && props.alignStart ? "flex-start" : props.isProduct ? "center" : "flex-end",
    marginTop: "5px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  rewardTitle: {
    margin: "5px 0",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#5c5c5c",
  },
  rewardPoints: {
    color: "#5c5c5c",
    margin: "5px 0",
    [theme.breakpoints.down("sm")]: {
      color: "#0DAF00",
      fontSize: "13px",
      fontWeight: "bold",
    },
  },
  icon: {
    color: "#0047BA",
    fontSize: "24px",
  },
  iconButton: {
    "&:hover": {
      background: "transparent",
    },
  },
  productCodeText: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#262626",
    lineHeight: "19px",
  },
  productCodeNumber: {
    fontSize: "14px",
    color: "#262626",
    lineHeight: "19px",
  },
  productPoints: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#0DAF00",
    lineHeight: "19px",
    margin: "0.3rem 0",
  },
  cartEmptyMessage: {
    margin: "5px 0",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#5c5c5c",
    textAlign: "center",
    width: "100%",
  },
  continueButton: {
    color: "#002791",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "28px",
    textTransform: "capitalize",
    marginTop: "1rem",
    marginBottom: "2rem",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  /**New styles for productcard list */
  productCardList: {
    marginBottom: "2rem",
    borderTop: "1px solid #DBDBDB"

    // "& > div:first-child": {
    //   borderTop: "none",
    // }
  },
}));

export const useTabsStyle = makeStyles((theme) => ({
  tabs: {
    marginTop: "2rem",
  },
  tab: {
    backgroundColor: ({ active }) => (active ? "#EAEAEA" : "transparent"),
    borderRadius: "19px",
    padding: "0px 45px",
    height: "43px",
    minHeight: "43px",
    fontSize: "20px",
    lineHeight: "29px",
    color: ({ active }) => (active ? "#313030" : "#808080"),
    fontWeight: "bold",
    textTransform: "none",
    "&.Mui-selected": {
      color: "#313030",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 30px",
    },
  },
}));
