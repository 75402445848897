import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useOktaAuth } from "@okta/okta-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalContext } from "../../LocalProvider";
import { fetchLogout } from "../../store/actions/auth.actions";
import { getHeaderNavData } from "../../store/selectors/navItems.selector";
import { isOktaLogin } from "../../utils/urls";
import { useStyles } from "./Appbar.styles";
import CartIconButton from "./CartIconButton.component";
import ProfileMenu from "./ProfileMenu.component";

const hideRegisterRoutes = (pathname) => {
  const authRoutes = [
    "/login",
    "/register",
    "/confirm-code",
    "/user-details",
    "/create-password",
    "/forgot-password",
  ];
  return authRoutes.indexOf(pathname) !== -1;
};

export const NavList = ({
  selected,
  handleSelected,
  isAuth,
  setSelectedSubMenu,
  setCheckedSubMenu,
  cartTotalCount,
}) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [active, setActive] = useState({});
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const headerData = useSelector(getHeaderNavData);
  // const language = useSelector(selectedLanguage);
  const { selectedLocaleLang, translate } = useContext(LocalContext);

  const ProfileOptions = [
    { name: translate("mx.whatsapp.my_account"), value: "/my-account" },
    { name: translate("mx.whatsapp.logout"), value: "logout" },
    { name: translate("mx.whatsapp.help"), value: "help" },
  ];

  const hideNavMenu = useMemo(
    () => hideRegisterRoutes(location.pathname),
    [location.pathname]
  );

  useEffect(() => {
    if (headerData) {
      const { pathname } = location;
      let currMenuName = null;
      if (!hideNavMenu) {
        let currentMenu = headerData.find((menu) => {
          if (menu.subItems?.length) {
            let thisMenu = menu.subItems.find(
              (subMenu) => subMenu.path === pathname
            );
            currMenuName = thisMenu?.path || null;
            return !!thisMenu;
          }
          if (!menu.subItems?.length) {
            return menu.path === pathname;
          }
        });
        if (currentMenu) {
          setActive(currentMenu);
          handleSelected(currentMenu?.name, currentMenu?.subItems);
          if (currentMenu?.subItems?.length) {
            setSelectedSubMenu(currentMenu.subItems);
            setCheckedSubMenu(currMenuName);
          } else {
            setSelectedSubMenu([]);
            setCheckedSubMenu(null);
          }
        }
      }
    }
  }, [headerData, location.pathname]);

  const onSelect = (item, subItems) => {
    if (
      item.path &&
      (item.title["en"] !== "Shop" || item.title["en"] !== "Rewards")
    ) {
      return navigate(item.path);
    }
    handleSelected(selected === item.name ? false : item.name, subItems);
  };
  const onLogin = () => {
    navigate("/login");
  };

  const onAccountMenuChange = async (value) => {
    if (value === "logout") {
      if (isOktaLogin) {
        await oktaAuth.signOut({
          clearTokensBeforeRedirect: true,
          revokeAccessToken: true,
          revokeRefreshToken: true,
          accessToken: authState.accessToken,
        });
        dispatch(fetchLogout());
      }
      navigate("/");
      return window.location.reload();
    }
    if (value && value !== "logout") {
      navigate(value);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: "none", md: hideNavMenu ? "none" : "flex" },
        justifyContent: "space-between",
      }}
    >
      {headerData && (
        <Box sx={{ display: "flex" }}>
          {headerData.map(
            (item) =>
              item.title["en"] !== "language" &&
              isAuth && (
                <Button
                  className={`${classes.leftLinks} ${
                    active.name === item.name && isAuth && classes.activeLink
                  }`}
                  onClick={() => onSelect(item, item.subItems || [])}
                  key={item.name}
                >
                  {/* {item.title[language]} */}
                  {item.title[selectedLocaleLang.toLowerCase()]}
                  {item.subItems?.length > 0 && <ArrowDropDownIcon />}
                </Button>
              )
          )}
        </Box>
      )}
      <Box sx={{ display: "flex" }}>
        {isAuth && (
          <Box marginTop={2} marginRight={3}>
            <ProfileMenu
              options={ProfileOptions}
              onChange={onAccountMenuChange}
              defaultValue={"Username"}
            />
          </Box>
        )}
        {isAuth && <CartIconButton cartTotalCount={cartTotalCount} />}
      </Box>
    </Box>
  );
};

export const SubMenuList = ({ subMenuList, selected, setSelectedSubMenu }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // const language = useSelector(selectedLanguage);
  const { selectedLocaleLang } = useContext(LocalContext);

  const onSelect = (item) => {
    if (item.path) {
      navigate(item.path);
    }
    selected !== item.path && setSelectedSubMenu(item.path);
  };
  return (
    <MenuList className={classes.menuList}>
      {subMenuList.map((item) => (
        <MenuItem
          className={`${classes.listItemText} ${
            selected === item.path && classes.activeListItem
          }`}
          key={item.path}
        >
          <ListItemText onClick={() => onSelect(item)}>
            {item.title[selectedLocaleLang.toLowerCase()]}
          </ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  );
};
