import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState, useRef } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import Routes from "./routes/Routes";
import theme from "./theme";
import {useDispatch, useSelector} from "react-redux";
import {fetchLogout} from "./store/actions/auth.actions";
import {useIdleTimer} from 'react-idle-timer';
import {getAuthToken} from "./store/selectors/auth.selector";
import {useOktaAuth} from "@okta/okta-react";




function App() {
  const { authState } = useOktaAuth();  // auth state from okta auth provider
  const dispatch = useDispatch();


  // handle timeout
  useEffect(() => {
    let timeoutFunc; // variable to reference the timeout function

    // if authstate does not exist, clear timer
    if(!authState) { clearTimeout.bind(null, timeoutFunc) }

    // if authstate object exists but user is not authenticated, clear timer
    if(!authState?.isAuthenticated) { clearTimeout.bind(null, timeoutFunc) }

    // if authstate object exists and user is authenticated, set timer
    if(authState?.isAuthenticated) {
      // get current unix time stamp in seconds
      let unixDateNow = Math.floor(Date.now() / 1000);

      // get expiry time in seconds minus a minute
      let authUnixExpireTime = ((authState.idToken.expiresAt - unixDateNow) * 1000) - 60000 ;
      // console.log('authUnixExpireTime', authUnixExpireTime);

      // set timeout to logout user
      timeoutFunc = setTimeout(() => {
        if(authState?.isAuthenticated) {
          dispatch(fetchLogout());
          window.location.reload();
        }
      }, authUnixExpireTime);
    }

    // on unmount, clear timeout
    return () => { clearTimeout.bind(null, timeoutFunc)}

  }, [authState]);

  return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <CssBaseline />
            <Routes />
          </StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
  );
}

export default App;
