import { LoginCallback } from "@okta/okta-react";
import { lazy } from "react";
import DefaultLayout from "../components/layouts/Default.layout";
import Home from "../pages/Home.page";
import LoginPageEngagement from "../pages/LoginEngagement.page";
import WelcomePage from "../pages/Welcome.page.jsx";
import ProtectedRoute from "./ProtectedRoute";
import RouteRedirect from "./RouteRedirect";
import UnProtectedRoute from "./UnProtectedRoute";

/** Import Pages */
// const Home = lazy(() => import("../pages/Home.page"));
const ChangePassword = lazy(() => import("../pages/ChangePassword.page"));
const Contact = lazy(() => import("../pages/Contact.page"));
const Help = lazy(() => import("../pages/Help.page"));
const NotFound = lazy(() => import("../pages/NotFound.page"));
const OrderHistoryPage = lazy(() => import("../pages/OrderHistory.page"));
const OrderHistoryDetailPage = lazy(() => import("../pages/OrderHistoryDetail.page"));
const EditOrderPage = lazy(() => import("../pages/EditOrder.page"));
const WishlistList = lazy(() => import("../pages/WishlistList.page"));
const EarnExtraPoints = lazy(() => import("../pages/EarnExtraPoints.page"));
const RedeemPoints = lazy(() => import("../pages/RedeemPoints.page"));
const Products = lazy(() => import("../pages/Products.page"));
const RedeemPointsList = lazy(() => import("../pages/RedeemPointsList.page"));
const RewardHistory = lazy(() => import("../pages/RewardsHistory.page"));
const ArticleDetail = lazy(() => import("../pages/ArticleDetail.page"));
const RewardsRedeemed = lazy(() => import("../pages/RewardsRedeemed.page"));
const CheckoutEngagement = lazy(() => import("../pages/CheckoutEngagement.page"));
const CartEngagement = lazy(() => import("../pages/CartEngagement.page"));
const OrderSummaryEngagement = lazy(() => import("../pages/OrderSummaryEngagement.page"));
// const LoginPageEngagement = lazy(() => import("../pages/LoginEngagement.page"));
const RegisterPageEngagement = lazy(() => import("../pages/RegisterEngagement.page"));
const ConfirmCodePageEngagement = lazy(() => import("../pages/ConfirmCodeEngagement.page"));
const UserDetailsPageEngagement = lazy(() => import("../pages/UserDetailsEngagement.page"));
const CreatePasswordPageEngagement = lazy(() => import("../pages/CreatePasswordEngagement.page"));
const ForgotPasswordPageEngagement = lazy(() => import("../pages/ForgotPassword.page"));
const MyAccountEngagement = lazy(() => import("../pages/MyAccountEngagement.page"));
const TermsAndConditionsPage = lazy(() => import("../pages/TermsAndConditions.page"));
const PrivacyPolicyPage = lazy(() => import("../pages/PrivacyPolicy.page"));
const FAQsPage = lazy(() => import("../pages/FAQs.page"));
const CommonPolicyTermsPage = lazy(() => import("../pages/CommonPolicyTerms.page"));
// Product infinity scroll poc
const ProductsPocPage = lazy(() => import("../pages/ProductPoc.page"));

export const pages = [
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      /** Home Screen */
      {
        path: "home",
        element: (
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        ),
      },
      {
        path: "welcome",
        element: (
          <UnProtectedRoute>
            <WelcomePage />
          </UnProtectedRoute>
        ),
      },

      /** Auth Screens */
      {
        path: "login",
        element: (
          <UnProtectedRoute>
            <LoginPageEngagement />
          </UnProtectedRoute>
        ),
      },
      {
        path: "register",
        element: (
          <UnProtectedRoute>
            <RegisterPageEngagement />
          </UnProtectedRoute>
        ),
      },
      {
        path: "confirm-code",
        element: <ConfirmCodePageEngagement />,
      },
      {
        path: "user-details",
        element: <UserDetailsPageEngagement />,
      },
      {
        path: "create-password",
        element: <CreatePasswordPageEngagement />,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordPageEngagement />,
      },

      /** Products Screens */
      {
        path: "products",
        element: (
          <ProtectedRoute>
            <Products />
          </ProtectedRoute>
        ),
      },
      {
        path: "buy-again",
        element: (
          <ProtectedRoute>
            <WishlistList />
          </ProtectedRoute>
        ),
      },

      /** Order History page */
      {
        path: "order-history",
        element: (
          <ProtectedRoute>
            <OrderHistoryPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "order-details/:orderId",
        element: (
          <ProtectedRoute>
            <OrderHistoryDetailPage />
          </ProtectedRoute>
        ),
      },
      /*Edit Order Page */
      {
        path: "edit-order/:orderId",
        element: (
          <ProtectedRoute>
            <EditOrderPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "order-summary-engagement",
        element: (
          <ProtectedRoute>
            <OrderSummaryEngagement />
          </ProtectedRoute>
        ),
      },

      /** My Accounts Page */
      {
        path: "my-account",
        element: (
          <ProtectedRoute>
            <MyAccountEngagement />
          </ProtectedRoute>
        ),
      },
      {
        path: "/change-password",
        element: (
          <ProtectedRoute>
            <ChangePassword />
          </ProtectedRoute>
        ),
      },

      /** Cart Page */
      {
        path: "cart-engagement",
        element: (
          <ProtectedRoute>
            <CartEngagement />
          </ProtectedRoute>
        ),
      },

      /** Checkout Page */
      {
        path: "checkout-engagement",
        element: (
          <ProtectedRoute>
            <CheckoutEngagement />
          </ProtectedRoute>
        ),
      },

      /** Rewards pages routes START */
      {
        path: "earn-extra-points",
        element: <EarnExtraPoints />,
      },
      {
        path: "redeem-points",
        element: <RedeemPoints />,
      },
      {
        path: "redeem-points-list",
        element: <RedeemPointsList />,
      },
      {
        path: "rewards-redeemed",
        element: <RewardsRedeemed />,
      },
      {
        path: "reward-history/:orderId",
        element: <RewardHistory />,
      },
      {
        path: "article/:articleId",
        element: <ArticleDetail />,
      },

      /** Rewards pages routes END */

      /** CMS & Extra pages */
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "contact-us",
        element: <Contact />,
      },
      {
        path: "help",
        element: <Help />,
      },

      /** Common components demo page */
      {
        path: "/poc",
        element: <ProductsPocPage />,
      },

      /** Okta login callback page */
      {
        path: "login/callback",
        element: <LoginCallback />,
      },
      {
        path: "",
        element: <RouteRedirect />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditionsPage />,
      },
    ],
  },

  //Terms and conditions and privacy policy page on userdetails page
  // TODO: Remove these pages if not required
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  // Added for whatsapp
  {
    path: "/faqs",
    element: <FAQsPage />,
  },
  {
    path: "/policy-terms",
    element: <CommonPolicyTermsPage />,
  },
];
