import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import * as React from "react";
import { colors } from "../../../../styles/variables";

export default function LabelCheckbox({ label = "", onClick, name }) {
  const handleClick = (e) => {
    e.stopPropagation()
    typeof onClick === "function" && onClick(e);
  };
  return (
    <FormGroup sx={{ marginTop: "20px" }}>
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              borderRadius: "5px",
              color: "#A5A5A5",
              "&.Mui-checked": {
                color: `${colors.color25}`,
              },
            }}
            onClick={handleClick}
          />
        }
        label={label}
        name={name}
      />
    </FormGroup>
  );
}
