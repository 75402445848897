import { OktaAuth } from "@okta/okta-auth-js";
import { getEnv } from "./urls";

const getOktaConfig = () => {
  const env = getEnv();
  return {
    issuer: env.oktaIssuer,
    clientId: env.oktaClientId,
    redirectUri: window.location.origin + "/login/callback",
    scopes: ["openid", "profile", "email"],
    pkce: true,
    // disableHttpsCheck: env.oktaDisableHttpsCheck || false,
    // useInteractionCode: env.oktaUseInteractionCode || false,
  };
};

const oktaAuth = new OktaAuth(getOktaConfig());

export default oktaAuth;
