import React, {useContext} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleModalAction } from "../../store/actions/modals.actions";
import { accountReadySelector, cartConfirmationSelector, deliveryPreferenceModalSelector, registrationErrorSelector, resetPasswordSelector } from "../../store/selectors/modals.selector";
import DeliveryPreferences from "./deliveryPreferences/DeliveryPreferences.component";
import DefaultModal from "./DefaultModal.component";
import FullScreenModal from "./FullScreenModal.component";
import {LocalContext} from "../../LocalProvider";
import { faFrown, faSmile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/**
 * All Modals Listing
 */
const Modals = () => {
  const dispatch = useDispatch();
  const deliveryPreference = useSelector(deliveryPreferenceModalSelector);
  const accountReady = useSelector(accountReadySelector);
  const resetPassword = useSelector(resetPasswordSelector);
  const cartConfirmation = useSelector(cartConfirmationSelector);
  const registrationError = useSelector(registrationErrorSelector);
  const navigate = useNavigate();
  const {translate} = useContext(LocalContext);


  const handleLogin = (id) => {
    console.log('id and ',id, resetPassword)
    dispatch(toggleModalAction(id, false));
    navigate("/login")
  }

  const handleRegister = () => {
    dispatch(toggleModalAction("registrationError", false));
    navigate("/register")
  }

  return (
    <>
      {/* Delevery Preference Modal */}
      <DefaultModal
        open={deliveryPreference}
        header={translate(' mx.whatsapp.you_have_more_than_one_type_of_rewards_in_your_cart_please_select_your_delivery_preferences')}
        id="deliveryPreference"
      >

        <DeliveryPreferences />
      </DefaultModal>
      {/* Cart confirmation modal*/}
      <FullScreenModal
        headingText="79 items will be added to cart"
        bottomButton1={translate('mx.whatsapp.accept')}
        bottomButton2={translate('mx.whatsapp.cancel')}
        id="cartConfirmation"
        open={cartConfirmation}
      />
      {/* Account Ready Modal */}
      <FullScreenModal
        headingText={translate('mx.whatsapp.your_account_is_ready')}
        iconRequired
        icon={<FontAwesomeIcon icon={faSmile} style={{height: '7rem', width: '7rem'}}/>}
        noClose
        bottomText={translate('mx.whatsapp.you_are_now_ready_to_log_in_and_place_your_first_order')}
        bottomButton1={translate('mx.whatsapp.log_in')}
        id="accountReady"
        bottomButton1Click={(e)=>handleLogin(e.target.id)}
        open={accountReady}
      />
      {/* Password reset Modal */}
      <FullScreenModal
        headingText={translate('mx.whatsapp.your_password_was_successfully_reset')}
        iconRequired
        icon={<FontAwesomeIcon icon={faSmile} style={{height: '7rem', width: '7rem'}}/>}
        noClose
        bottomText={translate('mx.whatsapp.you_are_now_ready_to_log_in')}
        bottomButton1={translate('mx.whatsapp.log_in')}
        id="resetPassword"
        bottomButton1Click={(e)=>handleLogin(e.target.id)}
        open={resetPassword}
      />
      {/* Registration Error Modal */}
      <FullScreenModal
        headingText={"There is some issue please try again."}
        iconRequired
        icon={<FontAwesomeIcon icon={faFrown} style={{height: '7rem', width: '7rem'}}/>}
        helperText={translate('mx.whatsapp.please_try_again_or_contact_our_call_center_for_support')}
        topButton1={translate('mx.whatsapp.try_again')}
        id="registrationError"
        isHelplineRequired
        topButton1Click={handleRegister}
        open={registrationError}
      />
    </>
  );
};

export default Modals;
