import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCartEngagementDataAction,
  setCheckoutTypeAction,
} from "../store/actions/cartEngagement.actions";
import {
  checkoutTypeSelector,
  productsCartEngagementSelector,
  rewardsCartEngagementSelector,
} from "../store/selectors/cartEngagement.selector";
import { getRewardPoints } from "../store/selectors/auth.selector";
import { arrToJSON } from "../utils/common";
import { numberWithCommas } from "../components/common/helper/helper";

export const useGetCartHook = () => {
  const dispatch = useDispatch();
  const rewardsCart = useSelector(rewardsCartEngagementSelector);
  const productsCart = useSelector(productsCartEngagementSelector);
  const rewardPoints = useSelector(getRewardPoints);
  const checkoutType = useSelector(checkoutTypeSelector);
  const isProducts = !!productsCart?.items?.length;
  const isRewards = !!rewardsCart?.items?.length;
  const cartTotalCount =
    (rewardsCart?.totalCount || 0) + (productsCart?.totalCount || 0);

  const [productCartSnapshot, setProductCartSnapshot] = useState({});

  useEffect(() => {
    let items = productsCart?.items || [];
    // items = items.map((item) => {
    //   return {
    //     ...item,
    //     unitType: item?.unitType?.find((unit) => unit.active === 1).key,
    //   };
    // });
    setProductCartSnapshot(arrToJSON(items, "id"));
  }, [productsCart]);

  // Change checkout type REWARDS/ZOR
  const onChangeCheckoutType = (type) => dispatch(setCheckoutTypeAction(type));

  const resetCart = (type) =>
    dispatch(
      setCartEngagementDataAction(
        { items: [], count: 0, totalCount: 0, totalPoints: 0 },
        type
      )
    );

  const handleAddToCartProduct = (product, quantity, unitType) => {
    let productData = productsCart?.items || [];
    let currentProductIndex = productData?.findIndex(
      (item) => item.id === product.id
    );
    if (currentProductIndex > -1) {
      productData[currentProductIndex].quantity = quantity;
      if (quantity === 0) {
        productData.splice(currentProductIndex, 1);
      }
      let totalItems = productData.reduce(function (acc, obj) {
        return acc + obj.quantity;
      }, 0);
      dispatch(
        setCartEngagementDataAction(
          { ...productsCart, items: productData, totalCount: totalItems },
          "ZOR"
        )
      );
    } else {
      product.quantity = quantity;
      product.unitType = unitType;
      let updatedCart = [...productData, product];
      let totalItems = updatedCart.reduce(function (acc, obj) {
        return acc + obj.quantity;
      }, 0);
      dispatch(
        setCartEngagementDataAction(
          {
            ...productsCart,
            items: [...productData, product],
            totalCount: totalItems,
          },
          "ZOR"
        )
      );
    }
  };

  const updateProductCartState = (product, count = 0, unitType = "CS") => {
    let productData = {
      ...productCartSnapshot,
    };
    let currProduct = productData[product.id];
    if (currProduct) {
      currProduct.count = count;
      currProduct.unitType = unitType;
    } else {
      productData[product.id] = {
        id: product.id,
        count: count,
        unitType: unitType,
      };
    }
    setProductCartSnapshot(productData);
  };

  const handleAddAllToCart = (allProducts) => {
    let productData = productsCart?.items || [];
    let products = [];

    allProducts.map((product) => {
      let currentProductIndex = productData?.findIndex(
        (item) => item.id === product.id
      );
      if (currentProductIndex >= 0) {
        products.push({
          ...product,
          quantity: productData[currentProductIndex].quantity,
        });
        productData.splice(currentProductIndex, 1);
      }
      if (currentProductIndex < 0) {
        product.quantity = 1;
        products.push(product);
      }
    });
    products = [...productData, ...products];
    let totalItems = products.reduce(function (acc, obj) {
      return acc + obj.quantity;
    }, 0);
    dispatch(
      setCartEngagementDataAction(
        { ...productData, items: products, totalCount: totalItems },
        "ZOR"
      )
    );
  };

  const calculateSubTotal = useMemo(() => {
    let cartItems = productsCart?.items || [];
    let subTotal = cartItems.reduce(function (acc, obj) {
      return acc + obj.quantity * obj.discountedPriceTotal;
    }, 0);
    return numberWithCommas(parseFloat(subTotal).toFixed(2));
  }, [productsCart]);

  //TODO: include vat and other taxes
  const calculateTotal = useMemo(() => {
    let cartItems = productsCart?.items || [];
    let subTotal = cartItems.reduce(function (acc, obj) {
      return acc + obj.quantity * obj.discountedPriceTotal;
    }, 0);
    return numberWithCommas(parseFloat(subTotal).toFixed(2));
  }, [productsCart]);

  return {
    rewardsCart,
    productsCart,
    rewardPoints,
    checkoutType,
    isProducts,
    isRewards,
    cartTotalCount,
    productCartSnapshot,
    calculateSubTotal,
    calculateTotal,
    onChangeCheckoutType,
    updateProductCartState,
    resetCart,
    handleAddToCartProduct,
    handleAddAllToCart,
  };
};
