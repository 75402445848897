import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import { mediaQueries } from "../../../styles/variables";
import SingleColumn from "../../styled/SingleColumn.styled";

export const Wrapper = styled(SingleColumn)`
  background: transparent;
  padding: 0;
  @media ${mediaQueries.mobileAndAbove} {
    padding: 0;
  }
  @media ${mediaQueries.belowContainer} {
    max-width: 1920px;
  }
  @media ${mediaQueries.containerAndAbove} {
    max-width: 100%;
  }
  & .slick-slide img {
    @media ${mediaQueries.containerAndAbove} {
      width: 100%;
      object-fit: cover;
    }
  }
  .slick-dots {
    bottom: 80px;
    width: 250px;
    margin: auto;
    left: 0;
    right: 0;
  }
  .slick-dots li button {
    padding: 0;
    border: 1px solid #fff;
    border-radius: 50%;
  }
  .slick-dots li button:before {
    font-size: 16px;
    line-height: 23px;
  }
`;

export const Holder = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  color: ${(props) => (props.color ? props.color : "#fff")};
`;


export const ImageHolder = styled.div`

`;

export const Image = styled.img`
  flex: 2;
  
`;


// export const Image = styled.img`
//   height: 40.625rem;
//   width: 100%;
//   object-fit: cover;
  
// `;

export const ContentHolder = styled.div`
  // flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;

  @media ${mediaQueries.aboveiPad} and ${mediaQueries.belowContainerStandard} {

  }

  @media ${mediaQueries.aboveContainerStandard} {
    max-width: 40rem;
    left: 8%;
  }

  @media ${mediaQueries.containerAndAbove} {
    max-width: 40rem;
    left: 10rem;
  }

  @media ${mediaQueries.belowiPad} {
    text-align: left;
    left: 1rem;
    bottom: auto;
    top: 2rem;
    max-width: 95%;
  }
`;


export const SlideHolder = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
`;


export const Heading = styled.h3`
  text-align: left;
  letter-spacing: 0px;
  font-size: 26px;
  margin: 0;

  @media ${mediaQueries.sliderMobileBreakPoint} {
    font-size: 14px;
  }

`;

export const SubHeading = styled.p`
  text-align: left;
  letter-spacing: 0px;
  font-size: 14px;
  opacity: 0.8;
  margin: 1rem 0 1rem;
  // @media ${mediaQueries.belowContainer} {
  //   font-size: 1.25rem;
  // }
  // @media ${mediaQueries.belowContainerStandard} {
  //   font-size: 1rem;
  // }
  // @media ${mediaQueries.belowiPad} {
  //   font-size: 1rem;
  //   text-align: left;
  // }
`;

export const CTA = styled.a`
  letter-spacing: 0px;
  font-size: 1.2rem;
  display: inline-block;
  text-decoration: none;
  border-bottom: 2px solid white;
  border-bottom-color: ${(props) =>
    props.borderbottomcolor ? props.borderbottomcolor : "#fff"};
  color: ${(props) => (props.color ? props.color : "#fff")};
  @media ${mediaQueries.belowiPad} {
    font-size: 1.2rem;
  }
`;

export const ButtonsStyling = styled.a`
  align-self: baseline;
  border-radius: 33px;
  cursor: pointer;
  border: 2px solid #fff;
  background-color: transparent;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 0.8rem 2.6rem;
  &:hover {
    background-color: #fff;
    color: #000;
  }
`;

// orignal style
export const useStyles = makeStyles((theme) => ({
  heroCarouselWrapper: {
    // height: "470px",
    marginBottom: "25px",
    [theme.breakpoints.up(1366)]: {
      padding: "0 5rem"
    },

    "& > .slick-slider > .slick-dots": {
      bottom: "-25px !important",
    },

    "& > .slick-slider > .slick-dots li  button:before": {
      fontSize: "17px !important",
      content: "unset",
    },

    "& > .slick-slider > .slick-dots li": {
      border: "1px solid  #000",
      borderRadius: "50%",
      width: "12px",
      height: "12px",
    },


    "& > .slick-slider > .slick-dots li.slick-active": {
      backgroundColor: "#000",
    },

    "& > .slick-slider > .slick-dots li.slick-active  button:before": {
      color: "#000 !important",
      opacity: 1,

    },
  },

  slideWrapper: {
    width: "100%",
    minHeight: "400px",
    display: "flex !important",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "stretch",
    [theme.breakpoints.down(1200)]: {
      minHeight: "370px",
    },
    [theme.breakpoints.down(900)]: {
      minHeight: "280px",
    },
    [theme.breakpoints.down(550)]: {
      flexDirection: "column",
      minHeight: "unset",
    }
  },

  slideTextWrapper:{
    backgroundColor: "#1e1e1e",
    color: "white",
    display: "flex !important",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "stretch",
    minWidth: "40%",
    width: "40%",
    padding: "20px",
    minHeight: "250px",
    [theme.breakpoints.down(550)]: {
      order: 2,
      width: "100%",
      minWidth: "100%",
      minHeight: '40%'
    }
  },



  headingText: {
    textAlign: "left",
    letterSpacing: "0px",
    fontSize: "26px",
    lineHeight: "33px",
    margin: "0",
    [theme.breakpoints.down(860)]: {
      fontSize: "22px",
      lineHeight: "33px",
    },
  },


  subHeadingText: {
    textAlign: "left",
    letterSpacing: "0px",
    fontSize: "14px",
    opacity: "0.8",
    margin: "1rem 0 1rem",
    [theme.breakpoints.down(860)]: {
      fontSize: "12px",
    },
  },

  slideImageWrapper: {
    backgroundColor: "#1e1e1e",
    [theme.breakpoints.up(1366)]: {
      flex: "1",
    },
    [theme.breakpoints.down(550)]: {
      order: 1
    }
  },

  slideImage:{
    objectFit: "cover",
    objectPosition: "right",
    height: "100%",
    width: "100%",
    minHeight: "200px",
  }

}));


//almost working
// export const useStyles = makeStyles((theme) => ({
//   heroCarouselWrapper: {
//     // height: "470px",
//     marginBottom: "25px",
//     [theme.breakpoints.up(1366)]: {
//       padding: "0 5rem"
//     },

//     "& > .slick-slider > .slick-dots": {
//       bottom: "-25px !important",
//     },

//     "& > .slick-slider > .slick-dots li  button:before": {
//       fontSize: "17px !important",
//       content: "unset",
//     },

//     "& > .slick-slider > .slick-dots li": {
//       border: "1px solid  #000",
//       borderRadius: "50%",
//       width: "12px",
//       height: "12px",
//     },


//     "& > .slick-slider > .slick-dots li.slick-active": {
//       backgroundColor: "#000",
//     },

//     "& > .slick-slider > .slick-dots li.slick-active  button:before": {
//       color: "#000 !important",
//       opacity: 1,

//     },
//   },

//   slideWrapper: {
//     width: "100%",
//     // minHeight: "400px",
//     display: "flex !important",
//     flexDirection: "row",
//     flexWrap: "nowrap",
//     justifyContent: "flex-start",
//     alignItems: "stretch",
//     [theme.breakpoints.down(1200)]: {
//       // minHeight: "370px",
//     },
//     [theme.breakpoints.down(900)]: {
//       // minHeight: "280px",
//     },
//     [theme.breakpoints.down(550)]: {
//       flexDirection: "column",
//       minHeight: "unset",
//     }
//   },

//   slideTextWrapper:{
//     backgroundColor: "#1e1e1e",
//     color: "white",
//     display: "flex !important",
//     flexDirection: "column",
//     flexWrap: "nowrap",
//     justifyContent: "center",
//     alignItems: "stretch",
//     minWidth: "40%",
//     // width: "40%",
//     // padding: "20px",
//     // minHeight: "250px",
//     [theme.breakpoints.down(550)]: {
//       order: 2,
//       width: "100%",
//       minWidth: "100%",
//     }
//   },



//   headingText: {
//     textAlign: "left",
//     letterSpacing: "0px",
//     fontSize: "26px",
//     margin: "0",
//     [theme.breakpoints.down(860)]: {
//       fontSize: "22px",
//     },
//   },


//   subHeadingText: {
//     textAlign: "left",
//     letterSpacing: "0px",
//     fontSize: "14px",
//     opacity: "0.8",
//     margin: "1rem 0 1rem",
//     [theme.breakpoints.down(860)]: {
//       fontSize: "12px",
//     },
//   },

//   slideImageWrapper: {
//     backgroundColor: "#1e1e1e",
//     [theme.breakpoints.up(1366)]: {
//       flex: "1",
//     },
//     [theme.breakpoints.down(550)]: {
//       order: 1
//     }
//   },

//   slideImage:{
//     // objectFit: "cover",
//     // objectPosition: "right",
//     // height: "100%",
//     // width: "100%",
//     objectPosition: "contain",
//     minHeight: "200px",
//   }

// }));



