import React from "react";
import { FormControl, TextField, FormLabel, Typography } from "@mui/material";
import { useStyles } from "./LableTextInput.styles";

export default function LableTextInput({
  label = "",
  id = "",
  type = "text",
  placeholder = "",
  name = "",
  value = "",
  onChange,
  InputProps = {},
  disabled = false,
  isDisabled = false,
  bottomRightText = null,
  error = false,
  onKeyDown = () => null,
}) {
  const classes = useStyles({ isDisabled, error });
  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      size="small"
      margin={"normal"}
    >
      {label && <FormLabel className={classes.formLabel} error={error}>{label}</FormLabel>}
      <TextField
        id={id}
        onChange={onChange}
        type={type}
        variant="outlined"
        name={name}
        placeholder={placeholder}
        value={value}
        InputProps={InputProps}
        disabled ={disabled}
        error={error}
        onKeyDown={onKeyDown}
      />
      {bottomRightText}
    </FormControl>
  );
}
