import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import LocalProvider from "./LocalProvider";
import * as serviceWorker from "./serviceWorker";
import { persistor, store } from "./store";
import fetchInterceptor from "./utils/fetchInterceptor";
import oktaAuth from "./utils/okta";
import {toRelativeUrl} from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import { updateLoginDetails } from "./store/actions/auth.actions";

// setup fetch interceptor to setup headers
fetchInterceptor.setup(store);


const Index = () => {
    const navigate = useNavigate();

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        const accessToken = _oktaAuth.authStateManager?._authState?.accessToken?.accessToken;
        await store.dispatch(updateLoginDetails(accessToken, "", false))
        navigate(toRelativeUrl(originalUri || "/", window.location.origin));
    };
    const onAuthRequired = function () {
        navigate("/login");
    };

    return(
        <StrictMode>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                        <LocalProvider>
                            <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} restoreOriginalUri={restoreOriginalUri}>
                                <App />
                            </Security>
                        </LocalProvider>
                </PersistGate>
            </Provider>
        </StrictMode>
    );
}


const container = document.getElementById("root");
const root = createRoot(container);
root.render((<Router>
                <Index />
             </Router>
           ));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
