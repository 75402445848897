import { getMethod, postMethod } from "../../utils/api";
import {
  creditAmountAPIUrl,
  statementOfAccountAPIUrl,
} from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

export const creditAmountActionTypes = {
  FETCH_CREDIT_AMOUNT_DATA_SUCCESS: "FETCH_CREDIT_AMOUNT_DATA_SUCCESS",
  FETCH_CREDIT_DATA_FAILTURE: "FETCH_CREDIT_AMOUNT_DATA_FAILURE",
};

const fetchCreditAmountDataSuccessAction = (data) => {
  return {
    type: creditAmountActionTypes.FETCH_CREDIT_AMOUNT_DATA_SUCCESS,
    data,
  };
};

// FOR DISPLAYING RECORDS IN WEB (WITH PAGINATION)
export const fetchCreditAmountAction =
  (isAuth, token, payload) => async (dispatch) => {
    dispatch(loadingStart());

    await postMethod(creditAmountAPIUrl, payload)
      .then((data) => {
        dispatch(fetchCreditAmountDataSuccessAction(data));
      })
      .catch((err) => console.log(err));
    dispatch(loadingEnd());
  };

// FOR DISPLAYING RECORDS IN EXCEL (WITHOUT PAGINATION, ALL RECORDS)
export const fetchCreditAmountExcelAction =
  (isAuth, token, payload) => async (dispatch) => {
    dispatch(loadingStart());
    return await postMethod(creditAmountAPIUrl, payload).then((data) => {
      return data;
    });
    // .then((response) => response.json())
    // .then((data) => {
    //   return data;
    //   dispatch(loadingEnd());
    // })
  };

// FETCH STATEMENT OF ACCOUNT ACTION ONLY FOR LISTING(NO NEED TO STORE IN REDUX)
export const fetchStatementOfAccountAction =
  (isAuth, token) => async (dispatch) => {
    dispatch(loadingStart());
    return await getMethod(statementOfAccountAPIUrl)
      .then((data) => {
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => console.log(err));
  };

// FETCH STATEMENT OF ACCOUNT DETAILS ACTION (NO NEED TO STORE IN REDUX)
export const fetchStatementOfAccountDetailsAction =
  (isAuth, token, urlLink) => async (dispatch) => {
    dispatch(loadingStart());
    return await getMethod(urlLink)
      .then((data) => {
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => console.log(err));
  };
