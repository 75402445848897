import { rewardHistoryActionTypes } from "../actions/rewardsHistory.actions";

export default function rewardsHistoryReducer(state = null, action) {
    switch (action.type) {
        case rewardHistoryActionTypes.FETCH_REWARD_HISTORY_SUCCESS: {
            return {
                ...state,
                rewardHistoryDetails: action.data,
            }
        };
        default: {
            return state;
        }
    }
}
