export const colors = {
  primary: "#00537D",
  disable: "#dddddd",
  black: "#000",
  white: "#ffffff",
  transparent: "rgba(0, 0, 0, .3)",
  color1: "#009ADC",
  color2: "#0076B3",
  color3: "#063F5E",
  color4: "#E5ECF8",
  color5: "#008000",
  color6: "#0DAF00",
  color7: "#299D49",
  color8: "#DDFFE6",
  color9: "#ffcc00",
  color10: "#e2b502",
  color11: "#c7c7c7",
  color12: "#f2f4f8",
  color13: "#c4c4c4",
  color14: "#464646",
  color15: "#4b4b4b",
  color16: "#535353",
  color17: "#dddddd",
  color18: "#eaebee",
  color19: "#D92736",
  color20: "#B9222E",
  color21: "#CE443B",
  color22: "#FFCAC6",
  color23: "#002791",
  color24: "#193C9C",
  color25: "#0047BA",
  color26: "#003182",
  color27: "#1e1e1e",
  color28: "#DB1E36",
  //Light shades of blue
  // blue100: "#009ADC",
  // blue200: "#0076B3",
  // blue300: "#063F5E",
  // blue400: "#E5ECF8",
  //Green color and success variants
  // green100: "#008000",
  // green200: "#0DAF00",
  // green300: "#299D49",
  // green400: "#DDFFE6",
  // Yellow shades
  // yellow100: "#ffcc00",
  // yellow200: "#e2b502",
  //Grey variant
  // grey100: "#c7c7c7",
  // grey200: "#f2f4f8",
  // grey300: "#c4c4c4",
  // grey400: "#464646",
  // grey500: "#4b4b4b",
  // grey600: "#535353",
  // grey700: "#dddddd",
  // grey800: "#eaebee",
  //Red variant
  // red100: "#D92736",
  // red200: "#B9222E",
  // red300: "#CE443B",
  // red400: "#FFCAC6",
  //Dark blue or indigo variants
  // indigo100: "#002791",
  // indigo200: "#193C9C",
  // indigo300: "#0047BA",
  // indigo400: "#003182"
};

export const fontSizes = {
  font12: "0.75rem", //12px
  font14: "0.875rem", //14px
  font16: "1rem", //16px
  font17: "1.0625rem", //17px
  font18: "1.125rem", //18px
  font19: "1.1875rem", //19px
  font20: "1.25rem", //20px
  font22: "1.375rem", //22px,
  font23: "1.4375rem", //23px
  font24: "1.5rem", //24px
  font26: "1.625rem", //26px
  font28: "1.75rem", //28px
  font30: "1.875rem", //30px
  font36: "2.25rem", //36px
  font42: "2.625rem", //42px
  font48: "3rem", //48px
  font60: "3.75rem", //60px
  font64: "4rem", //60px
  font80: "5rem", //80px
  font90: "5.625rem", //90px
};

export const fontFamilies = {
  signPainter: `"SignPainter-HouseScript", sans-serif`,
  frontage: `"FrontageCondensed-Inline", sans-serif`,
  poppins: `'Poppins', sans-serif`,
  lato: `'Lato', sans-serif`,
};

export const breakpoints = {
  mobileMaxWidth: "900px",
  // container: "1280px",
  container: "1536px",
  containerStandard: "1366px",
  belowiPad: "900px",
  mobileMinWidth: "360px",
  sliderMobileBreakpoint: "550px",
};

export const mediaQueries = {
  mobileAndAbove: `(min-width: ${breakpoints.mobileMaxWidth})`,
  containerAndAbove: `(min-width: ${breakpoints.container})`,
  belowiPad: `(max-width: ${breakpoints.belowiPad})`,
  aboveiPad: `(min-width: ${breakpoints.belowiPad})`,
  belowContainerStandard: `(max-width: ${breakpoints.containerStandard})`,
  aboveContainerStandard: `(min-width: ${breakpoints.containerStandard})`,
  belowContainer: `(max-width: ${breakpoints.container})`,
  mobileMinWidth: `(min-width: ${breakpoints.mobileMinWidth})`,
  mobileMaxWidth: `(max-width : ${breakpoints.mobileMaxWidth})`,
  sliderMobileBreakPoint: `(max-width : ${breakpoints.sliderMobileBreakpoint})`,
};

export const newBreakPoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export const newMediaQueries = {
  //Mobile (0-600)
  mobile: `(max-width:${newBreakPoints.sm})`,
  //Between mobile and ipad(600-900)
  betweenMobileIpad: `(min-width:${newBreakPoints.sm} and max-width:${newBreakPoints.md})`,
  //Above ipad(>900)
  aboveIpad: `(min-width:${newBreakPoints.md})`,
  //Below ipad(<900)
  belowIpad: `(max-width:${newBreakPoints.md})`,
  //Between ipad and desktop
  betweenIpadDesktop: `(min-width:${newBreakPoints.md} and max-width:${newBreakPoints.lg})`,
  //Above Desktop
  aboveDesktop: `(min-width:${newBreakPoints.lg})`,
};

export const signInPics = {
  drinkImage: `./images/signInPic.png`,
};
export const bgSetting = {
  dottedPatternDesktop: `./background/Page_BG.png`,
  dottedPatternMobile: `./background/BG_mobile.png`,
  footerImage: `./background/Footer_BG.png`,
  dotImage: `./background/background_dots.png`,
  leftImage: `./background/background_left.png`,
  rightImage: `./background/background_right.png`,
};

export const cartPics = {
  deleteIcon: `./icons/delete-icon.png`,
  uploadImageIcon: `./icons/uploadImg-icon.png`,
  shoppingIcon: `./icons/Cart-icon.png`,
};

export const contactIcons = {
  writeIcon: "./icons/Write.png",
  callIcon: "./icons/Call.png",
  whatsApp: "./icons/whatsApp.jpg",
};

export const blast = {
  blast: "./icons/blast.png",
};
