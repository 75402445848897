import { registerUserActionTypes } from "../actions/registerUser.actions";

export default function registerUserReducer(state = null, action) {
  switch (action.type) {
    case registerUserActionTypes.UPDATE_REGISTER_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case registerUserActionTypes.FETCHING_REGISTER_USER_DATA:
      return {
        ...state,
        isFetching: action.payload,
      };
    default:
      return state;
  }
}
