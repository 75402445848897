import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styled from "styled-components";

export const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: theme.customFont.font14,
  position: "relative",
  width: "100%",
  textTransform: "none",
  borderRadius: "100px",
  border: `1px solid ${theme.palette.custom.primary}`,
  color: theme.palette.custom.buttonText1,
  padding: "0.2rem 1.6rem",
  height: "fit-content",
  background: theme.palette.custom.buttonBg1,
  [theme.breakpoints.down("sm")]: { padding: "0.2rem 0.8rem" },
  //   [theme.breakpoints.between("md, lg")]: {
  //     padding: '0.2rem 1.6rem',
  // },
  // [theme.breakpoints.down("md, lg")]: {
  //   padding: '0.2rem 1.6rem',
  // },
  // [theme.breakpoints.between("md", "lg")]: {
  //   padding: '0.2rem 0.6rem',
  // },
  // [theme.breakpoints.between("lg","xl")]: {
  // padding: '0.2rem 0.6rem',
  // },
  // [theme.breakpoints.up("xl")]: {
  // padding: '0.2rem 0.6rem',
  // },

  // [theme.breakpoints.down("lg")]: {
  //     padding: '0.2rem 2.6rem',
  // },
  //   @media ${mediaQueries.belowContainerStandard} {
  //     padding: 0.2rem 0.6rem;
  //   }
  //   @media ${mediaQueries.mobileMinWidth} {
  //     padding: 0.2rem 0.6rem;
  //   }
}));

export const useStyles = makeStyles((theme) => ({
  fontStyle: {
    color: theme.palette.custom.color25,
    fontSize: theme.customFont.font14,
    padding: "5px 5px 0px 5px",
    opacity: 1,
  },
  orderText: {
    color: theme.palette.custom.color11,
    paddingLeft: "20px",
  },
  summaryCardsList: {
    "& > div:last-child": {
      borderBottom: "none",
    },
  },
  img: {
    width: "100px",
    height: "100px",
    margin: "10px auto",
    textAlign: "center",
  },

  toggleButtonStyle: {
    background: "transparent",
    border: "0",
    padding: "0 !important",
    position: "absolute",
    right: "0.8rem",
    top: "0.8rem",
    [theme.breakpoints.down("md")]: {
      right: "0.5rem",
      top: "0.5rem",
    },
    "&:hover": {
      background: "white !important",
    },
    "& svg": {
      fill: "lightgray",
      strokeWidth: "2px",
      stroke: "white",
      fontSize: "2rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    "&.Mui-selected": {
      background: "transparent",
      "&:hover": {
        background: "white !important",
      },
      "& svg": {
        fill: "#FF7979",
      },
    },
  },
  EmptyindicationBar: {
    padding: "5px",
    position: "relative",
  },
  indicationBar: {
    padding: "5px",
    background: "#0047BA",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    position: "relative",
  },
  indicationBarPoints: {
    position: "absolute",
    right: "-8px",
    top: "-8px",
    borderRadius: "50%",
    padding: "2px 10px",
    background: "#0047BA",
    color: "#FFFFFF",
  },
  summaryTitle: {
    fontWeight: "800",
    fontSize: theme.customFont.font20,
    marginBottom: "1.5em",
  },
  productCardContainer: {
    width: "100%",
    marginTop: "10px",
    borderRadius: "0.5rem",
    border: "0.1px solid #ececec",
    background: theme.palette.custom.productCardBg,
    // borderBottom: '1px solid #DBDBDB',
  },
  avtarStyle: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0.5rem",
  },
  summaryCardCompGrid: {
    paddingBottom: "1em",
    paddingTop: "1em",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "0em",
      marginBottom: "1em",
    },
  },
  fullText: {
    width: "max-content",
    fontSize: theme.customFont.font12,
  },
  text: {
    color: theme.palette.custom.productCardText,
    fontSize: theme.customFont.font16,
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0rem",
      fontSize: theme.customFont.font16,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "0rem",
      fontSize: theme.customFont.font16,
    },
  },
  productText: {
    color: theme.palette.custom.productCardText,
    fontSize: theme.customFont.font18,
    fontWeight: "700",
    lineHeight: "20px",
    width: "100%",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      marginTop: "0rem",
      fontSize: theme.customFont.font16,
    },
  },
  
  boxCard: {
    background: theme.palette.custom.productCardBg,
    marginLeft: "4px",
    marginRight: "4px",
    // [theme.breakpoints.down("sm")]: {
    //   marginLeft: '0px',
    //   marginRight: '0px',
    // },
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: '4px',
    //   marginRight: '4px',
    // },
    // [theme.breakpoints.down("lg")]: {
    //   marginLeft: '0px',
    //   marginRight: '0px',
    // },
  },
  barcode: {
    color: theme.palette.custom.productCardSubText,
    fontSize: theme.customFont.font12,
    position: "relative",
    top: '5px',
    [theme.breakpoints.down("md")]: {
      fontSize: theme.customFont.font12,
      top: '2px',
    },
  },

  text1: {
    color: theme.palette.custom.productCardSubText,
    fontSize: theme.customFont.font12,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.customFont.font12,
    },
  },
  unitPrice: {
    display: "inline-block",
    color: theme.palette.custom.productCardSubText,
    fontSize: theme.customFont.font12,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.customFont.font12,
    },
  },
  text2: {
    color: theme.palette.custom.productCardSubText,
    fontSize: theme.customFont.font12,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
      fontSize: theme.customFont.font10,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "0",
      fontSize: theme.customFont.font10,
    },
  },
  productDetail: {
    fontSize: theme.customFont.font14,
  },
  fontPadding: {
    paddingLeft: "20px",
  },
  totalTextStyle: {
    fontSize: theme.customFont.font14,
    paddingLeft: "20px",
    paddingTop: "10px",
  },
  totalAmoutStyle: {
    fontSize: theme.customFont.font16,
    paddingLeft: "20px",
    fontWeight: "bold",
  },
  titleFlex: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    marginTop: "2rem",
    alignItems: "center",
  },
  titleStyle: {
    fontSize: theme.customFont.font26,
    fontWeight: "bold",
    paddingTop: "20px",
    paddingBottom: "10px",
  },
  viewAllStyle: {
    fontSize: theme.customFont.font14,
    color: theme.palette.custom.color25,
    // paddingTop: "40px",
  },
  slider: {
    "& > .slick-slider > .slick-dots": {
      bottom: "-25px !important",
    },

    "& > .slick-slider > .slick-dots li  button:before": {
      fontSize: "17px !important",
      content: "unset",
    },

    "& > .slick-slider > .slick-dots li": {
      border: "1px solid  #000",
      borderRadius: "50%",
      width: "12px",
      height: "12px",
    },

    "& > .slick-slider > .slick-dots li.slick-active": {
      backgroundColor: "#000",
    },

    "& > .slick-slider > .slick-dots li.slick-active  button:before": {
      color: "#000 !important",
      opacity: 1,
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    width: "25%",

    "&__wrap": {
      "&--outer": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "25%",
      },

      "&--inner": {
        display: "flex",
        flexDirection: "row",
        width: "25%",
      },
    },
  },
}));
