import {useContext} from "react";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import { Typography, useTheme } from "@mui/material";
import { fontSizes } from "../../../styles/variables";
import Anchor from "../../styled/Anchor.styled";
import MarginWrapper from "../../styled/MarginWrapper.styled";
import SingleColumn from "../../styled/SingleColumn.styled";
import {
  FooterHelpLineRow,
  FooterRowCopyright, FooterWrapper
} from "./Footer.component.styles";
import { useSelector } from "react-redux";
// import { selectedLanguage } from "../../../store/selectors/language.selector";
import { LocalContext } from "../../../LocalProvider";

const FooterNew = ({ isAuth, footerData }) => {
  const theme = useTheme();
  const { selectedLocaleLang } = useContext(LocalContext);

  const renderFooter = () => {
    if (footerData) {
      return (
        <FooterWrapper isAuth={isAuth}>
          <SingleColumn>
            <MarginWrapper>
              {isAuth && <FooterHelpLineRow justify="space-between">
                <div sx={{ textAlign: "left" }}>
                  {(footerData[0].path === "footerContact" && footerData[0].title) && <Typography component="div" align={"left"}>
                    <PhoneEnabledIcon
                      sx={{
                        background: "white",
                        color: "#003182",
                        borderRadius: "50%",
                        margin: "auto",
                        padding: "1px",
                        fontSize: fontSizes.font12, 
                      }}
                    />{" "}
                    {footerData[0].title[selectedLocaleLang]}
                  </Typography>}
                  {(footerData[1].path === "footerCallCenterText" && footerData[1].title) && <Typography
                    align="left"
                    variant="body2"
                    sx={{ fontSize: fontSizes.font12, color: theme.palette.custom.footerSubHeading }}
                  >
                   {footerData[1].title[selectedLocaleLang]}
                  </Typography>}
                </div>
                {(footerData[2].path === "FooterLogo" && footerData[2].title) &&  <Anchor href={"/home"} hideLogoOnMobile>
                  <img
                    src= {footerData[2].image} /*TODO: Remove the logo from public folder if the url is coming from api */
                    alt={"Customer Engagement Portal"}
                    style={{ width: 115 }}
                  />
                </Anchor>}
              </FooterHelpLineRow>}
              {(footerData[3].path === "footerCopyRight" && footerData[3].title) && <FooterRowCopyright
                justify={isAuth ? "left" : 'center'}
                paddingMobile="1.5rem 1rem 1rem 1rem"
                isAuth={isAuth}
              >
                {footerData[3].title[selectedLocaleLang]}
              </FooterRowCopyright>}
            </MarginWrapper>
          </SingleColumn>
        </FooterWrapper>
      )
    }
    return <></>
  }

  return renderFooter();
};

export default FooterNew;
