import makeStyles from "@mui/styles/makeStyles";
import { colors } from "../../../../styles/variables";

export const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "30px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      minWidth: "232px",
      marginLeft: "0px",
    },
    "& > *": {
      fontSize: "0.8rem",
      [theme.breakpoints.between("sm", "lg")]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: props => props.isDisabled? '#DBDBDB': 'white',
      },
      "& input": {
        height: "1rem",
        padding: "1rem 0.7rem",
        color: "##1E1E1E",
        '&:disabled':{
          backgroundColor: '#DBDBDB',
          borderRadius: "10px",
        }
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: props => props.error ? "" : "2px solid #DBDBDB !important",
        borderRadius: "10px",
      },
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: props => props.error ? "" : `${colors.color25} !important`,
    },
  },
  formLabel: {
    color: `${colors.color27}`,
    fontWeight: "bold",
    fontSize: `${theme.customFont.font14}`,
    lineHeight: "32px",
  },
}));
