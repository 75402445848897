import React from "react";
import { Box } from "@mui/material";
import { useStyles } from "./Auth.styles";
import LanguageMenu from "../common/language/LanguageSelector.component";

export default function AuthLayout({ children }) {
  const classes = useStyles();
  return (
    <Box
      marginY={8}
      marginTop={{ md: 6, xs: 1 }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box className={classes.box}>
        <Box
          display={{ xs: "flex", md: "none" }}
          justifyContent="flex-end"
          marginBottom={3}
        >
          <LanguageMenu />
        </Box>
        {children}
      </Box>
    </Box>
  );
}
