export const modalsActionTypes = {
  TOGGLE_MODAL: "TOGGLE_MODAL",
  MODAL: "MODAL"
};

export const toggleModalAction = (key, data = false) => {
  return {
    type: modalsActionTypes.TOGGLE_MODAL,
    key,
    data,
  };
};

export const modalAction = (data) => {
  return {
    type: modalsActionTypes.MODAL,
    data
  }
}
