const initialState = {
  navItems: {},
  auth: {
    isAuth: false,
    token: "",
    changePasswordRequired: false,
    accountDetails: {},
  },
  help:null,
  homePage: null,
  cart: {
    items: [],
  },
  cartItems: {
    items: [],
  },
  language: {
    languageData: [],
    selectedLanguage: "en",
  },
  product: {
    items: [],
  },
  brandSubBrand: {
    brand: null,
    subBrand: null,
  },
  orderHistory: {
    items: [],
    orderStatusFilters: [],
  },
  creditAmount: {
    items: [],
  },
  myAccount: {},
  earnExtraPoints: {
    items: [],
    contentType: [],
    selectedTab: null,
  },
  redeemPoints: {
    items: [],
  },
  cartEngagement: {
    REWARDS: null,
    ZOR: null,
    checkoutType: null,
    isFetched: false,
  },
  checkOut: {
    termsDetails: null,
    customerDetails: null,
  },
  root: {
    loading: 0,
  },
  modals: {
    deliveryPreference: false,
    accountReady: false,
    cartConfirmation: false,
    registrationError: false,
  },
  rewardsHistory: {},
  registerUser: {
    firstName: "",
    lastName: "",
    userId: "",
    erpID: "",
    email: "",
    mobilePhone: "",
    role: "MGR",
    isFetching: false,
  },
  stores: {
    storeId: [],
  },
  deliveryEstimate: {
    estimatedDeliveryDate: {},
    holidayList: {},
  },
  editOrderCart : {
    items: [],
    id: 0
  },
  welcomePage: {
    content: []
  },
};

export default initialState;
