import moment from "moment";
import { deleteMethod, getMethod, postMethod } from "../../utils/api";
import { getEnv, productListAPIUrl, specialProductListAPIUrl } from "../../utils/urls";
import { fetchUnitTypeName } from "./../../components/common/helper/helper";
import {
  productWishListAPIUrl
} from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";
let momentUTCFormat = "YYYY-MM-DDTkk:mm:ss.SSSZ"; // utc string format to ensure correct parsing across all browsers
let hoursUntilProductRefreshNeeded = 4; // amount of hours until we need to pull new product data

// function to check if data is in correct date iso format to ensure cross-browser consistency
function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  var d = new Date(str);
  return d.toISOString() === str;
}

/* 
1> Update store with user action data (Sync)
2> Update the backend with user action data (Async)
3> Get the latest cart data on page load. (Async)

*/
export const productActionTypes = {
  FETCH_PRODUCT_DATA_SUCCESS: "FETCH_PRODUCT_DATA_SUCCESS",
  FETCH_PRODUCT_DATA_FAILURE: "FETCH_PRODUCT_DATA_FAILURE",
  FETCH_WISHLIST_DATA_SUCESS: "FETCH_WISHLIST_DATA_SUCESS",
  FETCH_WISHLIST_DATA_FAILURE: "FETCH_WISHLIST_DATA_FAILURE",
  FETCH_UPDATE_PRODUCT_DATA_SUCCESS: "FETCH_UPDATE_PRODUCT_DATA_SUCCESS",
};

const fetchProductDataSuccessAction = (data) => {
  return {
    type: productActionTypes.FETCH_PRODUCT_DATA_SUCCESS,
    data,
  };
};

const getWishListItemsDataSuccessAction = (productId, value) => {
  console.log("productId Action", productId);
  return {
    type: productActionTypes.FETCH_WISHLIST_DATA_SUCESS,
    productId,
    value,
  };
};

const fetchUpdateProductDataSuccessAction = (productId, unitType) => {
  return {
    type: productActionTypes.FETCH_UPDATE_PRODUCT_DATA_SUCCESS,
    productId,
    unitType,
  };
};

// get products with custom pricing from salesforce
// include storeId as param in body/payload when calling function
export const fetchSalesforceProductDataAction =
  (payload, isAuth, token, isSpecialProduct = false) =>
    async (dispatch, getState) => {
      const apiURL = productListAPIUrl; // api url used for fetching products with salesforce data
      let shouldFetchNewProducts = true; // variable used to determine if we should call product api
      const latestState = getState();

      //   const momentFromNow = moment("2022-07-23T08:18:42.895Z", momentUTCFormat).fromNow();
      //   console.log('momentFromNow: ', momentFromNow);
      //   console.log('momentFromNow int only: ', typeof parseInt( momentFromNow.match( /\d+/)[0] ) )

      // if there is product data stored in AND there is a date corresponding to the last date/time products were last pulled
      if (
        latestState.product?.items?.products &&
        latestState.product?.items?.productsPulledDate
      ) {
        let fromNowEval = moment(
          latestState.product?.items?.productsPulledDate,
          momentUTCFormat
        ).fromNow();

        // if product data is at least 1 day, 1 month, or 1 year-old
        if (
          fromNowEval.includes("day") ||
          fromNowEval.includes("month") ||
          fromNowEval.includes("year")
        ) {
          // fetch new products
          shouldFetchNewProducts = true;
        } else if (fromNowEval.includes("hour")) {
          // console.log('from now eval includes hour keyword')
          // if amount of hours from the fromNowEval is equal to or greater than the amount of hours until we need to pull product data
          if (
            parseInt(fromNowEval.match(/\d+/)?.[0]) >=
            hoursUntilProductRefreshNeeded
          ) {
            // console.log('product data at least 4 hours old')
            // product data is at least 4 years old
            shouldFetchNewProducts = true;
          } else {
            // console.log('product data not atleat 4 hours old')
            // product data is not more than 4 hours old, so we don't need to pull new products
            shouldFetchNewProducts = false;
          }
        } else {
          // this else statement handles the conditions of minutes and seconds
          // do not pull new products
          shouldFetchNewProducts = false;
        }
      } else {
        // pull new products because there is no product data or the date corresponding to the last date/time products were pulled is missing
        // console.log('pulling new product data!');
        shouldFetchNewProducts = true;
      }

      if (shouldFetchNewProducts) {
        // fetch new product data
        return await getMethod(apiURL, { headers: payload })
          .then((data) => {
            let updatedProductList = data.products?.map((item) => {
              // setProductList(productData);
              let discountedPrice = item?.price?.basePrice;
              item?.price?.discounts?.map((val) => {
                if (val.type === "Percentage" && val.value) {
                  discountedPrice =
                    (1 - 0.01 * val.value) * parseInt(item?.price?.basePrice);
                }
                if (val.type === "adjustment" && val.value) {
                  discountedPrice = discountedPrice - val.value;
                }
              });

              return {
                ...item,
                discountedPricePerUnit: getEnv().siteId === "6" ?
                  item?.price?.finalPrice : discountedPrice,
                discountedPriceTotal: getEnv().siteId === "6" ?
                  item?.price?.finalPrice * (parseInt(item?.price?.saleUoms[0].conversionMultiplierToBase) || 1)
                  : discountedPrice * (parseInt(item?.price?.saleUoms[0]?.conversionMultiplierToBase) || 1),
                unitType: fetchUnitTypeName(item?.price?.saleUoms),
                conversionMultiplier: parseInt(item?.price?.saleUoms[0]?.conversionMultiplierToBase) || 1
              };
            });

            // create date object to reference later when determining if we should make a new request for products
            // valid UTC date/time in iso format
            const productsPulledDate = moment().utc().toISOString();
            // console.log('productsPulledDate: ', productsPulledDate);
            // console.log('productsPulledDate is isodate: ', isIsoDate(productsPulledDate));

            dispatch(
              fetchProductDataSuccessAction({
                ...data,
                productsPulledDate,
                products: updatedProductList,
              })
            );
            dispatch(loadingEnd());
          })
          .catch((err) => {
            console.log(err);
            dispatch(loadingEnd());
          });
      } else {
        // do not fetch new product data and just return out of function
        dispatch(loadingEnd());
        return;
      }
    };

export const fetchProductDataAction =
  (payload, isAuth, token, isSpecialProduct = false) =>
    async (dispatch, getState) => {
      const apiURL = isSpecialProduct
        ? specialProductListAPIUrl
        : productListAPIUrl;

      return await postMethod(apiURL, payload)
        .then((data) => {
          let updatedProductList = data.products?.map((item) => {
            // setProductList(productData);
            let discountedPrice = item?.price?.basePrice;
            item?.price?.discounts?.map((val) => {
              if (val.type === "Percentage" && val.value) {
                discountedPrice =
                  (1 - 0.01 * val.value) * parseInt(item?.price?.basePrice);
              }
              if (val.type === "adjustment" && val.value) {
                discountedPrice = discountedPrice - val.value;
              }
            });
            return {
              ...item,
              discountedPriceEach: discountedPrice,
              discountedPriceTotal:
                item?.price?.saleUoms?.length > 0
                  ? discountedPrice *
                  item?.price?.saleUoms[0]?.conversionMultiplierToBase
                  : discountedPrice,
            };
          });
          dispatch(
            fetchProductDataSuccessAction({
              ...data,
              products: updatedProductList,
            })
          );
          dispatch(loadingEnd());
        })
        .catch((err) => {
          console.log(err);
          dispatch(loadingEnd());
        });
    };

// Add to Wishlist from Product Listing Page
export const addToWishListDataAction =
  (productInfo, storeId) => async (dispatch, getState) => {
    try {
      // dispatch(loadingStart());
      postMethod(productWishListAPIUrl + "?productId=" + productInfo.id, null, {
        headers: { storeId: storeId },
      });
      dispatch(getWishListItemsDataSuccessAction(productInfo.id, "1"));
      // dispatch(loadingEnd());
    } catch (error) {
      console.log(error);
    }
  };

// Remove to Wishlist from Product Listing Page
export const removeToWishListDataAction =
  (productInfo, storeId) => async (dispatch, getState) => {
    try {
      // dispatch(loadingStart());
      deleteMethod(productWishListAPIUrl + "?productId=" + productInfo.id, {
        headers: { storeId: storeId },
      });
      dispatch(getWishListItemsDataSuccessAction(productInfo.id, "0"));
      // dispatch(loadingEnd());
    } catch (error) {
      console.log(error);
    }
  };

// Change Unitype in Product Listing Page
export const updateUnitTypeDataAction =
  (productId, unitType) => (dispatch, getState) => {
    dispatch(loadingStart());
    const latestState = getState();
    // TO UPDATE CART STORE
    latestState.cart.items.map((singleCartItem) => {
      return singleCartItem.id === productId
        ? singleCartItem.unitType.map((singleType) => {
          return singleType.key === unitType
            ? (singleType.active = 1)
            : singleType.key !== unitType
              ? (singleType.active = 0)
              : singleType.active;
        })
        : singleCartItem;
    });

    dispatch(fetchUpdateProductDataSuccessAction(productId, unitType));
    dispatch(loadingEnd());
  };

// FOR DISPLAYING RECORDS IN EXCEL (WITHOUT PAGINATION, ALL RECORDS)
export const fetchProductExcelAction =
  (token, isSpecialProduct = false) =>
    async (dispatch) => {
      dispatch(loadingStart());
      const apiURL = JSON.parse(isSpecialProduct)
        ? specialProductListAPIUrl
        : productListAPIUrl;
      return await postMethod(apiURL)
        .then((data) => {
          return data;
        })
        .catch((err) => console.log(err));
    };
