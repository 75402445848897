import { getMethod } from "../../utils/api";
import { getRewardsAPIUrl } from "../../utils/urls";
export const redeemPointsActionTypes = {
  FETCH_REWARDS_API_SUCCESS: "FETCH_REWARDS_API_SUCCESS",
  FETCH_REWARDS_API_FAILURE: "FETCH_REWARDS_API_FAILURE",
};

const fetchRedeemPointsDataSuccessAction = (data) => {
  return {
    type: redeemPointsActionTypes.FETCH_REWARDS_API_SUCCESS,
    data,
  };
};

export const getRedeemPoints = () => async (dispatch) => {
  try {
    let data = await getMethod(getRewardsAPIUrl);
    if (data?.items) {
      dispatch(fetchRedeemPointsDataSuccessAction({ items: data.items || [] }));
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};
