import { redeemPointsActionTypes } from "../actions/redeemPoints.actions";

export default function redeemPointsReducer(state = null, action) {
  switch (action.type) {
    case redeemPointsActionTypes.FETCH_REWARDS_API_SUCCESS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
