import React, {useContext} from "react";
import { Box, Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import { colors, fontSizes, newBreakPoints } from "../../../styles/variables";
import EarnExtraPointsCard from "./EarnPointsCard.component";
import { useSelector } from "react-redux";
// import { selectedLanguage } from "../../../store/selectors/language.selector";
import { useStyles } from "./ContentEarnPoints.styles";
import { LocalContext } from "../../../LocalProvider";

const settings = {
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: false,
  speed: 2000,
  autoplaySpeed: 5000,
  dots: false,
  arrows: false,
  responsive: [
    {
      breakpoint: newBreakPoints.sm,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
      },
    },
  ],
};

const ContentEarnPoints = ({ data }) => {
  const classes = useStyles();
  // const language = useSelector(selectedLanguage);
  const { selectedLocaleLang } = useContext(LocalContext);
  if (!data?.length) {
    return null;
  }
  return (
    <Box sx={{ marginY: "2rem" }}>
      <Box marginBottom={8}>
        <Typography variant="h4" fontSize={fontSizes.font26} fontWeight="bold">
          Content that earns you points
        </Typography>
        <Typography
          variant="caption"
          fontSize={fontSizes.font14}
          color={colors.color16}
        >
          Engage with content to earn more points
        </Typography>
        <Box marginY={5}>
          <Slider {...settings} className={classes.slider}>
            {data.map((item) => (
              <EarnExtraPointsCard
                item={item}
                key={item.contentId}
                language={selectedLocaleLang}
              />
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(ContentEarnPoints);
