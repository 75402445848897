import { productActionTypes } from "../actions/product.actions";

export default function productReducer(state = null, action) {
  switch (action.type) {
    case productActionTypes.FETCH_PRODUCT_DATA_SUCCESS:
      // console.log('productActionTypes.FETCH_PRODUCT_DATA_SUCCESS action.data: ', action.data);
      return { items: action.data };

    case productActionTypes.FETCH_WISHLIST_DATA_SUCESS:
      console.log("reducers", state);
      return {
        ...state, 
        items: {
          ...state.items,
          products:
            state.items.products &&
            state.items.products.map((singleItem) => {
              if (singleItem.id === action.productId) {
                return { ...singleItem, wishlist: action.value };
              }
              return singleItem;
            }),
        },
      };
    case productActionTypes.FETCH_UPDATE_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          items:
            state.items.items &&
            state.items.items.map((singleItem) => {
              if (singleItem.id === action.productId) {
                singleItem.unitType.map((singleType) => {
                  if (singleType.key === action.unitType) {
                    singleType.active = 1;
                  } else if (singleType.key !== action.unitType) {
                    singleType.active = 0;
                  }
                  return { ...singleType };
                });
              }
              return singleItem;
            }),
        },
      };
    default:
      return state;
  }
}
