import CallIcon from "@mui/icons-material/Call";
import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
// import { selectedLanguage } from "../../../store/selectors/language.selector";
import { getFooterNavData } from "../../../store/selectors/navItems.selector";
import { colors } from "../../../styles/variables";
import { LocalContext } from "../../../LocalProvider";

export default function HelplineContact() {
  const footerData = useSelector(getFooterNavData);
  // console.log('footerData', footerData);
  // const language = useSelector(selectedLanguage);
  const { selectedLocaleLang, translate } = useContext(LocalContext);
  return (
    <Box padding="20px" bgcolor={colors.color25} marginTop="40px" borderRadius="10px">
      {footerData && footerData[0]?.title &&
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box component="p" marginY="5px">
            <Typography
              component="span"
              display="block"
              color="common.white"
              fontWeight="bold"
            >
              {translate('mx.whatsapp.helpline')}:
            </Typography>
            <a href="tel:1800622997"
              style={{ display: "block", color: "white", fontWeight: "bold", textDecoration: 'none' }}
            >
              {footerData[0].title[selectedLocaleLang]}
            </a>
          </Box>
          <a href={footerData[0].title[selectedLocaleLang]}>
            <CallIcon
              sx={{
                backgroundColor: "#F6EB39",
                borderRadius: "50%",
                padding: "3px",
                transform: "rotateY(180deg)",
              }}
            />
          </a>
        </Box>} 
      {footerData && footerData[1]?.title && <Typography
        component="span"
        display="block"
        color="common.white"
        variant="subtitle2"
        sx={{ opacity: "0.64" }}
        fontSize="12px"
      >
       {footerData[1].title[selectedLocaleLang]}
      </Typography>}
    </Box>
  );
}
