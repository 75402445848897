import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useStyles } from "./Appbar.styles";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isOktaLogin } from "../../utils/urls";
import { useOktaAuth } from "@okta/okta-react";
import { fetchLogout } from "../../store/actions/auth.actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { LocalContext } from "../../LocalProvider";

const data = {
  logo: "images/logoWhite.png",
  topMenu: [
    { name: "Home", subMenu: [], link: "/home" },
    {
      name: "Shop",
      subMenu: [
        { name: "All products", link: "/products" },
        { name: "Your favorites", link: "/buy-again" },
      ],
    },
    { name: "Orders", subMenu: [], link: "/order-history" },
    {
      name: "Rewards",
      subMenu: [
        { name: "Dashboard", link: "/earn-extra-points" },
        { name: "Reward Catalog", link: "/redeem-points" },
        { name: "Earn Points", link: "/redeem-points-list" },
      ],
    },
    { name: "Help", subMenu: [] },
    { name: "My account", subMenu: [] },
  ],
  bottomMenu: [
    {
      name: "English",
      subMenu: [{ name: "English" }, { name: "中文" }],
    },
    { name: "Signout", subMenu: [] },
  ],
};


const MultiListItem = (props) => {
  const { item, handleClick, language, icon, isLanguageDropDown, handleChangeLang, lang } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <>
      {item && <ListItem disablePadding>
        <ListItemButton
          className={classes.mobileListButton}
          onClick={() => setOpen(!open)}
          disableRipple
        >
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText
            primary={isLanguageDropDown ? lang.name : item.title && item.title[language]}
            className={classes.mobileListText}
          />
          {open ? (
            <ArrowDropUpIcon className={classes.mobileExpandIcon} />
          ) : (
            <ArrowDropDownIcon className={classes.mobileExpandIcon} />
          )}
        </ListItemButton>
      </ListItem>}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.subItems?.map((sub, i) => {
            if (isLanguageDropDown) {
              return (
                <ListItem disablePadding key={i}>
                  <ListItemButton disableRipple onClick={() => handleChangeLang({ name: sub.title[language], value: sub.path })}>
                    <ListItemText
                      primary={sub.title[language]}
                      className={classes.mobileListText}
                    />
                  </ListItemButton>
                </ListItem>
              )
            }
            else return(
              <ListItem disablePadding key={i}>
                <ListItemButton disableRipple onClick={(e) => handleClick(e, sub.path)}>
                  <ListItemText
                    primary={sub.title[language]}
                    className={classes.mobileListText}
                  />
                </ListItemButton>
              </ListItem>
            )
          }
          )}
        </List>
      </Collapse>
    </>
  );
};

// language selector in mobile menu drawer
const MobileLanguageChanger = (props) => {
  const { toggleDrawer } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const {handleLanguageChange, selectedLocaleLang, languageOptions} = useContext(LocalContext);

  // change language
  const handleLanguageSelect = (e, languageKey) => {
    setOpen(false);
    toggleDrawer(e, false);
    handleLanguageChange(languageKey);
  }


  return(
    <>
      <ListItem disablePadding>
        <ListItemButton className={classes.mobileListButton} disableRipple onClick={() => {setOpen(!open)}}>
          <FontAwesomeIcon icon={faLanguage} color={"white"} style={{marginRight: 5}} />
          <ListItemText
            primary={selectedLocaleLang === "en" ? "English" : "Spanish"}
            className={classes.mobileListText}
          />
          
          <FontAwesomeIcon icon={faCaretDown} color={"white"} style={{marginLeft: 5}} />
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {languageOptions.map((item, i) => {
            return( 
                    <ListItemButton disableRipple key={i} onClick={(e) => handleLanguageSelect(e, item.languageKey)}>
                      <ListItemText primary={item.language} className={classes.mobileListText}/>
                    </ListItemButton>
                  )
          })}
        </List>
      </Collapse>
    </>
  )
}


// mobile menu drawer
export default function MobileDrawer({ toggleDrawer, open, headerData, lang, handleChangeLang }) {

  const classes = useStyles();
  const navigate = useNavigate();
  // const language = useSelector(selectedLanguage);
  const { authState, oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const { handleLanguageChange, selectedLocaleLang, languageOptions, translate } = useContext(LocalContext);


  const handleClick = (e, path) => {
    toggleDrawer(e, false);
    setTimeout(() => {
      navigate(path);
    }, 100);
  }

  const handleLogout = async () => {
      if (isOktaLogin) {
        await oktaAuth.signOut({
          clearTokensBeforeRedirect: true,
          revokeAccessToken: true,
          revokeRefreshToken: true,
          accessToken: authState.accessToken,
        });
      }
      dispatch(fetchLogout())
      navigate("/");
      return window.location.reload();
  }

  return (
    <>
      {open && (
        <IconButton className={classes.closeBtn} onClick={(e)=>toggleDrawer(e, false)}>
          <CloseIcon />
        </IconButton>
      )}
      <Drawer
        anchor="left"
        open={open}
        onClose={(e)=>toggleDrawer(e,false)}
        BackdropProps={{ sx: { backgroundColor: "rgb(0 0 0 / 80%)" } }}
        PaperProps={{ sx: { backgroundColor: "#0047BA" } }}
      >
        <Box className={classes.drawerBox} role="presentation">
          <img
            src={data.logo}
            className={classes.mobileLogo}
            onClick={(e)=> handleClick(e, "/home")}
            alt="PepsiCo"
            width="166px"
          />
          <List className={classes.mobileList}>
            {headerData && headerData.map((item, i) =>
            item.title["en"] !== "language" &&
              (item.subItems?.length ? (
                <MultiListItem 
                  item={item} 
                  key={i} 
                  handleClick={handleClick} 
                  language={selectedLocaleLang} 
                />
              ) : (
                <ListItem disablePadding key={i}>
                  <ListItemButton
                    className={classes.mobileListButton}
                    disableRipple
                    onClick={(e) => handleClick(e, item.path)}
                  >
                    <ListItemText
                      primary={item.title[selectedLocaleLang]}
                      className={classes.mobileListText}
                    />
                  </ListItemButton>
                </ListItem>
              )
            ))}
            <ListItem disablePadding key={"My account"}>
              <ListItemButton
                className={classes.mobileListButton}
                disableRipple
                onClick={(e) => handleClick(e, "/my-account")}
              >
                <ListItemText
                  primary={translate('mx.whatsapp.my_account')}
                  className={classes.mobileListText}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding key={"Help"}>
              <ListItemButton className={classes.mobileListButton} disableRipple onClick={(e) => handleClick(e, "/help")}>
                <ListItemText primary={translate('mx.whatsapp.help')} className={classes.mobileListText}/>
              </ListItemButton>
            </ListItem>
          </List>
          <Divider sx={{ borderColor: "#f8fbff7a" }} />
          <List className={classes.mobileList}>
            <MobileLanguageChanger toggleDrawer={toggleDrawer}/>
            <ListItem disablePadding key={"SignOut"}>
              <ListItemButton className={classes.mobileListButton} disableRipple onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon sx={{ color: "#fff" }} />
                  </ListItemIcon>
                  <ListItemText primary={translate('mx.whatsapp.logout')} className={classes.mobileListText}/>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
}
