import React from "react";
import { Stack, Typography, ButtonBase } from "@mui/material";
import { fontSizes } from "../../../styles/variables";
import { useStyles } from "./ContentEarnPoints.styles";
import ButtonOutlined from "../../common/button/ButtonOutlined.component";
import { formatDate } from "../../../utils/common";

export default function EarnExtraPointsCard({ item, language }) {
  const classes = useStyles();
  return (
    <Stack direction="column" marginX={{xs: 1, md: 2}}>
      <ButtonBase>
        <img
          src={item.images?.[0]?.image}
          height="320"
          width="350"
          className={classes.img}
        />
      </ButtonBase>
      {item.StartDate && (
        <Typography
          variant="subtitle2"
          component="p"
          color="#616161"
          marginTop={2}
        >
          {formatDate(item.StartDate)}
        </Typography>
      )}
      {item.title[language] && (
        <Typography fontSize={fontSizes.font18} fontWeight="bold" sx={{minHeight: "56px"}}>
          {item.title[language]}
        </Typography>
      )}
      <ButtonOutlined variant="outlined" sx={{ width: "150px" }}>
        READ NOW
      </ButtonOutlined>
    </Stack>
  );
}
