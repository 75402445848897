import { getMethod } from "../../utils/api";
import { getOrderHistoryAPIUrl } from "../../utils/urls";
import { loadingStart } from "./root.actions";

export const rewardHistoryActionTypes = {
  FETCH_REWARD_HISTORY_SUCCESS: "FETCH_REWARD_HISTORY_SUCCESS",
  FETCH_REWARD_HISTORY_FAILURE: "FETCH_REWARD_HISTORY_FAILURE",
};

export const fetchRewardHistorySuccessData = (data) => {
  return {
    type: rewardHistoryActionTypes.FETCH_REWARD_HISTORY_SUCCESS,
    data,
  };
};

export const fetchRewardHistoryDetails =
  (isAuth, token, orderId) => async (dispatch) => {
    dispatch(loadingStart());
    return await getMethod(`${getOrderHistoryAPIUrl}/${orderId}`)
      .then((data) => {
        dispatch(fetchRewardHistorySuccessData(data));
        return data;
      })
      .catch((err) => console.log(err));
  };
