
import React, { createContext, useEffect, useState } from "react";
import {getTranslations} from "./utils/urls";
import {getMethod} from "./utils/api";
import Loading from './components/common/Loading.component';

export const LocalContext = createContext();

function LocalProvider({ children }) {
  const languageOptions = [
    {languageKey: 'en', language: 'English'},
    {languageKey: 'es', language: 'Spanish'}
  ];

  // create state to store translation data
  // const [contentLan, setContentLan] = useState(() => {
  //   const savedTranslations = JSON.parse(localStorage.getItem('translationdata'));
  //   return savedTranslations || null;
  // });

  const [contentLan, setContentLan] = useState(null);

  // create state to handle selected language
  const [selectedLocaleLang, setSelectedLocalelang] = useState(() => {
    // check local stoage for previously sleceted lanauge
    const savedLang = localStorage.getItem("language");
    // set default state to previously selected language or default to ES (spanish)

    if(savedLang === 'zh'){
      localStorage.setItem('language','es');
      return 'es'
    } else if(!savedLang){
      localStorage.setItem('language','es');
      return 'es'
    } else {
      localStorage.setItem('language', savedLang);
      return savedLang
    }
  });


  useEffect(() => {
    const getTranslationAPIList = async () => {
      try{
        const translationdata = await getMethod(getTranslations);
        // console.log('translationdata', translationdata)
        localStorage.setItem('translationdata', JSON.stringify(translationdata));
        setContentLan(translationdata);
      } catch(err){
        console.log(err);
      }
    }
    getTranslationAPIList();
  },[]);


  const handleLanguageChange = (languageKey) => {
    localStorage.setItem("language", languageKey);
    setSelectedLocalelang(languageKey);
  }

  // function used to translate text based on the key in the translation data 
  const translate = (translationKey) => {
    // if there is no translation data at all
    if(!contentLan){
      return ""
    }

    // if there is no translation data matching translation key
    if(contentLan[translationKey] === undefined){
      return translationKey
    }

    // if language is selected
    if(selectedLocaleLang){
      // return translation
      return contentLan[translationKey][selectedLocaleLang];
    } else {
      // if no language selected default to spanish translation
      return contentLan[translationKey]['es']
    }
  };



  return (
    <LocalContext.Provider value={{ translate, handleLanguageChange, selectedLocaleLang, languageOptions }}>
      {contentLan ? children : <Loading/>}
    </LocalContext.Provider>
  );
}

LocalProvider.propTypes = {};

export default LocalProvider;

