export const allProductsSelector = (state) =>
  state.product.items.products ? state.product.items.products : [];

export const getOrderTypeSelector = (state) =>
  state.product.items ? state.product.items.orderType : null;
export const totalProductsCountSelector = (state) =>
  state.product.items.totalCount ? state.product.items.totalCount : 0;

export const layoutType = (state) =>
  state.product.items.layoutWithImage
    ? state.product.items.layoutWithImage
    : true;
