import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRedeemPoints } from "../store/actions/redeemPoints.actions";
import {
  allAuthData,
  getRewardPoints
} from "../store/selectors/auth.selector";
import { redeemPointsItemsSelector } from "../store/selectors/redeemPoints.selector";
import { getEnv } from "../utils/urls";
export const useRedeemPointsHook = () => {
  const headerData = useSelector(allAuthData);
  const siteId = getEnv().siteId;
  const token = headerData?.token ? headerData.token : null;
  const redeemPointsItems = useSelector(redeemPointsItemsSelector);
  const rewardPoints = useSelector(getRewardPoints);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!redeemPointsItems?.length && token && siteId !== "6") {
      dispatch(getRedeemPoints());
    }
  }, [token]);

  return {
    redeemPointsItems,
    rewardPoints,
  };
};
