import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getAuthToken } from "../store/selectors/auth.selector";

export default function UnProtectedRoute({ children }) {
  const token = useSelector(getAuthToken);
  if (token) {
    return <Navigate to="/home" />;
  }
  return children;
}
