import { getContentFaqsAPIUrl } from "./../../utils/urls";
import { getMethod } from "../../utils/api";


export const helpActionTypes = {
    FETCH_HELP_DATA_SUCCESS: "FETCH_HELP_DATA_SUCCESS",
  };

  const fetcHelpDataSuccessAction = (data) => {
    return {
      type: helpActionTypes.FETCH_HELP_DATA_SUCCESS,
      data,
    };
  };

  // to get faqs 
  export const getHelpAction = () => async (dispatch) => {
    try {
      let data = await getMethod(getContentFaqsAPIUrl);
      if (data.totalCount > 0) {
        const helpData = data;
        dispatch(fetcHelpDataSuccessAction(helpData));
      }
    } catch (error) {
      console.log(error)
    }
  };