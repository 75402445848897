import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { rewardsCartEngagementSelector } from "../../../store/selectors/cartEngagement.selector";
import { deliveryPreferencesStyles } from "./DeliveryPreferences.styles";
// import RewardList from "./RewardList.component";
import RewardDetail from "./RewardDetail.component";

const DeliveryPreferences = () => {
  const classes = deliveryPreferencesStyles();
  const [openAddress, setOpenAddress] = useState(false);
  const rewardsCart = useSelector(rewardsCartEngagementSelector);
  return (
    <Grid
      container
      className={classes.gridContainer}
      direction="column"
      spacing={2}
    >
      <Grid item md={12} xs={12} className={classes.containerTopSpacing}>
        {rewardsCart?.items?.map((item) => {
          return <RewardDetail data={item} key={item.id} />;
        })}
      </Grid>
      {/* <Grid item md={12} xs={12} className={classes.containerTopSpacing}>
        <RewardList items={rewardsCart?.items} />
      </Grid> */}
      {openAddress && (
        <Grid
          item
          md={12}
          xs={12}
          rowSpacing={{ xs: 2 }}
          columnSpacing={{ xs: 2 }}
          style={{ padding: "1rem 1.5rem 2rem 2rem" }}
        >
          <Grid container>
            <Grid item md={4} xs={12}></Grid>
            <Grid item md={7} xs={12} className={classes.gridSecondary}>
              <Grid container direction="column" spacing={2}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  className={classes.deliveryAddressText}
                >
                  New Delivery Address
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel} shrink>
                      Name
                    </InputLabel>
                    <TextField
                      variant="standard"
                      // size="small"
                      // style={{ minWidth: "475px" }}
                      className={classes.nameTextBox}
                      // InputLabelProps={{ shrink: false }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.inputLabel} shrink>
                      Address
                    </InputLabel>
                    <TextField
                      variant="standard"
                      // style={{ minWidth: "475px" }}

                      className={classes.nameTextBox}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes.hideSuggestion}
                          >
                            <Typography>Remove Suggestion</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel className={classes.inputLabel} shrink>
                          City
                        </InputLabel>
                        <TextField
                          variant="standard"
                          className={classes.nameTextBox}
                          InputProps={{ disableUnderline: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel className={classes.inputLabel} shrink>
                          State
                        </InputLabel>
                        <TextField
                          variant="standard"
                          className={classes.nameTextBox}
                          InputProps={{ disableUnderline: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel className={classes.inputLabel} shrink>
                          Zip Code
                        </InputLabel>
                        <TextField
                          variant="standard"
                          className={classes.nameTextBox}
                          InputProps={{ disableUnderline: true }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ paddingLeft: "0.5rem" }}>
                  <Checkbox />
                  Remember this information for next time
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!openAddress && (
        <Grid
          item
          className={classes.addNewDeliveryAddress}
          onClick={() => setOpenAddress(true)}
        >
          + Add New Delivery Address
        </Grid>
      )}
      <Grid
        item
        className={classes.continueButtonContainer}
        style={{ padding: "1.5rem" }}
      >
        <Button className={classes.continueButton}>Continue</Button>
      </Grid>
    </Grid>
  );
};

export default DeliveryPreferences;
