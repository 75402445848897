import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./Cart.styles";

const CartQuantity = (props) => {
  const { onQuantityUpdate, quantity, isProduct, alignStart, multiplier, minimumOrderQuantity } = props;
  const classes = useStyles({ isProduct, alignStart });
  const [count, setCount] = useState("");
  useEffect(() => {
    if (quantity === "" || quantity == 0) {
      return setCount("");
    }
    setCount(parseInt(quantity));
  }, [quantity]);

  const onAddQuantity = () => {
    let qty = parseInt(quantity || 0);
    qty += multiplier ? multiplier : 1;
    onQuantityUpdate(qty);
  };

  const onSubtractQuantity = () => {
    let qty = parseInt(quantity || 0);
    if (qty === minimumOrderQuantity) {
      onQuantityUpdate(0);
      return;
    }
    if (qty > 1 || (qty > 0 && isProduct)) {
      qty += multiplier ? -multiplier : -1;
      onQuantityUpdate(qty);
    }
  };

  const onDeleteQuantity = () => onQuantityUpdate(0);

  const onChangeQuantity = (value) => {
    if (!isNaN(value)) {
      // Incase of minimum quantity, we are restricting it to 0
      if (value === "" || value <= 0) {
        return setCount(0);
      }
      //max length should be three digit
      if (value && value.length < 6) {
        value = parseInt(value);
        setCount(value);
      }
    }
  };

  return (
    <div className={classes.actionWrapper}>
      {quantity > 1 || (quantity >= 0 && isProduct) ? (
        <IconButton
          disableTouchRipple
          className={classes.iconButton}
          onClick={onSubtractQuantity}
          sx={{ paddingLeft: 0 }}
          disabled={quantity === 0 && isProduct}
          size="large"
        >
          {isProduct ?
            <RemoveCircleIcon className={classes.icon} /> :
            <RemoveCircleOutlineIcon className={classes.icon} />
          }
        </IconButton>
      )
        : quantity === 1 && !isProduct ? (
          <IconButton
            disableTouchRipple
            size="large"
            className={classes.iconButton}
            onClick={onDeleteQuantity}
          >
            <DeleteIcon className={classes.icon} />
          </IconButton>
        ) : null}
      <TextField
        maxLength="5"
        className={classes.productQuantity}
        autoComplete="off"
        InputLabelProps={{
          shrink: true,
        }}
        value={count || 0}
        onChange={(e) => onChangeQuantity(e.target.value)}
        onBlur={() => onQuantityUpdate(count)}
        variant="standard"
      />
      <IconButton
        disableTouchRipple
        className={classes.iconButton}
        onClick={onAddQuantity}
        size="large"
      >
        {isProduct ?
          <AddCircleIcon className={classes.icon} /> :
          <AddCircleOutlineIcon className={classes.icon} />
        }
      </IconButton>
    </div>
  );
};

export default CartQuantity;
