import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { colors, fontSizes } from "../../../styles/variables";

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: `${theme.palette.custom.buttonBg3}`,
  fontWeight: "bold",
  fontSize: `${fontSizes.font14}`,
  height: "56px",
  borderRadius: "33px",
  marginTop: "1rem",
  color: `${colors.white}`,
  "&:hover": {
    backgroundColor: `${theme.palette.custom.buttonHoverBg3}`
  },
}));

export const StyledOutlineButton = styled(Button)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: `${fontSizes.font14}`,
  height: "40px",
  // boxShadow: theme.customShadow.shad2,
  borderRadius: "33px",
  marginTop: "1rem",
  background: theme.palette.custom.buttonBg2,
  color: theme.palette.custom.buttonText2,
  border: "2px solid #0047BA",
  "&:hover": {
    border: "2px solid",
  },
}));

export const StyledTextButton = styled(Button)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: `${fontSizes.font14}`,
  height: "40px",
  marginTop: "1rem",
  color: theme.palette.custom.buttonText2,
}));