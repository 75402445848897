import React, { useEffect, useState, useContext } from "react";
import ProductCard from "./ProductCard";
import { Typography, Button } from "@mui/material";
import Slider from "react-slick";
import { useStyles } from "./ProductCardGrid.styles";
import BoxCard from "../../common/card/boxCard/BoxCard.component";
// import { productData } from "./ProductCardData";
import { newBreakPoints } from "../../../styles/variables";
import {LocalContext} from "../../../LocalProvider";

const settings = {
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: false,
  speed: 500,
  dots: false,
  arrows: false,
  responsive: [
    {
      breakpoint: newBreakPoints.md,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: newBreakPoints.sm,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
  ],
};
const ProductCardSlider = ({ title, isReward, data, isHome, handleViewAll }) => {
  const classes = useStyles();
  const {translate} = useContext(LocalContext);

  return (
    <>
      {title && (
        <div className={classes.titleFlex}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className={classes.titleStyle}
          >
            {title}
          </Typography>
          <Button size="small" className={classes.viewAllStyle}
           onClick={handleViewAll}
          >
            {translate('mx.whatsapp.view_all')}
            </Button>
        </div>
      )}
      <div className={classes.slider}>
        <Slider {...settings}>
          {data?.map((item, index) => (
            <BoxCard
              key={index}
              noPadding
              // paddingY={2}
              marginX={{ xs: 1, md: 2 }}
              marginTop={{ md: 2, xs: 1 }}
              className={classes.boxCard}
              styleChange={{height: "300px"}}
            
            >
              <ProductCard item={item} key={index} isReward={isReward} isHome={isHome} xs="300" md="360" sm="330" hideWishlistIcon={true}/>
            </BoxCard>
          ))}
        </Slider>
      </div>
      
    </>
  );
};

export default ProductCardSlider;
