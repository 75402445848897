import Login from "../components/auth/login/Login.component";
import SingleColumn from "../components/styled/SingleColumn.styled";

const LoginPage = ({ location }) => {
  return (
    <div>
      <SingleColumn>
        <Login />
      </SingleColumn>
    </div>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
