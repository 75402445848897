export const allAuthData = (state) =>
  state.auth ? state.auth : null;

export const getAuthToken = (state) =>
  state.auth?.token ? state.auth.token : null;
  
export const getUserType = (state) =>
  state.auth?.userType ? state.auth.userType : null;
export const isAuthenticated = (state) => !!state.auth.token;

export const getAccountDetails = (state) =>
  state.auth?.accountDetails ? state.auth.accountDetails : {};

export const getRewardPoints = (state) =>
  state.auth?.accountDetails?.rewardPoints ? state.auth.accountDetails?.rewardPoints : "00";
