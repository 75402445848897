import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Button, Menu, MenuItem } from "@mui/material";
import { useState, useContext } from "react";
import { flushSync } from "react-dom";
import { colors } from "../../styles/variables";
import { useStyles } from "./profileMenu.component.styles";
import { useSelector } from "react-redux";
import { getAccountData } from '../../store/selectors/myAccount.selector';
import {LocalContext} from "../../LocalProvider";


const ProfileMenu = ({ onChange, options = [] }) => {
  const { translate } = useContext(LocalContext);
  const accountData = useSelector(getAccountData);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value) => {
    // use flushSync to bypass react's update batching
    flushSync(() => {
      setAnchorEl(null);
    });
    // use setTimeout to wait for local state update before navigating
    setTimeout(() => {
      onChange(value);
    }, 50);
  };

  return (
    <div>
      <Button
        className={classes.button}
        id="basic-button"
        sx={{ textTransform: "unset !important", color: "#1E1E1E" }}
        // endIcon={<ArrowDropDownIcon sx={{fill: colors.color27, height: '20px', width: '20px'}} />}
        endIcon={
          <FontAwesomeIcon
            icon={faCaretDown}
            style={{ height: "18px", width: "20px" }}
          />
        }
        startIcon={<AccountCircleIcon sx={{ fill: colors.color27 }} />}
        onClick={handleClick}
        aria-controls={open ? "basic-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        {/* <span>
          {userData
            ? userData?.customers[0]?.firstName +
              " " +
              userData?.customers[0]?.lastName
            : ""}
        </span> */}

        <span>
          {accountData
            ? accountData?.customers?.firstName +
              " " +
              accountData?.customers?.lastName
            : ""}
        </span>
      </Button>
      <Menu
        id="account-nav-menu"
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        {options.map((item, i) => {
          return (
            <MenuItem key={i} onClick={() => handleSelect(item.value)}>
              {item.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default ProfileMenu;
