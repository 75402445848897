import makeStyles from "@mui/styles/makeStyles";

export const deliveryPreferencesStyles = makeStyles((theme) => ({
  deliveryAddressText: {
    fontSize: "14px",
    lineHeight: "32px",
    fontWeight: "bold",
    color: "#5C5C5C",
    opacity: 1,
  },
  nameTextBox: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #9EBDE6",
    borderRadius: "10px",
    marginTop: "1rem",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "0.5rem",
  },
  gridSecondary: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "32px !important",
    },
  },
  inputLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#535353",
    marginLeft: "-10px",
  },
  hideSuggestion: {
    fontSize: "16px",
    lineHeight: "32px",
    letterSpacing: "0px",
    color: "#004D96",
    marginRight: "0.5rem",
  },
  continueButtonContainer: {
    background: "#F8FBFF 0% 0% no-repeat padding-box",
    display: "flex",
    justifyContent: "center",
  },
  continueButton: {
    color: "#fff",
    padding: "0.5rem 3.5rem",
    background: "#002791 0% 0% no-repeat padding-box",
    borderRadius: "33px",
    textTransform: "uppercase",
    fontSize: "18px",
    fontWeight: "bold",
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "yellow !important",
  },
  containerTopSpacing: {
    padding: "0rem 1.5rem 2rem 4rem !important",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 1.5rem 2rem 2rem !important",
    },
  },
  addNewDeliveryAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "uppercase",
    color: "#002791",
    fontWeight: "bold",
    marginBottom: "2rem",
  },
  checkBox: {
    background: "#FFFFFF",
    border: "1px solid #9EBDE6",
    borderRadius: "4px",
  },
}));

export const rewardDetailStyles = makeStyles((theme) => ({
  container: {
    marginTop: "3rem",
    // marginLeft: "1.5rem"
  },
  imageTitle: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "bold",
    color: "#000000",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
  },
  tabular: {},
  tabs: {
    display: "flex",
    flexWrap: "wrap",
  },
  tab: {
    minWidth: "100px",
    marginRight: "3%",
    borderRadius: "13px",
    background: "#FFFFFF 0% 0 % no-repeat padding-box",
    border: "2px solid #C7A317",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1",
    textTransform: "capitalize",
    [theme.breakpoints.down(581)]: {
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "29px",
      marginRight: "3%",
      minWidth: "80px",
      padding: "5px",
    },
    "&[aria-selected=true]": {
      background: "#FFCB02 0% 0% no-repeat padding-box",
      color: "#313030",
    },
  },
  tabPanel: {
    // padding: "16px 0px",
    // marginTop: "40px",
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  textField: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    height: "50px",
    border: "1px solid #80B5D3",
    borderRadius: "10px",
    opacity: 1,
    marginLeft: "2rem",
  },
  menuItem: {
    margin: "0rem 1rem 0rem 1rem",
    borderBottom: "1px solid #000",
    minHeight: "40px",
    // "&:last-child": {
    //     borderBottom: "none"
    // }
    "&:last-child": {
      // color: "blue",
      borderBottom: "none",
    },
  },
  select: {
    // "&:last-child": {
    //     color: "blue",
    //     borderBottom: "4px solid orange"
    // }
  },
}));
