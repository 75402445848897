import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuthToken } from "../store/selectors/auth.selector";
import {useOktaAuth} from "@okta/okta-react";

const RouteRedirect = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search); // url - /home?langauge=en
  const path = searchParams.get("path"); // https://exmaple.com/?path=/products
  const token = useSelector(getAuthToken);


  if (path) {
    return <Navigate to={path} />;
  } else if(token) {
    return <Navigate to="/home" />;
  } else {
    return <Navigate to="/welcome" />;
  }
};

export default RouteRedirect;
