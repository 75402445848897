import React, { useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector, } from "react-redux";
import { Box, Grid, Typography, Avatar, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../common/button/Button.component";
import ButtonOutlined from '../common/button/ButtonOutlined.component';
import AuthLayout from "../auth/AuthLayout.component";
import { useStyles } from "../auth/Auth.styles";
import { colors, fontSizes } from "../../styles/variables";
import HelplineContact from "../common/helplineContact/HelplineContact.component";
import styled from "styled-components";
import {useHomeDataHook} from '../../hooks/useHomeData.hook';
import {fetchWelcomePageContent} from "../../store/actions/welcomePage.actions";
import {welcomePageContent} from "../../store/selectors/welcomePage.selector";
import {LocalContext} from "../../LocalProvider";

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  cursor: 'pointer',
});

const WelcomeContent = (props) => {
  const { translate } = useContext(LocalContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const content = useSelector(welcomePageContent);
  const brandImagesArray = content[0]?.images;
  const isFirstFetch = useRef(true);

  // fetch content
  useEffect(() => {
      // fetch once
      if(isFirstFetch.current){
          dispatch(fetchWelcomePageContent());
          isFirstFetch.current = false;
          return
      }
  }, [isFirstFetch]);


  return (
    <AuthLayout>
      <Box component="form" display="flex" flexDirection="column">
          <Box marginBottom={3}>
              <Typography variant="h4" fontWeight="bold" className={classes.heading}>
                  {translate('mx.whatsapp.welcome')}
                  <Typography variant="subtitle1" color="success.main" marginTop={0.3} className={classes.subHeading}>
                      {translate("mx.whatsapp.this_is_your_easy_access_to_pepsico_products_for_your_store")}
                  </Typography>
              </Typography>
          </Box>
          <Button type="button" onClick={() => navigate("/login")}>
              {translate("mx.whatsapp.sign_in")}
          </Button>
          <ButtonOutlined type="outlined" onClick={() => navigate("/register")} sx={{ height: '56px' }}>
              {translate("mx.whatsapp.register")}
          </ButtonOutlined>
          <Box marginTop={3}>
            <Typography variant={"h5"} fontSize={fontSizes.font26} fontWeight={"bold"} color={colors.color27}>
                {translate("mx.whatsapp.our_brands")}
            </Typography>
            <Typography fontSize={fontSizes.font14} color={colors.color16}>
                {translate("mx.whatsapp.inside_you_will_find_products_from_these_and_other_brands")}
            </Typography>
            <Box>
              <Grid container spacing={4} marginTop={3}>
                {brandImagesArray && brandImagesArray.map((imageObj, index)=>{
                  return(
                    <Grid key={index} item xs={6} md={4} lg={4} sm={4} xl={4}>
                      <Img
                        alt={`brandImage ${index}`}
                        src={imageObj.image}
                      />
                    </Grid>  
                  )
                })}
              </Grid>
            </Box>
          </Box>
          
          <HelplineContact />
        </Box>
    </AuthLayout>
  )
}

export default WelcomeContent
