import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHomeContent } from "../store/actions/earnExtraPoints.actions";
import { fetchProductDataAction, fetchSalesforceProductDataAction } from "../store/actions/product.actions";
import { getAuthToken, isAuthenticated } from "../store/selectors/auth.selector";
import {
  allContentSelector,
  earnPointsContentSelector,
  filteredContentDataSelector,
  homeAllContentSelector,
  homeTagsContentSelector
} from "../store/selectors/earnExtraPoints.selector";
// import { selectedLanguage } from "../store/selectors/language.selector";
import { allProductsSelector } from "../store/selectors/product.selector";
import { storeNameSelector } from "../store/selectors/stores.selector";
import { getEnv } from "../utils/urls";
import { useRedeemPointsHook } from "./useRedeemPoints.hook";
import { getStoreId } from "../store/selectors/myAccount.selector";
export const useHomeDataHook = () => {
  const dispatch = useDispatch();
  const [infoMsg, setInfoMsg] = useState("");
  const allContentData = useSelector(allContentSelector);
  const homeTagsContent = useSelector(homeTagsContentSelector);
  const earnPointsContent = useSelector(earnPointsContentSelector);
  const homeAllTagsContent = useSelector(homeAllContentSelector);
  const filteredContentData = useSelector(filteredContentDataSelector);
  const storeName = useSelector(storeNameSelector);
  const token = useSelector(getAuthToken);
  // const language = useSelector(selectedLanguage);
  const productData = useSelector(allProductsSelector);
  const { authState } = useOktaAuth();
  const { redeemPointsItems } = useRedeemPointsHook();
  const isAuth =  useSelector(isAuthenticated);
  const storeId = useSelector(getStoreId); // get store id to use in product api request



  const envConfig = useMemo(getEnv, []);
  useEffect(() => {
    if (envConfig.loyaltyEnabled) {
      dispatch(getHomeContent(storeName, token));
    }
  }, [isAuth, storeName]);


  // useEffect(() => {
  //   if (token && !productData.length) {
  //     // dispatch(fetchProductDataAction());
  //     dispatch(fetchSalesforceProductDataAction({storeId: storeId}))
  //   }
  // }, [dispatch, token]);

  useEffect(() => {
    if (token && storeId) {
      dispatch(fetchSalesforceProductDataAction({storeId: storeId}))
    }
  }, [token, storeId]);

  //  TODO:  SET
  // TODO: NOT in USE
  const setNotificationMessage = (data) => {
    if (data !== undefined && data.hasOwnProperty("informationSplash")) {
      setInfoMsg(data.informationSplash);
    }
  };

  return {
    infoMsg,
    allContentData,
    homeTagsContent,
    earnPointsContent,
    isUserAuth: isAuth,
    loyaltyEnabled: envConfig.loyaltyEnabled,
    filteredContentData,
    redeemPointsItems,
    productData,
    storeName,
  };
};
