import { getMethod, putMethod } from "../../utils/api";
import {
  getCustomerDetailsAPIUrl, postCustomerDetailsAPIUrl
} from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

export const myAccountActionTypes = {
  FETCH_MY_ACCOUNT_DATA_SUCCESS: "FETCH_MY_ACCOUNT_DATA_SUCCESS",
  FETCH_MY_ACCOUNT_DATA_FAILTURE: "FETCH_MY_ACCOUNT_DATA_FAILURE",
  UPDATE_MY_ACCOUNT_DATA_FAILTURE: "UPDATE_MY_ACCOUNT_DATA_FAILURE",
  UPDATE_MY_ACCOUNT_DATA_SUCCESS: "UPDATE_MY_ACCOUNT_DATA_SUCCESS",
};

const fetchMyAccountSuccessAction = (data) => {
  return {
    type: myAccountActionTypes.FETCH_MY_ACCOUNT_DATA_SUCCESS,
    data,
  };
};

const putMyAccountSuccessAction = (data) => {
  return {
    type: myAccountActionTypes.UPDATE_MY_ACCOUNT_DATA_SUCCESS,
    data,
  };
};

// TO GET CURRENT LOGGED-IN USER BILL,SHIP & SOLD TO ADDRESS
//Store API is called along with account API as user id from account API is required in store API and is used across the application.
export const fetchMyAccountDetailsAction = () => async (dispatch) => {
  dispatch(loadingStart());
  try {
    let accountDetails = await getMethod(getCustomerDetailsAPIUrl);
    dispatch(fetchMyAccountSuccessAction(accountDetails));
  } catch (err) {
    console.log(err);
  }
  dispatch(loadingEnd());
};

export const changeMyAccountDetailsAction = (body) => async (dispatch) => {
  dispatch(loadingStart());
  await putMethod(postCustomerDetailsAPIUrl, body)
    .then((data) => {
      dispatch(putMyAccountSuccessAction(data));
    })
    .catch((err) => console.log(err));
  dispatch(loadingEnd());
};
