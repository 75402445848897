import { getMethod } from "../../utils/api";
import { getEnv, getNavigationsAPIUrl } from "../../utils/urls";
import { getAuthToken, isAuthenticated } from "../selectors/auth.selector";
import { loadingStart } from "./root.actions";

export const navItemsActionTypes = {
  FETCH_NAV_ITEMS_DATA_SUCCESS: "FETCH_NAV_ITEMS_DATA_SUCCESS",
};

const fetchHeaderDataSuccessAction = (data, isAuth) => {
  return {
    type: navItemsActionTypes.FETCH_NAV_ITEMS_DATA_SUCCESS,
    isAuth,
    data,
  };
};

export const fetchGetNavigation = () => async (dispatch, getState) => {
  try {
    const latestState = getState();
    const isAuth = isAuthenticated(latestState);

    dispatch(loadingStart());
    // crate header as per isAuth
    let headers = {};
    if (localStorage.getItem("language") && getEnv().siteId === "1") {
      headers["Content-Language"] = localStorage.getItem("language");
    }
    let data = await getMethod(getNavigationsAPIUrl, {
      headers: headers,
    });
    // TODO: handel error
    // if (data?.appHeader?.creditBalance) { //Need to integrate with account api
    //   localStorage.setItem(
    //     "selectedCustomerCreditAmt",
    //     data.appHeader.creditBalance
    //   );
    // }
    dispatch(fetchHeaderDataSuccessAction(data, isAuth));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const changeLanguageAction =
  (userTypeData) => async (dispatch, getState) => {
    dispatch(loadingStart());
    let latestState = getState();
    const token = getAuthToken(latestState);

    // dispatch(validateTokenSuccessAction(token, false));
    // if (latestState.homePage) {
    //   latestState.homePage = null;
    // }
    // if (latestState.navItems) {
    //   latestState.navItems.userType = userTypeData;
    // }
    // if (latestState.navItems) {
    //   // latestState.navItems = null;
    //   latestState.navItems.anonymousNav = null;
    // }
    // if (latestState.checkOut) {
    //   latestState.checkOut = null;
    // }
    // if (latestState.cart) {
    //   latestState.cart.items = [];
    // }
    // if (latestState.myAccount) {
    //   latestState.myAccount = null;
    // }
    // // To clear the cartItems
    // if (latestState.cartItems) {
    //   latestState.cartItems.items = [];
    //   latestState.cartItems = [];
    // }
    // if (latestState.product) {
    //   latestState.product.items.items = [];
    //   latestState.product.items.orderType = null;
    //   latestState.product.items.totalCount = null;
    // }
    // if (latestState.brandSubBrand) {
    //   latestState.brandSubBrand.brand = null;
    //   latestState.brandSubBrand.subBrand = null;
    // }
    // if (latestState.orderHistory) {
    //   latestState.orderHistory.items = [];
    // }
    // if (latestState.creditAmount) {
    //   latestState.creditAmount.items = [];
    // }
    // }
  };
