import { MenuItem, Select, Menu, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { LocalContext } from "../../../LocalProvider";

const LanguageMenu = () => {
  const {handleLanguageChange, selectedLocaleLang, languageOptions} = useContext(LocalContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (languageKey) => {
      setAnchorEl(null);
  };

  const handleChange = (languageKey) => {
      setAnchorEl(null);
      handleLanguageChange(languageKey);
  }

  return (
    <div>
      <Button
        style={{color: "black", textTransform: 'unset'}}
        id="language-selector-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faLanguage} style={{marginRight: 5}}/>
          {selectedLocaleLang === "en" ? "English" : "Spanish"}
        <FontAwesomeIcon icon={faCaretDown} style={{marginLeft: 5, marginBottom: 3}}/>
      </Button>
      <Menu
        id="language-selector-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{"aria-labelledby": "language-selector-button"}}
      >
        {languageOptions.map((item, i) => {
          return( <MenuItem key={i} onClick={() => handleChange(item.languageKey)}>{item.language}</MenuItem>)
        })}
      </Menu>
    </div>
  );
};

export default LanguageMenu;
