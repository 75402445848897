import PersonIcon from "@mui/icons-material/Person";
import { Box, Typography, IconButton, InputAdornment } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginHook } from "../../../hooks/useLoginHook";
import { colors, fontSizes } from "../../../styles/variables";
import Button from "../../common/button/Button.component";
import HelplineContact from "../../common/helplineContact/HelplineContact.component";
import LabelCheckbox from "../../common/input/checkbox/Checkbox.component";
import LableTextInput from "../../common/input/LabelTextInput/LableTextInput.component";
import { useStyles } from "../Auth.styles";
import AuthLayout from "../AuthLayout.component";
import { useLocation } from "react-router-dom";
import { getEnv } from "../../../utils/urls";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LocalContext } from "../../../LocalProvider";

export default function Login() {
  const classes = useStyles();
  const location = useLocation();
  let redirectToPath;
  if(location.state && location.state.path){
    redirectToPath = location.state.path;
  }

  const {
    showPassword,
    values,
    handleChange,
    errorMsg,
    error,
    signIn,
    handleClickShowPassword,
  } = useLoginHook(redirectToPath);
  const navigate = useNavigate();
  const siteId = getEnv().siteId;
  const { translate } = useContext(LocalContext);
  const onRegister = () => navigate("/register");
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onEnterPress = (e) => {
    if(e.keyCode === 13) {
      e.preventDefault();
      signIn(e);
    }
  }

  return (
    <AuthLayout>
      <Box component="form" display="flex" flexDirection="column">
        <Typography variant="h4" className={classes.heading}>
          {translate("mx.whatsapp.hello")}
          <Typography
            variant="subtitle1"
            color="success.main"
            marginTop={0.3}
            className={classes.subHeading}
          >
           {translate("mx.whatsapp.to_login_enter_your_credentials ")}.
          </Typography>
        </Typography>
        {siteId === "6" ? (
          <LableTextInput
            label={translate("mx.whatsapp.phone_number")}
            id="userid"
            type="tel"
            placeholder={translate("mx.whatsapp.enter_your_phone_number")}
            name="username"
            value={values.username}
            onChange={handleChange}
            error = {errorMsg}
          />
        ) : (
          <LableTextInput
            label="User Id"
            id="userid"
            type="email"
            error = {errorMsg}
            placeholder="Enter your user id"
            name="username"
            value={values.username}
            onChange={handleChange}
          />
        )}
        <LableTextInput
          label={translate("mx.whatsapp.password")}
          id="password"
          type={showPassword ? "text" : "password"}
          placeholder="Enter your password"
          name="password"
          error = {errorMsg}
          value={values.password}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyDown={onEnterPress}
        />
           {errorMsg && <Typography
          variant="body2"
          color={colors.color28}
          fontSize={fontSizes.font12}
          sx={{ cursor: "pointer" }}
        >
          {errorMsg}
        </Typography>}
        <Typography
          variant="subtitle2"
          color={colors.color25}
          fontWeight={fontSizes.font14}
          sx={{ cursor: "pointer" }}
          onClick={()=> navigate("/forgot-password")}
        >
          {translate('mx.whatsapp.forgot_your_password')}
        </Typography>
        {/* {!phoneNumber && <LabelCheckbox label="Remember me" />} */}
        <Button
          type="button"
          onClick={signIn}
          disabled={values.isFetching}
          isLoading={values.isFetching}
        >
          {translate('mx.whatsapp.sign_in')}
        </Button>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          marginTop={"35px"}
          fontSize={fontSizes.font14}
          color={colors.color27}
        >
          {translate('mx.whatsapp.dont_have_an_account')}
        </Typography>
        <Typography
          variant="subtitle2"
          color={colors.color25}
          fontWeight="bold"
          sx={{ cursor: "pointer" }}
          display="flex"
          alignItems={"center"}
          marginTop={"0.5rem"}
          onClick={onRegister}
        >
          <PersonIcon
            sx={{ marginRight: "10px", color: `${colors.color25}` }}
          />{" "}
          {translate('mx.whatsapp.register_now')}
        </Typography>
        <HelplineContact />
      </Box>
    </AuthLayout>
  );
}
