import { loadingEnd, loadingStart } from "./root.actions";

/* 
1> Update store with user action data (Sync)
2> Update the backend with user action data (Async)
3> Get the latest cart data on page load. (Async)

*/
export const cartActionTypes = {
  FETCH_CART_DATA_SUCCESS: "FETCH_CART_DATA_SUCCESS",
  FETCH_CART_DATA_FAILURE: "FETCH_CART_DATA_FAILURE",
};

const fetchCartDataSuccessAction = (data) => {
  return {
    type: cartActionTypes.FETCH_CART_DATA_SUCCESS,
    data,
  };
};

export const increaseCartDataAction = (productInfo) => (dispatch, getState) => {
  dispatch(loadingStart());
  const latestState = getState();
  const cartItemData = latestState.cart.items.find(
    (item) => item.id === productInfo.id
  );
  if (!cartItemData) {
    productInfo.count = parseInt(productInfo.count) + 1;
    latestState.cart.items.push(productInfo);
  } else {
    cartItemData.count++;
  }
  dispatch(fetchCartDataSuccessAction(latestState.cart.items));
  dispatch(loadingEnd());
};

export const deleteCartDataAction = (id) => (dispatch, getState) => {
  dispatch(loadingStart());
  let latestState = getState();
  let updatedProduct = latestState.cart.items.filter(
    (product) => product.id !== id
  );
  if (updatedProduct.length === 0) {
    let productDetails = latestState.product.items.find(
      (product) => product.id === id
    );
    productDetails.count = 0;

    latestState.cart.items = [];
    updatedProduct = [];
  }
  dispatch(fetchCartDataSuccessAction(updatedProduct));
  dispatch(loadingEnd());
};

export const clearCartDataAction = () => (dispatch, getState) => {
  dispatch(loadingStart());
  let latestState = getState();
  latestState.cart.items = [];
  let cartUpdated = latestState.cart.items;
  // latestState.product.items.map((item) => {
  //   if (item.count > 0) {
  //     item.count = 0;
  //   }
  //   return item;
  // });
  dispatch(fetchCartDataSuccessAction(cartUpdated));
  dispatch(loadingEnd());
};

export const decreaseCartDataAction = (productInfo) => (dispatch, getState) => {
  dispatch(loadingStart());
  let latestState = getState();
  let cartItem = latestState.cart.items.find(
    (item) => item.id === productInfo.id
  );
  if (cartItem) {
    cartItem.count--;
  }

  if (cartItem && cartItem.count === 0) {
    // remove cartItem.ProductId from cart
    latestState.cart.items = latestState.cart.items.filter(
      (item) => item.id !== productInfo.id
    );
  }
  dispatch(fetchCartDataSuccessAction(latestState.cart.items));
  dispatch(loadingEnd());
};

export const updateCartInputDataAction =
  (productInfo, value) => (dispatch, getState) => {
    dispatch(loadingStart());
    const latestState = getState();
    // TODO: Update productID to id in entire action
    const cartItem = latestState.cart.items.find(
      (item) => item.id === productInfo.id
    );
    if (!cartItem) {
      if (value > 0) {
        // value = 0 ? parseInt(value) : value;
        productInfo.count = parseInt(value);
        console.log(productInfo.count);
        latestState.cart.items.push(productInfo);
      }
    } else {
      value === '' ? value = 0 : value;
      productInfo.count = value;
      // latestState.cart.items.push(productInfo);
      cartItem.count = value;
    }
    dispatch(fetchCartDataSuccessAction(latestState.cart.items));
    dispatch(loadingEnd());
  };

// export const editOrderDataAction = (row) => (dispatch, getState) => {
//   // console.log("row ==>", row);
//   let latestState = getState();
//   console.log("latestState.cartItems ==>", latestState.cartItems);
//   latestState.cart.items = [];
//   latestState.cart.items = row.productList;

//   latestState.product.items.map((item) => {
//     if (item.count > 0) {
//       item.count = 0;
//     }
//   });
//   dispatch(loadingStart());
//   setTimeout(() => {
//     dispatch(loadingEnd());
//     dispatch(fetchCartDataSuccessAction(latestState.cart.items));
//   }, 500);
// };

export const fetchCartDataAction = () => (dispatch) => {
  dispatch(loadingStart());

  // TODO: need to be changed to actual API call implementation

  // FIXME: test
  // setTimeout is just for demonstration purposes
  setTimeout(() => {
    dispatch(loadingEnd());
    // dispatch(fetchCartDataSuccessAction(cartData));
  }, 500);
};

export const moveCartItemsToCartAction =
  (orderTypeCart) => (dispatch, getState) => {
    const latestState = getState();
    const cartItemsCopy = latestState.cartItems.items;

    let cartArray = [];
    if (orderTypeCart !== "ZYCO") {
      cartItemsCopy &&
        cartItemsCopy.map((cartItem) => {
          cartArray.push(cartItem);
          return cartArray;
        });
    }
    dispatch(fetchCartDataSuccessAction(cartArray));
  };
