import { getMethod } from "../../utils/api";
import { getStoreAPIUrl } from "../../utils/urls";


export const storeActionTypes = {
    GET_STORE_DETAILS: "GET_STORE_DETAILS"
}

export const getStoresActionData = (data) => {
    return {
        type: storeActionTypes.GET_STORE_DETAILS,
        data
    }
}

export const getStoreDetails = () => async (dispatch) => {
    //USer id needs to be taken from registration flow later
    return await getMethod(`${getStoreAPIUrl}?userId=${"45675675656@pepsico.com"}`)
        .then((data) => {
            dispatch(getStoresActionData(data));
            return data;
        })
        .catch((err) => console.log(err));
}
