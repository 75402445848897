import { getEnv, getTranslations } from "./../../utils/urls";
// import { loadingEnd, loadingStart } from "./root.actions";
import { getMethod } from "../../utils/api";

export const languageActionTypes = {
  FETCH_LANGUAGE_DATA_SUCCESS: "FETCH_LANGUAGE_DATA_SUCCESS",
  CHANGE_LANGUAGE_DATA_SUCCESS: "CHANGE_LANGUAGE_DATA_SUCCESS",
};

const fetchLanguageDataSuccessAction = (data) => {
  return {
    type: languageActionTypes.FETCH_LANGUAGE_DATA_SUCCESS,
    data,
  };
};

export const getLanguageAction = () => async (dispatch) => {
  try {
    let data = await getMethod(getTranslations);
    if (data.success) {
      const langData = {
        languageData: data,
        selectedLanguage: getEnv().siteId == 1 ? "zh" : "en",
      };
      dispatch(fetchLanguageDataSuccessAction(langData));
    }
  } catch (error) {
    console.log(error)
  }
};

export const changeLanguageAction = (languageEnv) => (dispatch, getState) => {
  // dispatch(loadingStart());
  const latestState = getState();
  const cartItemData = latestState.language.languageData;
  const langData = {
    languageData: cartItemData,
    selectedLanguage: languageEnv,
  };
  dispatch(fetchLanguageDataSuccessAction(langData));
  // dispatch(loadingEnd());
};
