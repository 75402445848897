import { deleteMethod, getMethod, putMethod } from "../../utils/api";
import {
  countPointsAndTotalRewards,
  createAddCartData,
  ORDER_TYPES
} from "../../utils/common";
import { getCartEngagementUrl } from "../../utils/urls";
import {
  productsCartEngagementSelector,
  rewardsCartEngagementSelector
} from "../selectors/cartEngagement.selector";
export const cartEngagementActionTypes = {
  SET_CART_ENGAGEMENT_DATA: "SET_CART_ENGAGEMENT_DATA",
  SET_CHECKOUT_TYPE: "SET_CHECKOUT_TYPE",
  SET_CART_IS_FETCHED: "SET_CART_IS_FETCHED",
  CLEAR_CART_ENGAGEMENT: "CLEAR_CART_ENGAGEMENT",
};

export const setCartEngagementDataAction = (data, key) => {
  return {
    type: cartEngagementActionTypes.SET_CART_ENGAGEMENT_DATA,
    key,
    data,
  };
};

export const setCheckoutTypeAction = (data) => {
  return {
    type: cartEngagementActionTypes.SET_CHECKOUT_TYPE,
    data,
  };
};

export const setCartIsFetchedAction = (data) => {
  return {
    type: cartEngagementActionTypes.SET_CART_IS_FETCHED,
    payload: data,
  };
};
export const clearCartEngagementAction = (data) => {
  return {
    type: cartEngagementActionTypes.CLEAR_CART_ENGAGEMENT,
  };
};

const changeCheckoutType = (
  rewardsCart,
  productsCart,
  data,
  orderType,
  dispatch
) => {
  if (
    orderType === ORDER_TYPES.ZOR &&
    !data?.items?.length &&
    rewardsCart?.items?.length
  ) {
    return dispatch(setCheckoutTypeAction(ORDER_TYPES.REWARDS));
  }
  if (
    orderType === ORDER_TYPES.REWARDS &&
    !data?.items?.length &&
    productsCart?.items?.length
  ) {
    return dispatch(setCheckoutTypeAction(ORDER_TYPES.ZOR));
  }
  return dispatch(setCheckoutTypeAction(orderType));
};

export const getCartEngagement = (orderType) => async (dispatch, getState) => {
  try {
    if (orderType) {
      let url = `${getCartEngagementUrl}?orderType=${orderType}`;
      let data = await getMethod(url);
      if (data?.success === false) {
        // TODO: show error notification
        return data;
      }
      let total = countPointsAndTotalRewards(data.items || []);
      dispatch(setCartEngagementDataAction({ ...data, ...total }, orderType));
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addToCartEngagement =
  (orderType, item) => async (dispatch, getState) => {
    try {
      let quantity = 1;
      let rewardCart = rewardsCartEngagementSelector(getState());
      if (orderType === ORDER_TYPES.REWARDS) {
        let thisItem = rewardCart?.items?.find(
          (product) => product.id === item.id
        );
        if (thisItem) {
          quantity = parseInt(thisItem.count) + 1;
        }
      }
      let body = createAddCartData(
        orderType,
        item,
        quantity,
        rewardCart?.items
      );
      let data = await putMethod(getCartEngagementUrl, body);
      if (data?.success === false) {
        console.log("Error: ", data);
        // TODO: show error notification
        return data;
      }
      let total = countPointsAndTotalRewards(data.items || []);
      dispatch(
        setCartEngagementDataAction({ ...data, ...total }, data.orderType)
      );
      dispatch(setCheckoutTypeAction(orderType));
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const updateCartEngagement =
  (orderType, item, qty) => async (dispatch, getState) => {
    try {
      let cart = { items: [] };
      let rewardsCart = rewardsCartEngagementSelector(getState());
      let productsCart = productsCartEngagementSelector(getState());
      if (orderType === ORDER_TYPES.REWARDS) {
        cart = rewardsCart;
      }
      if (orderType === ORDER_TYPES.ZOR) {
        cart = JSON.parse(JSON.stringify(productsCart));
        cart.items = cart?.items?.map((item) => {
          item.unitType =
            item?.unitType?.find((unit) => unit.active === 1)?.key || "CS";
          return item;
        });
      }
      let body = createAddCartData(orderType, item, qty, cart?.items);
      let data = await putMethod(getCartEngagementUrl, body);
      if (data?.success === false) {
        console.log("Error: ", data);
        // TODO: show error notification
        return data;
      }
      let total = countPointsAndTotalRewards(data.items || []);
      dispatch(
        setCartEngagementDataAction({ ...data, ...total }, data.orderType)
      );
      changeCheckoutType(rewardsCart, productsCart, data, orderType, dispatch);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const putCartDataEngagement = (body) => async (dispatch, getState) => {
  try {
    let rewardsCart = rewardsCartEngagementSelector(getState());
    let productsCart = productsCartEngagementSelector(getState());
    let data = await putMethod(getCartEngagementUrl, body);
    if (data?.success === false) {
      console.log("Error: ", data);
      // TODO: show error notification
      return data;
    }
    let total = countPointsAndTotalRewards(data.items || []);
    dispatch(
      setCartEngagementDataAction({ ...data, ...total }, data.orderType)
    );
    changeCheckoutType(
      rewardsCart,
      productsCart,
      data,
      data.orderType,
      dispatch
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const clearCartDataEngagement = () => async (dispatch) => {
  try {
    let resp = await deleteMethod(getCartEngagementUrl);
    if (resp.success) {
      dispatch(clearCartEngagementAction());
    }
    return resp;
  } catch (error) {
    console.log(error);
  }
};

export const addPreviousOrderItems = (orderItems) => async (dispatch, getState) => {
  let totalItems = 0;
  let cartItems = productsCartEngagementSelector(getState());
  let cartItemsArr = cartItems?.items || [];
  let previousOrderItemsArr = orderItems;
  let cartItemsIds = cartItemsArr?.map(item => item.id);
  previousOrderItemsArr.map((item) => {
    if (cartItemsIds.includes(item.id)) {
      let cartItem = cartItemsArr.findIndex(cart => cart.id === item.id);
      cartItemsArr[cartItem].quantity = cartItemsArr[cartItem].quantity + item.quantity
    }
    else {
      let itemObj = {
        ...item,
        discountedPriceTotal: parseFloat(parseFloat(item?.price?.basePrice) * parseInt(item?.price?.saleUoms[0]?.conversionMultiplierToBase || 1))
      }
      cartItemsArr = [...cartItemsArr, itemObj];
    }
    totalItems = cartItemsArr.reduce(function (acc, obj) { return acc + obj.quantity }, 0);
    dispatch(setCartEngagementDataAction({ ...cartItems, items: cartItemsArr, totalCount: totalItems }, "ZOR"))
    // dispatch(setCartEngagementDataAction({ ...productsCart, items: productData, totalCount: totalItems }, "ZOR"));
  })

}
