import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getEnv } from "../../utils/urls";
import { rootActionTypes } from "../actions/root.actions";
import initialState from "../initial-state";
import brandSubBrandReducer from "./brandSubBrand.reducer";
import cartReducer from "./cart.reducer";
import cartEngagementReducer from "./cartEngagement.reducer";
import cartItemsReducer from "./cartItems.reducer";
import checkOutReducer from "./checkOut.reducer";
import creditAmountReducer from "./creditAmount.reducer";
import deliveryEstimateReducer from "./deliveryEstimate.reducer";
import earnExtraPointsReducer from "./earnExtraPoints.reducer";
import homePageReducer from "./homePage.reducer";
import languageReducer from "./language.reducer";
import modalsReducer from "./modals.reducer";
import myAccountReducer from "./myAccount.reducer";
import navItemsReducer from "./navItems.reducer";
import orderHistoryReducer from "./orderHistory.reducer";
import productReducer from "./product.reducer";
import redeemPointsReducer from "./redeemPoints.reducer";
import registerUserReducer from "./registerUser.reducer";
import rewardsHistoryReducer from "./rewardsHistory.reducer";
import authReducer from "./auth.reducer";
import storesReducer from "./stores.reducer";
import helpReducer from "./help.reducer";
import editOrderReducer from "./editOrder.reducer"
import welcomePageReducer from "./welcomePage.reducer";

const siteId = getEnv().siteId;
const persistConfig = {
  key: "root",
  storage,
  whitelist:
    siteId === "6"
      ? [
          // "cart",
          "product",
          "auth",
          // "myAccount",
          "cartEngagement",
          // "navItems",
          // "orderHistory",
          // "checkOut",
          "language",
          "welcomePage", 

        ]
      : [
          // "cart",
          // "product",
          // "cartEngagement",
          // "navItems",
          // "orderHistory",
          // "checkOut",
          "language",
        ],
};

const rootReducer = (state = initialState.root, action) => {
  if (action.type === rootActionTypes.LOADING_START) {
    return {
      ...state,
      loading: state.loading + 1,
    };
  } else if (action.type === rootActionTypes.LOADING_END) {
    return {
      ...state,
      loading: state.loading - 1,
    };
  }

  if (action.type === rootActionTypes.USER_LOGGED_OUT) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    return { ...initialState };
  }

  return state;
};


const rootReducerData = combineReducers({
  homePage: homePageReducer,
  navItems: navItemsReducer,
  cart: cartReducer,
  product: productReducer,
  brandSubBrand: brandSubBrandReducer,
  orderHistory: orderHistoryReducer,
  cartItems: cartItemsReducer,
  checkOut: checkOutReducer,
  myAccount: myAccountReducer,
  creditAmount: creditAmountReducer,
  root: rootReducer,
  language: languageReducer,
  earnExtraPoints: earnExtraPointsReducer,
  redeemPoints: redeemPointsReducer,
  cartEngagement: cartEngagementReducer,
  modals: modalsReducer,
  rewardsHistory: rewardsHistoryReducer,
  registerUser: registerUserReducer,
  stores: storesReducer,
  deliveryEstimate: deliveryEstimateReducer,
  auth: authReducer,
  help: helpReducer,
  editOrderCart: editOrderReducer,
  welcomePage: welcomePageReducer,
});
export default persistReducer(persistConfig, rootReducerData);
