import styled from "styled-components";
import { colors, mediaQueries } from "../../styles/variables";

const Anchor = styled.a`
  color: ${(props) => (props.color ? props.color : "inherit")};
  text-decoration: none;
  @media ${mediaQueries.belowiPad} {
    display: ${(props) => (props.hideLogoOnMobile ? 'none !important' : 'block')};
  }
  &:focus {
    outline-color: ${colors.black};
  }
`;

export default Anchor;
