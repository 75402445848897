import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import Loading from "../components/common/Loading.component";
import { pages } from "./config";

const Routes = () => {
  return <Suspense fallback={<Loading />}>{useRoutes(pages)}</Suspense>;
};

export default Routes;
