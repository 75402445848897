import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../styles/variables";

export const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.custom.headerBg,
    ['@media only screen and (max-width: 900px)'] : {
      zIndex: "100",
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
    }
  },
  singleColumn: { paddingBottom: 0 },
  topBtn: {
    marginLeft: "1rem",
    height: "40px",
  },
  toolbar: { minHeight: "auto" },
  subMenu: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  leftLinks: {
    marginTop: "1.2rem",
    color: theme.palette.custom.headerMenuItem,
    fontWeight: "bold",
    display: "flex",
    textTransform: "capitalize",
    height: "45px",
    padding: "1rem",
    margin: "0 0.5rem",
  },
  activeLink: {
    color: "#fff",
    backgroundColor: theme.palette.custom.headerMenuItemBg,
    borderRadius: "10px 10px 0 0",
    "&:hover": {
      backgroundColor: theme.palette.custom.headerMenuItemBg,
    },
  },
  paper: {
    boxShadow: "none",
    backgroundColor: theme.palette.custom.headerNavBarBg,
    borderRadius: "0",
    color: "#fff",
    paddingTop: "0.5rem",
  },
  menuList: {
    display: "flex",
    paddingBottom: 0,
    paddingTop: "4px"
  },
  listItemText: {
    paddingBottom: "0.5rem",
    margin: "0 0.8rem",
    "& span": {
      fontWeight: "bold",
      display: "block",
      textTransform: "capitalize",
      fontSize: "0.875rem",
    },
  },
  activeListItem: {
    color: "#0047BA",
    backgroundColor: "#fff",
    borderRadius: "10px 10px 0 0",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  drawerBox: {
    width: "88vw",
    height: "100%",
    // backgroundColor: "#0047BA",
  },
  desktopLogo: {
    height: '30px',
    width: '128px',
    marginBottom: '1em'
  },
  mobileLogo: {
    height: '30px',
    width: '128px',
    margin: "1rem 1.5rem 1.2rem 1rem",
  },
  mobileList: {
    marginTop: "0.5rem",
    marginLeft: "1.5rem",
  },
  mobileListButton: {
    paddingLeft: 0,
    flexGrow: "unset",
  },
  mobileListText: {
    "& .MuiTypography-body1": {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#fff",
    },
  },
  mobileExpandIcon: {
    color: "#fff",
    marginLeft: "0.5rem",
  },
  closeBtn: {
    position: "absolute",
    right: "-9px",
    top: "-9px",
    zIndex: "9999",
    color: "#fff",
    backgroundColor: "#171717",
    borderRadius: 0,
    padding: "12px",
  },
  cartIcon: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginBottom: "1rem",
      justifyContent: "flex-end",
    },
  },
}));

export const CartIcon = styled(NavLink)`
  position: relative;
  padding-top: 1.5rem;
  display: block;
  &.active {
    font-weight: bold;
  }
  @media (max-width: 768px) {
    display: block;
    color: ${colors.color15};
    padding-right: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const DivCart = styled.div`
  border-radius: 50%;
  display: inline-block;
  background: #ff4b4b;
  color: white;
  width: 25px;
  height: 25px;
  vertical-align: top;
  position: absolute;
  top: 12px;
  left: 14px;
  font-size: 0.8rem;
  @media (max-width: 768px) {
    right: 0;
    width: 20px;
    height: 22px;
  }
`;

export const SpanCart = styled.span`
  line-height: 25px;
  display: block;
  color: white;
  vertical-align: top;
  text-align: center;
  font-size: 0.75rem;
`;
