import PropTypes from "prop-types";
import { Component } from "react";
import classNames from "classnames";
import { capitalize } from "@mui/material/utils";
import withStyles from '@mui/styles/withStyles';

const videoStyle = (theme) => ({
  videoComponent: {
    display: "flex",
    position: "relative",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      // marginTop: '20px',
    },
  },
  root: {
    display: "block",
    width: "100%",
  },
  modeLight: {
    backgroundColor: "#000",
    display: "block",
    width: "100%",
    maxHeight: "40.625rem",
    [theme.breakpoints.down('sm')]: {
      maxHeight: "25.625rem",
    },
  },
  videoIcon: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    margin: "0 auto",
    [theme.breakpoints.down('sm')]: {
      left: "1",
      right: "auto",
    },
    "&:hover": {
      cursor: "pointer",
      "& path": {
        fill: "#005cb9",
      },
    },
  },
});

/**
 * @constant
 * @function
 * @param {object} props
 * @returns {element}
 * @see https://www.gatsbyjs.org/docs/adding-images-fonts-files/
 */
class VideoOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoPlayed: false,
    };
  }

  playVideo = () => {
    const { videoPlayed } = this.state;
    const video = document.getElementById("videoComp");
    video.play();
    this.setState({
      videoPlayed: !videoPlayed,
    });
  };

  render() {
    const { classes, mode, url, urlwebm, poster, autoPlay, loop } = this.props;
    return (
      <section className={classes.videoComponent}>
        <video
          id="videoComp"
          className={classNames(
            classes.root,
            classes[mode ? `mode${capitalize(mode)}` : ""]
          )}
          autoPlay={autoPlay}
          muted={true}
          preload="none"
          controls="controls"
          poster={poster}
          playsInline
          loop={loop}
        >
          <source src={url} type="video/mp4" />
          <source src={urlwebm} type="video/webm" />
        </video>
      </section>
    );
  }
}

VideoOne.defaultPropTypes = {
  muted: true,
  playsinline: true,
  loop: false,
};

VideoOne.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  urlwebm: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool.isRequired,
  // muted: PropTypes.bool,
};

export default withStyles(videoStyle, { withTheme: true })(VideoOne);
