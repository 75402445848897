import {
  deleteMethod,
  getMethod
} from "../../utils/api";
import { arrToJSON } from "../../utils/common";
import { allProductsSelector } from "../selectors/product.selector";
import {
  cancelOrderAPIUrl, getOrderDetailsAPIUrl,
  getOrderHistoryAPIUrl
} from "./../../utils/urls";
import { addPreviousOrderItems } from "./cartEngagement.actions";
import { setEditCartDataAction } from "./editOrderCart.action";
import { loadingEnd, loadingStart } from "./root.actions";

export const orderHistoryActionTypes = {
  FETCH_ORDER_HISTORY_DATA_SUCCESS: "FETCH_ORDER_HISTORY_DATA_SUCCESS",
  FETCH_ORDER_HISTORY_DATA_FAILTURE: "FETCH_ORDER_HISTORY_DATA_FAILURE",
  FETCH_SELECTED_ORDER_DATA_SUCCESS: "FETCH_SELECTED_ORDER_DATA_SUCCESS",
  FETCH_SELECTED_ORDER_DATA_FAILTURE: "FETCH_SELECTED_ORDER_DATA_FAILTURE",
  CANCEL_ORDER_DATA_SUCCESS: "CANCEL_ORDER_DATA_SUCCESS",
  CANCEL_ORDER_DATA_FAILURE: "CANCEL_ORDER_DATA_FAILURE",
  REMOVE_PRODUCT_DATA_SUCCESS: "REMOVE_PRODUCT_DATA_SUCCESS",
  UPDATE_PRODUCT_QUNATITY_DATA_SUCCESS: "UPDATE_PRODUCT_QUNATITY_DATA_SUCCESS",
  UPDATE_PRODUCT_REASONCODE_DATA_SUCCESS:
    "UPDATE_PRODUCT_REASONCODE_DATA_SUCCESS",
  UPDATE_PRODUCT_IMAGEUPLOAD_DATA_SUCCESS:
    "UPDATE_PRODUCT_IMAGEUPLOAD_DATA_SUCCESS"
};

export const orderHistoryClassTypes = {
  ORDER_CLASS_TYPE_CONFIRMED: "Confirmed",
  ORDER_CLASS_TYPE_COMPLETED: "Completed",
  ORDER_CLASS_TYPE_CANCELLED: "Order Cancelled",
  ORDER_CLASS_TYPE_BEINGPROCESSED: "Order is being processed",
  ORDER_CLASS_TYPE_ACKNOWLEDGED: "Order Acknowledged",
  ORDER_CLASS_TYPE_HASPROCESSED: "Order has been proceeded",
  ORDER_CLASS_TYPE_ISPROCESSING: "Order is processing",
  ORDER_CLASS_TYPE_CLAIMPROCESSING: "Claim processing",
  ORDER_CLASS_TYPE_CLAIMPENDING: "Claim pending",
  ORDER_CLASS_TYPE_CLAIMREJECTED: "Claim rejected",
  ORDER_CLASS_TYPE_CLAIMAPPROVED: "Claim approved",
};

const fetchOrderHistoryDataSuccessAction = (data) => {
  return {
    type: orderHistoryActionTypes.FETCH_ORDER_HISTORY_DATA_SUCCESS,
    data,
  };
};

const fetchSelectedOrderDataSuccessAction = (data) => {
  return {
    type: orderHistoryActionTypes.FETCH_SELECTED_ORDER_DATA_SUCCESS,
    data,
  };
};

const cancelOrderDataSuccessAction = (orderId) => {
  return {
    type: orderHistoryActionTypes.CANCEL_ORDER_DATA_SUCCESS,
    orderId,
  };
};

const fetchRemoveProductDataSuccessAction = (productId) => {
  return {
    type: orderHistoryActionTypes.REMOVE_PRODUCT_DATA_SUCCESS,
    productId,
  };
};

const fetchUpdateQunatityDataSuccessAction = (productInfo, productQuantity) => {
  return {
    type: orderHistoryActionTypes.UPDATE_PRODUCT_QUNATITY_DATA_SUCCESS,
    productInfo,
    productQuantity,
  };
};

const fetchUpdateReturnReasonSuccessAction = (
  productInfo,
  productReasonCode
) => {
  return {
    type: orderHistoryActionTypes.UPDATE_PRODUCT_REASONCODE_DATA_SUCCESS,
    productInfo,
    productReasonCode,
  };
};

const fetchUpdateImageUploadSuccessAction = (productId, payload) => {
  return {
    type: orderHistoryActionTypes.UPDATE_PRODUCT_IMAGEUPLOAD_DATA_SUCCESS,
    productId,
    payload,
  };
};

export const fetchOrderHistoryAction =
  (isAuth, token, storeName, orderType) => async (dispatch) => {
    dispatch(loadingStart());
    return await getMethod(
      `${getOrderHistoryAPIUrl}?orderType=${orderType}
      `, {
      headers: { "StoreId": storeName },
    }
    )
      .then((data) => {
        dispatch(fetchOrderHistoryDataSuccessAction(data));
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingEnd());
      });
  };

export const fetchSelectedOrderHistoryAction =
  (isAuth, orderId, storeName, isEditOrder) => async (dispatch, getState) => {
    dispatch(loadingStart());
    let productData = allProductsSelector(getState());
    const queryParams = `?orderNumber=${orderId}&orderType=ZOR`
    return await getMethod(getOrderDetailsAPIUrl + queryParams, {
      headers: { "StoreId": storeName },
    })
      .then((data) => {
        let formattedOrdHisData = formatOrderHistoryRes(data?.orders[0], productData);
        dispatch(fetchSelectedOrderDataSuccessAction(formattedOrdHisData));
        if (isEditOrder) {
          const selectedData = JSON.parse(JSON.stringify(formattedOrdHisData));
          selectedData.orderNumber = data?.orders[0].orderNumber;
          dispatch(setEditCartDataAction(selectedData));
        }
        dispatch(loadingEnd());
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingEnd());
      });
  };

export const cancelOrderDataAction =
  (orderId, storeId) => async (dispatch) => {
    dispatch(loadingStart());
    return await deleteMethod(`${cancelOrderAPIUrl}?orderId=${orderId}`,
      { headers: { "storeId": storeId } })
      .then((data) => {
        dispatch(loadingEnd());
        return data;
        // dispatch(cancelOrderDataSuccessAction(orderId));
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingEnd());
      });
  };

export const deleteOrderDataAction =
  (isAuth, token, orderId) => async (dispatch) => {
    dispatch(loadingStart());
    return await deleteMethod(deleteOrderAPIUrl + "/" + orderId)
      .then((data) => {
        dispatch(cancelOrderDataSuccessAction(orderId));
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingEnd());
      });
  };

export const orderInfoDataAction =
  (isAuth, token, orderId) => async (dispatch) => {
    dispatch(loadingStart());
    return await getMethod(orderInfoAPIUrl + "?orderId=" + orderId)
      .then((data) => {
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingEnd());
      });
  };

export const returnMaterialReasonCodeDataAction =
  (token) => async (dispatch) => {
    dispatch(loadingStart());
    return await getMethod(returnMaterialReasonCodeAPIUrl)
      .then((data) => {
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingEnd());
      });
  };

export const deleteReturnItemsDataAction = (productId) => (dispatch) => {
  dispatch(loadingStart());
  dispatch(fetchRemoveProductDataSuccessAction(productId));
  dispatch(loadingEnd());
};

export const updateReturnQunatityDataAction =
  (productInfo, productQuantity) => (dispatch) => {
    if (productQuantity >= 1) {
      dispatch(loadingStart());
      dispatch(
        fetchUpdateQunatityDataSuccessAction(productInfo, productQuantity)
      );
      dispatch(loadingEnd());
    }
  };

export const updateReturnReasonCodeDataAction =
  (productInfo, productReasonCode) => (dispatch) => {
    dispatch(loadingStart());
    dispatch(
      fetchUpdateReturnReasonSuccessAction(productInfo, productReasonCode)
    );
    dispatch(loadingEnd());
  };

// RMA: Image Upload action
export const updateImageUploadCodeDataAction =
  (productId, payload, isAuth, token) => async (dispatch) => {
    dispatch(loadingStart());
    await postMethod(addReturnOrderImagesAPIUrl, payload)
      .then((data) => {
        console.log("data ==>", data);
        dispatch(fetchUpdateImageUploadSuccessAction(productId, payload));
      })
      .catch((err) => console.log(err));
    dispatch(loadingEnd());
  };

export const createReturnOrderDataAction =
  (token, payload) => async (dispatch) => {
    dispatch(loadingStart());
    return await postMethod(createReturnOrderAPIUrl, payload)
      .then((data) => {
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingEnd());
      });
  };

// FETCH INVOICE PDF FOR RETURN (NO NEED TO STORE IN REDUX)
export const fetchDownloadPDFAction =
  (isAuth, token, urlLink) => async (dispatch) => {
    dispatch(loadingStart());
    return await getMethod(downloadPDFReturnOrderAPIUrl + "/" + urlLink)
      .then((data) => {
        dispatch(loadingEnd());
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingEnd());
      });
  };

export const formatOrderHistoryRes = (ordHisData, prodData) => {
  //Converting the product data to JSON to take corresponding data when iterating order products.
  let prodDataVal = arrToJSON(prodData, "id");
  //Array used to hold updated data of order products 
  let prodArray = [];
  //Mapping the ord history products and adding base price and final price to it.
  ordHisData?.items?.map(orderProduct => {
    let prodVal = prodDataVal[orderProduct?.id];
    prodVal = {
      ...prodVal,
      oldFinalPrice: orderProduct?.finalPrice,
      quantity: orderProduct?.quantity
    };
    prodArray.push(prodVal);
  })
  return ({ ...ordHisData, items: prodArray });
}

export const reOrderAction = (orderDetail) => async (dispatch, getState) => {
  let productData = allProductsSelector(getState());
  let formattedOrderHisRes = formatOrderHistoryRes(orderDetail, productData);
  dispatch(addPreviousOrderItems(formattedOrderHisRes?.items));
}
