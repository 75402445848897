import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
    borderRadius: "10px",
    [theme.breakpoints.down("xl")]: { height: "220px" },
    [theme.breakpoints.down("lg")]: { height: "250px" },
    [theme.breakpoints.down("md")]: { height: "220px" },
  },
  slider: {
    "& .slick-dots": {
      bottom: "auto",
      "& li": {
        "& button": {
          "&::before": { fontSize: "1rem" },
        },
      },
      "& li.slick-active": {
        "& button": {
          "&::before": { color: "#000" },
        },
      },
    },
  },
}));
