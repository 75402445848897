import { getSelectedAssociatedCustomer } from "../components/common/helper/helper";
import { apiHeaderWithSiteId } from "./urls";

const initialOptions = {
  headers: {},
};

/**
 * GET method api call
 * @param {*} url
 * @param {*} options
 * @returns promise<response>
 */
export const getMethod = (url, options) => {
  let headers =  JSON.parse(JSON.stringify(apiHeaderWithSiteId));
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  if (options?.headers) {
    headers = {
      ...headers,
      ...options.headers,
    };
  }
  return new Promise((resolve, reject) => {
    try {
      fetch(url, {
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          if (response?.ok) {
            return response.json();
          }
          reject(response);
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
/**
 * POST method api call
 * @param {string} url
 * @param {object} body
 * @param {object} options
 * @returns promise<response>
 */
export const postMethod = (url, body, options = initialOptions) => {
  let headers = JSON.parse(JSON.stringify(apiHeaderWithSiteId));;
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  headers = {
    ...headers,
    ...options.headers,
  };
  return new Promise((resolve, reject) => {
    try {
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response?.ok) {
            return response.json();
          }
          reject(response);
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    } catch (error) {
      reject(error);
    }
  });
};
/**
 * PUT method api call
 * @param {string} url
 * @param {object} body
 * @param {object} options
 * @returns promise<response>
 */
export const putMethod = (url, body, options = initialOptions) => {
  let headers = JSON.parse(JSON.stringify(apiHeaderWithSiteId));;
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  headers = {
    ...headers,
    ...options.headers,
  };
  return new Promise((resolve, reject) => {
    try {
      fetch(url, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response?.ok) {
            return response.json();
          }
          reject(response);
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    } catch (error) {
      reject(error);
    }
  });
};
/**
 * DELETE method api call
 * @param {*} url
 * @param {*} options
 * @returns promise<response>
 */
export const deleteMethod = (url, options = initialOptions) => {
  let headers = JSON.parse(JSON.stringify(apiHeaderWithSiteId));;
  if (getSelectedAssociatedCustomer() !== null) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  headers = {
    ...headers,
    ...options.headers,
  };
  return new Promise((resolve, reject) => {
    try {
      fetch(url, {
        method: "DELETE",
        headers: headers,
      })
        .then((response) => {
          if (response?.ok) {
            return response.json();
          }
          reject(response);
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
