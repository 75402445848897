import { contentDataActionTypes } from "../actions/earnExtraPoints.actions";

export default function earnExtraPointsReducer(state = null, action) {
  switch (action.type) {
    case contentDataActionTypes.FETCH_CONTENT_API_SUCCESS:
      return { ...state, ...action.data };
    case contentDataActionTypes.UPDATE_CONTENT_KEY_DATA:
      return { ...state, [action.key]: action.data };
    default:
      return state;
  }
}
