import { CONTENT_TYPES } from "../../utils/common";

export const allContentSelector = (state) =>
  state.earnExtraPoints ? state.earnExtraPoints : {};

export const allContentItemsSelector = (state) =>
  state?.earnExtraPoints?.items ? state.earnExtraPoints.items : [];

export const filteredContentDataSelector = (state) => {
  let items = allContentItemsSelector(state);
  return items.filter((item) => {
    let contentType = item.contentType;
    return CONTENT_TYPES.indexOf(contentType) >= 0;
  });
};

export const allContentTypeSelector = (state) =>
  state.earnExtraPoints.contentType ? state.earnExtraPoints.contentType : [];

export const allContentObjSelector = (state) =>
  state.earnExtraPoints.contentObj ? state.earnExtraPoints.contentObj : [];

/** Home page content data as per tags selector */
export const homeAllContentSelector = (state) =>
  state.earnExtraPoints.allHomeContent
    ? state.earnExtraPoints.allHomeContent
    : [];
export const homeTagsContentSelector = (state) =>
  state.earnExtraPoints.tagsContent ? state.earnExtraPoints.tagsContent : {};
export const earnPointsContentSelector = (state) =>
  state.earnExtraPoints.earnPointsContent ? state.earnExtraPoints.earnPointsContent : {};
