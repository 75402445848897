import HomeComponent from "../components/home/Home.Component";
import HomeUnAuthComponent from "../components/home/HomeUnAuth.Component";
import { useHomeDataHook } from "../hooks/useHomeData.hook";
import { getAuthToken } from "../store/selectors/auth.selector";
import { useSelector } from "react-redux";
const Homepage = () => {
  const {
    infoMsg,
    allContentData,
    isUserAuth,
    loyaltyEnabled,
    homeTagsContent,
    earnPointsContent,
    redeemPointsItems,
    productData,
  } = useHomeDataHook();
  const token = useSelector(getAuthToken);

  // const renderHomePageConditionally = () => {
  //   if (token) {
  //     return (
  //       <div style={{ height: "inherit" }}>
  //         <HomeComponent
  //           homepageData={[]}
  //           allContentData={allContentData}
  //           homeTagsContent={homeTagsContent}
  //           isUserAuth={isUserAuth}
  //           loyaltyEnabled={loyaltyEnabled}
  //           earnPointsContent={earnPointsContent}
  //           redeemPointsItems={redeemPointsItems}
  //           productData={productData}
  //         />
  //       </div>
  //     );
  //   }
  //   return <HomeUnAuthComponent allContentData={allContentData} />;
  // };
  //
  // return renderHomePageConditionally();

  return (
      <div style={{ height: "inherit" }}>
        <HomeComponent
            homepageData={[]}
            allContentData={allContentData}
            homeTagsContent={homeTagsContent}
            isUserAuth={isUserAuth}
            loyaltyEnabled={loyaltyEnabled}
            earnPointsContent={earnPointsContent}
            redeemPointsItems={redeemPointsItems}
            productData={productData}
        />
      </div>
  );



};

Homepage.propTypes = {};

export default Homepage;
