import Close from "@mui/icons-material/Close";
import { Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { toggleModalAction } from "../../store/actions/modals.actions";
import HelplineContact from "../common/helplineContact/HelplineContact.component";
import { useFullModalStyles } from "./Modal.styles";

const FullScreenModal = (props) => {
    const dispatch = useDispatch();
    // const modalOpen = useSelector(modalSelector);
    const { headingText, iconRequired, helperText, topButton1,
        topButton1Click,
        bottomText, bottomButton1, bottomButton1Click,
        bottomButton2, bottomButton2Click, id, open, isHelplineRequired, noClose, icon } = props;
    const classes = useFullModalStyles();
    // const [open, setOpen] = useState(true);
    const onClose = () => {
        dispatch(toggleModalAction(id, false));
    }
    return (
        <Dialog
            open={open}
            scroll="body"
            fullWidth
            maxWidth="lg"
            classes={{ paper: classes.paper }}
        >
            <DialogTitle id="scroll-dialog-title">
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end">
                    {!noClose && <Grid item >
                        <Close onClick={onClose} />
                    </Grid>}
                </Grid>
            </DialogTitle>
            {/* )} */}
            <DialogContent>
                <Grid container direction="column" justifyContent="space-between" alignItems="center" className={classes.bottomContainer}>
                    <Grid item>
                        <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center">
                            {headingText &&
                                <Grid item className={classes.headingText}>
                                    {headingText}
                                </Grid>
                            }
                            {iconRequired &&
                                <Grid item>
                                    {icon}
                                </Grid>
                            }
                            {helperText &&
                                <Grid item className={classes.helperText}>
                                    {helperText}
                                </Grid>
                            }
                            {topButton1 &&
                                <Grid item>
                                    <Button variant="outlined" className={classes.button1} onClick={topButton1Click}>
                                        {topButton1}
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={4} alignItems="center">
                            {bottomText &&
                                <Grid item className={classes.bottomText}>
                                    {bottomText}
                                </Grid>
                            }
                            {bottomButton1 &&
                                <Grid item>
                                    <Button variant="outlined" className={classes.button1} id = {props.id} onClick={bottomButton1Click}>
                                        {bottomButton1}
                                    </Button>
                                </Grid>
                            }
                            {bottomButton2 &&
                                <Grid item>
                                    <Button className={classes.buttonText} onClick={bottomButton2Click}>
                                        {bottomButton2}
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {isHelplineRequired &&
                        <Grid item>
                            <HelplineContact />
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            {/* </Paper> */}
        </Dialog >

    )
};

export default FullScreenModal;