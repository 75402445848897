export const deliveryPreferenceModalSelector = (state) =>
  state.modals.deliveryPreference ? state.modals.deliveryPreference : false;

export const accountReadySelector = (state) =>
  state.modals.accountReady ? state.modals.accountReady : false;

export const cartConfirmationSelector = (state) =>
  state.modals.cartConfirmation ? state.modals.cartConfirmation : false;

export const registrationErrorSelector = (state) =>
  state.modals.registrationError ? state.modals.registrationError : false;

export const resetPasswordSelector = (state) => state.modals.resetPassword ? state.modals.resetPassword : false;

export const addProductsModalSelector = (state) => state.modals.addProducts ? state.modals.addProducts : false;

export const cancelOrderModalSelector = (state) => state.modals.cancelOrder ? state.modals.cancelOrder : false;