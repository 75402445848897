import { ContactlessOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchGetAccessToken,
  fetchGetCheckSupervisor,
  getCustomerArchiveStatus,
  updateLoginDetails,
} from "../store/actions/auth.actions";
import oktaAuth from "../utils/okta";
import { isOktaLogin, getEnv } from "../utils/urls";

export const useLoginHook = (redirectToPath) => {
  const [values, setValues] = useState({
    username: "",
    password: "",
    showPassword: false,
    isFetching: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const siteId = getEnv().siteId;
  

  const [errorMsg, setErrorMsg] = useState("");
  // const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    
    if(errorMsg){
      setErrorMsg("");
    }
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleOktaLogin = async () => {
    try {
      if (values.username && values.password) {
        setValues({ ...values, isFetching: true });

        const payload = {
          // TODO: NEED CONFIRMATION whether Whatsapp comes under loyalty or not
          username: getEnv().loyaltyEnabled
            ? siteId === '6' ?
              "cep06-" + values.username + "@pepsico.com" : /* TODO: Add this in the env varible */
              "cep07-" + values.username
            : values.username,
          password: values.password,
        };
        const transaction = await oktaAuth.signInWithCredentials(payload);
        setValues({ ...values, isFetching: false });
        if (transaction.status === "SUCCESS") {
          console.log("token is ",transaction);
          let res = await oktaAuth.signInWithRedirect({
            sessionToken: transaction.sessionToken,
            originalUri: redirectToPath? redirectToPath : "/home",
          });
          console.log("response is ",res);
        } else {
          let invalidAttempt = localStorage.getItem("invalidAttempt")
            ? parseInt(localStorage.getItem("invalidAttempt")) + 1
            : 1;
          localStorage.setItem("invalidAttempt", invalidAttempt);
          setErrorMsg(`Could not sign in: ${transaction.status}`);
        }
      }
      else setErrorMsg('Please enter both username and password to proceed');
    } catch (error) {
      console.log(error);
      let invalidAttempt = localStorage.getItem("invalidAttempt")
        ? parseInt(localStorage.getItem("invalidAttempt")) + 1
        : 1;
      localStorage.setItem("invalidAttempt", invalidAttempt);
      setErrorMsg(
        `Could not sign in: ${error?.errorSummary || "Authentication failed"}`
      );
      // setError(true);
      setValues({ ...values, isFetching: false });
    }
  };

  const handleApiLogin = () => {
    const payload = {
      customerId: values.username,
      password: values.password,
      openId: getEnv().apOpenId,
      displayName: getEnv().displayName,
    };
    dispatch(fetchGetAccessToken(payload))
      .then((res) => {
        if (res && res.success === false) {
          let invalidAttempt = localStorage.getItem("invalidAttempt")
            ? parseInt(localStorage.getItem("invalidAttempt")) + 1
            : 1;
          localStorage.setItem("invalidAttempt", invalidAttempt);
          setErrorMsg(res.msg);
        } else if (res.changePasswordRequired === true) {
          navigate({
            pathname: "/change-password",
            search: "?changepwd=1",
            state: {
              userType: res.userType,
              newUser: true,
            },
          });
        } else {
          dispatch(fetchGetCheckSupervisor(res.token, true)).then(
            (checkSuper) => {
              if (
                checkSuper &&
                checkSuper.associatedCustomers &&
                checkSuper.associatedCustomers.length === 1
              ) {
                if (
                  checkSuper &&
                  checkSuper.associatedCustomers &&
                  checkSuper.associatedCustomers[0].blocked === true
                ) {
                  setErrorMsg(
                    "Contact Customer Service regarding login credentials"
                  );
                }
              } else {
                // setOpen(false);
                setErrorMsg("");
                dispatch(
                  updateLoginDetails(
                    res.token,
                    res.userType,
                    res.changePasswordRequired
                  )
                );
                // navigate("/home");
              }
            }
          );
          // setOpen(false);
          // setErrorMsg("");
          // if (res.userType !== "Profile") {
          //   navigate("/home");
          // }
          dispatch(getCustomerArchiveStatus(res.token));
        }
      })
      .catch((err) => {
        setErrorMsg(err);
      });
  };

  /* TODO: Move API Calls to authentication */
  const signIn = async (event) => {
    event.preventDefault();
    setErrorMsg("");
    if (parseInt(localStorage.getItem("invalidAttempt")) > 4) {
      setErrorMsg("Your account is locked please try again after 5 min");
    } else {
      localStorage.setItem("userId", values.email ? values.email : "");
      if (isOktaLogin) {
        handleOktaLogin();
      } else {
        handleApiLogin();
      }
    }
  };

  return {
    values,
    showPassword,
    errorMsg,
    signIn,
    setValues,
    setErrorMsg,
    handleChange,
    handleClickShowPassword
  };
};
