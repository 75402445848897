import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  height: inherit;
  
  @media only screen and (max-width: 900px) {
    margin-top: 73.2px;
  }
`;

export default Container;
