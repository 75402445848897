import { postMethod } from "../../utils/api";
import {
  productSubBrandsAPIUrl
} from "../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

/* 
1> Update store with user action data (Sync)
2> Update the backend with user action data (Async)
3> Get the latest cart data on page load. (Async)

*/
export const brandSubBrandActionTypes = {
  FETCH_BRANDSUBBRAND_DATA_SUCCESS: "FETCH_BRANDSUBBRAND_DATA_SUCCESS",
  FETCH_BRANDSUBBRAND_DATA_FAILURE: "FETCH_BRANDSUBBRAND_DATA_FAILURE",
  FETCH_SUBBRAND_DATA_SUCCESS: "FETCH_SUBBRAND_DATA_SUCCESS",
  FETCH_SUBBRAND_DATA_FAILURE: "FETCH_SUBBRAND_DATA_FAILURE",
};

export const fetchBrandSubBrandDataSuccessAction = (data) => {
  return {
    type: brandSubBrandActionTypes.FETCH_BRANDSUBBRAND_DATA_SUCCESS,
    data,
  };
};

const fetchSubBrandDataSuccessAction = (data) => {
  return {
    type: brandSubBrandActionTypes.FETCH_SUBBRAND_DATA_SUCCESS,
    data,
  };
};

export const fetchBrandDataAction =
  (payload = {}) =>
    async (dispatch, getState) => {
      // try {

      //   const latestState = getState();
      //   isAuth = isAuthenticated(latestState);
      //   let brandAPIData = { ...latestState.brandSubBrand.brand };
      //   dispatch(loadingStart());
      //   return await postMethod(productBrandsAPIUrl, payload)
      //     .then((data) => {
      //       brandAPIData = data;
      dispatch(fetchBrandSubBrandDataSuccessAction(payload));
      //   dispatch(loadingEnd());
      // })
      // .catch((err) => console.log(err));

      //   TODO: need to be changed to actual API call implementation
      //   setTimeout is just for demonstration purposes
      // setTimeout(() => {
      //   dispatch(loadingEnd());
      //   // console.log("loading end");
      //   // console.log(productData);
      //   dispatch(fetchBrandSubBrandDataSuccessAction(brandSubBrandData));
      // }, 500);
      //  console.log("loading start");
      // } catch (error) {
      //   console.log(error)
      // }
    };

export const fetchSubBrandDataAction =
  (payload, isAuth) => async (dispatch, getState) => {
    try {
      // console.log("loading start");
      const latestState = getState();
      let subBrandAPIData = { ...latestState.brandSubBrand.subBrand };
      dispatch(loadingStart());
      return await postMethod(productSubBrandsAPIUrl, payload)
        .then((data) => {
          subBrandAPIData = data;
          dispatch(fetchSubBrandDataSuccessAction(subBrandAPIData, isAuth));
          dispatch(loadingEnd());
          return data;
        })
        .catch((err) => console.log(err));

      //   TODO: need to be changed to actual API call implementation
      //   setTimeout is just for demonstration purposes
      // setTimeout(() => {
      //   dispatch(loadingEnd());
      //   // console.log("loading end");
      //   // console.log(productData);
      //   dispatch(fetchBrandSubBrandDataSuccessAction(brandSubBrandData));
      // }, 500);
    } catch (error) {
      console.log(error)
    }
  };
