import { getMethod, postMethod } from "../../utils/api";
import {
  createOrderAPIUrl, getCustomerDetailsAPIUrl,
  getTermsDetailsAPIUrl
} from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

export const cartCheckoutActionTypes = {
  FETCH_CART_CHECKOUT_DATA_SUCCESS: "FETCH_CART_CHECKOUT_DATA_SUCCESS",
  FETCH_CART_CHECKOUT_DATA_FAILTURE: "FETCH_CART_CHECKOUT_DATA_FAILURE",
  FETCH_CART_CHECKOUT_TERMS_DATA_SUCCESS:
    "FETCH_CART_CHECKOUT_TERMS_DATA_SUCCESS",
  FETCH_CART_CHECKOUT_TERMS_DATA_FAILURE:
    "FETCH_CART_CHECKOUT_TERMS_DATA_FAILURE",
  FETCH_ORDER_DETAILS_SUCCESS: "FETCH_ORDER_DETAILS_SUCCESS",
  FETCH_ORDER_DETAILS_FAILURE: "FETCH_ORDER_DETAILS_FAILURE",
};

const fetchCartCheckoutSuccessAction = (data) => {
  return {
    type: cartCheckoutActionTypes.FETCH_CART_CHECKOUT_DATA_SUCCESS,
    data,
  };
};

const fetchCartCheckoutTermsSuccessAction = (data) => {
  return {
    type: cartCheckoutActionTypes.FETCH_CART_CHECKOUT_TERMS_DATA_SUCCESS,
    data,
  };
};

const fetchCreateOrderSuccessAction = (data) => {
  return {
    type: cartCheckoutActionTypes.FETCH_ORDER_DETAILS_SUCCESS,
    data,
  };
};

// TO GET CURRENT LOGGED-IN USER BILL,SHIP & SOLD TO ADDRESS
export const fetchCustomerDetailsAction = () => async (dispatch) => {
  dispatch(loadingStart());
  await getMethod(getCustomerDetailsAPIUrl)
    .then((data) => {
      dispatch(fetchCartCheckoutSuccessAction(data));
    })
    .catch((err) => console.log(err));
  dispatch(loadingEnd());
};

// TO GET TERMS & CONDITIONS TO PLACE ORDER
export const fetchTermsDetailsAction = () => async (dispatch) => {
  try {
    let data = await getMethod(getTermsDetailsAPIUrl);
    dispatch(fetchCartCheckoutTermsSuccessAction(data));
    return data;
  } catch (error) {
    console.log(error);
  }
};

// TO PLACE NEW ORDER/EDIT ORDER
export const addOrderDataAction = (payload, extraHeaders) => async (dispatch, getState) => {
  try {
    let resp = await postMethod(createOrderAPIUrl, payload, extraHeaders);
    console.log(resp);
    // .then((response) => response.json())
    // .then((data) => {
    //   let latestState = getState();
    //   latestState.cartItems.productsCount = 0;
    //   let orderDetails = {
    //     orderNumber: data.orderNumber,
    //     billTo: payload.billTo,
    //     shipTo: payload.shipTo,
    //     soldTo: payload.soldTo,
    //     orderDate: data.orderDate,
    //   };
    // dispatch(fetchCreateOrderSuccessAction(orderDetails));
    return resp;
    // })
    // .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};
