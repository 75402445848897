import PropTypes from "prop-types";
import React from "react";
import Slider from "react-slick";
import Video from "../Video.component";
import {
  ButtonsStyling,
  ContentHolder,
  ContentHolderInner,
  SlideHolder,
  Heading,
  Holder,
  Image,
  ImageHolder,
  SubHeading,
  Wrapper,
  useStyles 
} from "./HeroCarousel.styles";
import "./slick-theme.min.css";
import "./slick.min.css";
import ButtonOutlined from "../button/ButtonOutlined.component";
import { useNavigate } from "react-router-dom";
var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  adaptiveHeight: true,
  lazyLoad: false,
};


const HeroCarousel = ({ heroCarouselData, hideArrow, isAuthenticated }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return(
    <div className={classes.heroCarouselWrapper}>
      <Slider {...settings}>
        {heroCarouselData?.map((data, key) => (
         <div key={key} className={classes.slideWrapper}>
          <div className={classes.slideTextWrapper}>
            <h3 className={classes.headingText}>{data.title?.en}</h3>
            <p className={classes.subHeadingText}>{data.excerpt?.en}</p>
            {data.url && 
            // TODO : Use appropriate button here
             <ButtonsStyling href={data.url}>
               Learn More
             </ButtonsStyling>
              // <ButtonOutlined sx={{backgroundColor: 'transparent', borderColor: 'white',color: 'white', width: '130px'}} onClick={()=> navigate(data.url)}>
              //   Learn more
              // </ButtonOutlined>
            }
          </div>
          <div className={classes.slideImageWrapper}>
              <img className={classes.slideImage} src={data?.images[0]?.image || "imagesLoyalty/bannerImage.png"} alt={data?.images[0]?.id} loading="lazy"/>
          </div>
         </div>
        ))}
      </Slider>
    </div>
  )
};



HeroCarousel.defaultPropTypes = {
  hideArrow: false,
  isAuthenticated: false,
};

HeroCarousel.propTypes = {
  heroCarouselData: PropTypes.array.isRequired,
  hideArrow: PropTypes.bool,  isAuthenticated: PropTypes.bool,
};

export default React.memo(HeroCarousel);


// -------------------------
// originial hero carousel code
// -------------------------
// const HeroCarousel = ({ heroCarouselData, hideArrow, isAuthenticated }) => {
//   return (
//     // <Wrapper  isAuthenticated={isAuthenticated}>
//     <>
//       <Slider {...settings} arrows={!hideArrow}>
//         {heroCarouselData?.map((data, key) => (
//           <Holder key={key} color={data.color}>
//             {data?.contentType?.[0] === "mp4" ? (
//               <Video
//                 // className={classes.video}
//                 url={data.videoUrl}
//                 urlwebm={data.videoUrlContentwebm}
//                 mode="light"
//                 poster={data.videoPosterImage ? data.videoPosterImage : ""}
//                 autoPlay={true}
//                 // muted
//               />
//             ) : (
//               <>

//                 <ContentHolder>
//                     <Heading>{data.title?.en}</Heading>
//                     <SubHeading>{data.excerpt?.en}</SubHeading>
//                     {data.btnLabel && (
//                       <ButtonsStyling href={data.btnUrl} target="_blank">
//                         {data.btnLabel}
//                       </ButtonsStyling>
//                     )}
//                 </ContentHolder>

//                 <Image
//                   src={data?.images[0]?.image || "imagesLoyalty/bannerImage.png"}
//                   alt={data.title}
//                   loading="lazy"
//                 />

//               </>
//             )}
//           </Holder>
//         ))}
//       </Slider>
//       </>
//     // </Wrapper>
//   );
// };
