import { navItemsActionTypes } from "../actions/navItems.actions";

export default function navItemsReducer(state = null, action) {
  switch (action.type) {
    case navItemsActionTypes.FETCH_NAV_ITEMS_DATA_SUCCESS:
      // if (action.) {
        return { ...state, navData: action.data.navItems };
      // } else {
        // return { ...state, anonymousNav: action.data.navItems }; //TODO:  REMOVE THIS IF NOT REQURIED
      // }

    case navItemsActionTypes.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        isAuth: action.isAuth,
        token: action.token,
        userType: action.userType,
        changePasswordRequired: action.changePasswordRequired,
      };

    case navItemsActionTypes.CHANGE_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        isAuth: action.isAuth,
        token: action.token,
      };

    case navItemsActionTypes.CUSTOMER_ARCHIVE_SUCCESS:
      return {
        ...state,
        archiveData: action.data
      };

    case navItemsActionTypes.ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        accountDetails: action.data
      };

    default:
      return state;
  }
}
