import CachedIcon from "@mui/icons-material/Cached";
import HomeIcon from "@mui/icons-material/Home";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import {useContext} from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/common/button/Button.component";
import {LocalContext} from "../../../LocalProvider";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: "2rem 0rem",
    // top: "904px",
    // left: "83px",
    // width: "531px",
    // height: "135px",
    /* UI Properties */
    opacity: 1,
    // [theme.breakpoints.down(640)]: {
    //   display: "flex",
    //   flexDirection: "column",
    //   flexWrap: "nowrap",
    //   justifyContent: "flex-start",
    //   alignItems: "stretch",
    // }
  },
  title: {
    // top: "904px",
    // left: "83px",
    // width: "138px",
    // height: "34px",
    textAlign: "left",
    font: "normal normal bold 26px/39px Roboto",
    letterSpacing: "0px",
    color: "#1E1E1E",
    opacity: 1,
  },
  titleSummary: {
    // top: "939px",
    // left: "85px",
    // width: "416px",
    // height: "19px",
    textAlign: "left",
    font: "normal normal normal 14px/23px Roboto",
    letterSpacing: "0px",
    color: "#535353",
    opacity: 1,
  },
  buttonsContainer:{
    [theme.breakpoints.down(640)]: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      alignItems: "stretch",
    }
  },
  buttonOne: {
    padding: "23px",
    width: "285px",
    height: "56px",
    textAlign: "left",
    [theme.breakpoints.down(640)]: {
      width: "100%"
    }
  },
  buttonTwo: {
    padding: "23px",
    width: "285px",
    height: "56px",
    marginLeft: "13px",
    textAlign: "left",
    [theme.breakpoints.down(640)]: {
      marginLeft: 0,
      width: "100%"
    }
  },
  iconOne: {
    width: "26px",
    height: "18px",
    textAlign: "center",
  },
  textButton: {
    fontSize: "14px",
    fontWeight: "bold",
  },
}));

const GetStarted = () => {
  const { translate } = useContext(LocalContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const handleOrderProducts = () => {
    navigate("/products")
  }

  const handleRepeatOrder = () => {
    navigate("/order-history")
  }
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
         {/* Get Started! */}
          {translate('mx.whatsapp.get_started')}   
      </Typography>
      <Typography
        variant="subtitle1"
        component="div"
        className={classes.titleSummary}
      >
        {translate('mx.whatsapp.create_a_new_order_from_scratch_or_start_from_a_previous_one')}
      </Typography>
      <div className={classes.buttonsContainer}>
        <Button className={classes.buttonOne} onClick={handleOrderProducts}>
          <HomeIcon className={classes.iconOne} />
          <Typography className={classes.textButton}>{translate('mx.whatsapp.order_products')}</Typography>
        </Button>
        <Button className={classes.buttonTwo} onClick={handleRepeatOrder}>
          <CachedIcon className={classes.iconOne} />
          <Typography className={classes.textButton}>
            {translate('mx.whatsapp._repeat_a_previous_order')}
          </Typography>
        </Button>
      </div>
    </Box>
  );
};

export default GetStarted;
