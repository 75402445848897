import { deliveryEstimateActionTypes } from "../actions/deliveryEstimate.actions";

export default function deliveryEstimateReducer(state = null, action) {
    switch (action.type) {
        case deliveryEstimateActionTypes.ESTIMATED_DELIVERY_DATE_SUCCESS:
            return { ...state,
                estimatedDeliveryDate: action.data
            };
        case deliveryEstimateActionTypes.HOLIDAY_LIST_SUCCESS:
            return {
                ...state,
                holidayList: action.data
            };
        default:
            return state;
    }
}