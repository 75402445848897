import React from "react";
import { CircularProgress } from "@mui/material";
import { StyledOutlineButton } from "./Button.styles";

export default function ButtonOutlined({
  children,
  onClick,
  type = "button",
  disabled,
  isLoading = false,
  variant = "outlined",
  className = "",
  sx={},
}) {
  const handleClick = (e) => {
    typeof onClick === "function" && onClick(e);
  };
  return (
    <StyledOutlineButton
      onClick={handleClick}
      variant={variant}
      type={type}
      disabled={disabled}
      className={className}
      sx={sx}
    >
      {!isLoading && children}
      {isLoading && <CircularProgress color="primary" size={30} />}
    </StyledOutlineButton>
  );
}
