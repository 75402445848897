import PropTypes from "prop-types";
import React, { useContext } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { LocalContext } from "../../../LocalProvider.js";
import { colors, mediaQueries } from "../../../styles/variables";
import SingleColumn from "../../styled/SingleColumn.styled";
import "./slick-theme.min.css";
import "./slick.min.css";

const CardSliderHeading = styled.div`
  text-transform: uppercase;
  text-align: center;
  color: ${colors.color16};
  font-size: 2.6rem;
  font-weight: bold;
  margin-top: 1.5rem;
  @media ${mediaQueries.belowContainer} {
    font-size: 2.1rem;
  }
  @media ${mediaQueries.belowContainerStandard} {
    font-size: 2.1rem;
  }
`;

const CardSliderWrapper = styled.div`
  color: ${colors.color11};
  overflow: hidden;
  margin: 1.5rem 0;
  padding-bottom: 1.5rem;

  .slick-slide img {
    border-radius: 25px;
    margin: 0 auto;
  }
  & .slick-prev {
    left: 25px;
    z-index: 1;
  }

  & .slick-next {
    right: 25px;
  }
  & .slick-prev:before {
    content: "‹";
  }
  & .slick-next:before,
  [dir="rtl"] .slick-prev:before {
    content: "›";
  }
  [dir="rtl"] .slick-next:before {
    content: "‹";
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 60px;
    color: #484848;
  }
  .slick-dots {
    bottom: -25px;
    width: auto;
    left: 0;
    right: 0;
    margin: auto;
  }

  .slick-dots li button:before {
    content: "▃▃▃";
  }
`;

const CardSliderHolderParent = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 25px;
`;

const CardSliderHolder = styled.div`
  position: relative;
  border-radius: 25px;
  padding-bottom: 3rem;
  box-shadow: 0px 4px 8px #00000066;
  border: 0.1px solid #bdbdbd;
  //background-color: #bdbdbd;
`;

const CardSliderHolderImg = styled.img`
  max-width: 100%;
  min-height: 300px;
  @media ${mediaQueries.belowContainerStandard} {
    object-fit: cover;
    min-height: auto;
  }
  @media ${mediaQueries.belowiPad} {
  }
`;

const CardSlidertitleHolder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 80px;
  background: #f64e6e;
  background: ${(props) => (props.background ? props.background : "#fff")};
  border-radius: 0 0 25px 25px;
`;

const CardSlidertitle = styled.div`
  padding: 0.25rem 0.75rem;
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80px;
  @media ${mediaQueries.belowContainer} {
    font-size: 1.2rem;
  }
  @media ${mediaQueries.belowContainerStandard} {
    font-size: 1.1rem;
  }
`;

var settings = {
  dots: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: false,
  speed: 2000,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const CardSlider = ({ cardSliderData }) => {
  const { translate } = useContext(LocalContext);

  const sliderAPIData = cardSliderData;
  if (sliderAPIData) {
    return (
      <SingleColumn>
        <CardSliderHeading>{translate("BEST SELLER")}</CardSliderHeading>
        <CardSliderWrapper>
          <Slider {...settings}>
            {sliderAPIData.map((data, index) => (
              <CardSliderHolderParent key={index}>
                <CardSliderHolder>
                  <CardSliderHolderImg src={data.image} alt={data.alt} />
                  <CardSlidertitleHolder background={data.color}>
                    <CardSlidertitle>{data.title}</CardSlidertitle>
                  </CardSlidertitleHolder>
                </CardSliderHolder>
              </CardSliderHolderParent>
            ))}
          </Slider>
        </CardSliderWrapper>
      </SingleColumn>
    );
  } else {
    return null;
  }
};

CardSlider.propTypes = {
  cardSliderData: PropTypes.array.isRequired,
};

export default React.memo(CardSlider);
