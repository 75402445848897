import React, { useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getEnv } from "../../utils/urls";
import GetStarted from "../common/getstarted/GetStarted";
import { shuffleObjectArray } from "../common/helper/helper";
// import LoyaltyCard from "../loyaltyProgram/components/loyaltyCard/LoyaltyCard.component";
// import LoyaltyCardData from "../loyaltyProgram/JsonFile/ModifiedCard.json";
import ProductCardSlider from "../common/productCard/ProductCardSlider.component";
import CardSlider from "../common/slider/CardSlider.component";
import HeroCarousel from "../common/slider/HeroCarousel.component";
import ContentEarnPointsComponent from "../rewards/contentEarnPoints/ContentEarnPoints.component";
import PromotionalOffers from "../rewards/promotionalOffers/PromotionalOffers.component";
import BackgroundContainer from "../styled/BackGroundContainer.styled";
import MarginWrapper from "../styled/MarginWrapper.styled";
import SingleColumn from "../styled/SingleColumn.styled";
import { LocalContext } from "../../LocalProvider";

const HomeComponent = (props) => {
  const siteId = getEnv().siteId;
  const {translate} = useContext(LocalContext);

  const {
    homepageData,
    isUserAuth,
    loyaltyEnabled,
    homeTagsContent,
    earnPointsContent,
    redeemPointsItems,
    productData,
  } = props;

  //Randomizing the array in-place using Durstenfeld shuffle algorithm. This shuffledArray is used to randomize the products array to show random 4 products on Home Page.
  const shuffledArray = useMemo(() => shuffleObjectArray(productData), [productData.length]);
  
  const navigate = useNavigate();
  const handleViewAllRewards = () => {
    navigate("/redeem-points");
  };
  const handleViewAllProducts = () => {
    navigate("/products");
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        {homeTagsContent?.Carousel?.length > 0 && (
          <HeroCarousel
            isAuthenticated={isUserAuth}
            heroCarouselData={homeTagsContent.Carousel}
            hideArrow
          />
        )}
        {/* <SingleColumn noPaddingMobile>
          <MarginWrapper>
            <LoyaltyCard isAuthenticated cardDetails={LoyaltyCardData} />
          </MarginWrapper>
        </SingleColumn> */}
      </div>
      <BackgroundContainer>
        {!loyaltyEnabled && siteId !== "6" && (
          <CardSlider cardSliderData={homepageData.promotions} />
        )}

        {loyaltyEnabled && (
          <SingleColumn>
            <MarginWrapper>
              <div style={{marginBottom: '6rem'}}>
                {isUserAuth && <GetStarted />}
                {siteId !== "6" &&
                  <PromotionalOffers contentData={homeTagsContent?.Promotions} />
                }
                {isUserAuth && productData?.length > 0 && (
                  <ProductCardSlider
                    title={translate('mx.whatsapp.discover_products')}
                    isReward={false}
                    isHome={true}
                    data={productData.length > 4 ? shuffledArray.slice(0,4) : productData}
                    handleViewAll={handleViewAllProducts}
                  />
                )}
                {isUserAuth && redeemPointsItems?.length > 0 && siteId !== "6" && (
                  <ProductCardSlider
                    title="Get rewards"
                    isReward={true}
                    data={redeemPointsItems}
                    isHome={true}
                    handleViewAll={handleViewAllRewards}
                  />
                )}
                {siteId !== "6" &&
                  <ContentEarnPointsComponent data={earnPointsContent} />
                }
              </div>
            </MarginWrapper>
          </SingleColumn>
        )}
      </BackgroundContainer>
    </>
  );
};

export default HomeComponent;
