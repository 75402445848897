import styled from "styled-components";
import { newBreakPoints } from "../../styles/variables";
const MarginWrapper = styled.div`
@media (min-width: ${newBreakPoints.xs}px) {
  margin: ${(props) => props.noMarginMobile ? "0rem" : "0.5rem"};
}
  @media (min-width: ${newBreakPoints.md}px) {
    margin: 0 3rem;
  }
  @media (min-width: ${newBreakPoints.lg}px) {
    margin: 0 4rem;
  }
  @media (min-width: ${newBreakPoints.xl}px) {
    margin: 0 6rem;
  }
`;

export default MarginWrapper;
