import styled from "styled-components";
import { bgSetting, mediaQueries } from "../../../styles/variables";

const FooterWrapper = styled.footer`
//   background: url(/${bgSetting.footerImage}) no-repeat;
  background-color: ${props => props.isAuth? props.theme.palette.custom.footerBg: props.theme.palette.custom.footerUnAuthBg};
  background-size: 100% 100%;
  color: ${props => props.theme.palette.custom.footerHeading};
  // overflow: hidden;
`;

const FooterHelpLineRow = styled.div`
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  margin: 3rem 0 1rem 0;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  font-size: 1rem;
  @media ${mediaQueries.belowContainerStandard} {
    margin: 1rem 0;
  }
  @media ${mediaQueries.belowiPad} {
    padding: 0 1rem;
    margin: 1rem 0;
    display: block;
    text-align: center;
  }
  & > * {
    @media ${mediaQueries.belowiPad} {
      display: block;
      margin: 1rem 0;
    }
  }
`

const FooterRowCopyright = styled.div`
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  font-size: 0.8rem;
  color: ${props => props.isAuth ? 
            props.theme.palette.custom.footerCopyRight : 
            props.theme.palette.custom.footerUnAuthCopyRight
  };
  margin: ${(props) => (props.margin ? props.margin : 0)};
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  @media ${mediaQueries.belowContainerStandard} {
    font-size: 0.7rem;
    margin: 1rem 0;
  }
  @media ${mediaQueries.belowiPad} {
    padding: ${(props) => (props.paddingMobile ? props.paddingMobile : 0)};
    justify-content: center;
  }
`

export { FooterRowCopyright, FooterHelpLineRow, FooterWrapper };

