import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Stack, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import React, { useContext, useEffect, useState } from "react";
// import { selectedLanguage } from "../../../store/selectors/language.selector";
import { LocalContext } from "../../../LocalProvider";
import { getEnv } from "../../../utils/urls";
import CartQuantity from "../../cart/CartQuantity.component";
import Button from "../../common/button/Button.component";
import { numberWithCommas } from "../helper/helper";
import { useStyles } from "./ProductCardGrid.styles";
const ProductCard = ({
  item,
  isReward,
  updateProductCartState,
  handleAddToCartProduct,
  productCartSnapshot,
  isHome,
  siteId,
  handleWishList,
  storeId,
  sm,
  md,
  xs,
  hideWishlistIcon,
}) => {
  const classes = useStyles();
  // const language = useSelector(selectedLanguage);
  const [perUnit, setPerUnit] = useState();
  const [unitConv, setUnitConv] = useState(1);
  const { selectedLocaleLang, translate } = useContext(LocalContext);

  const handleCart = (product) => {
    console.log("cart added");
    handleAddToCartGridProduct(product);
  };

  const handelUpdateCart = (product, e, unitType) => {
    if (!isNaN(e)) {
      updateProductCartState(product, parseInt(e), unitType);
    }
  };

  useEffect(() => {
    if (item.price && item.price.unitConversion !== null) {
      setPerUnit(
        parseFloat(
          parseFloat(item.price.listPrice) / item.price?.unitConversion
        ).toFixed(2)
      );
      setUnitConv(item.price?.unitConversion);
    } else {
      setPerUnit(parseFloat(item?.price?.listPrice / unitConv).toFixed(2));
    }
  }, []);
  //DESC: Product Card has 2 parts top part includes Image and barcode and the bottom part includes pricing info and add to cart button. 
  return (
    <Stack position="relative" sx={{display: "flex", justifyContent: "space-between", height: '100%'}}>
      {/* {siteId === "6" && productCartSnapshot?.[item.id]?.count > 0 ? 
          <div className={classes.indicationBar}>
            <div className={classes.indicationBarPoints}>
              {productCartSnapshot?.[item.id]?.count}
            </div>
          </div>
        : <div className={classes.EmptyindicationBar}></div> } */}
      {!isReward && !hideWishlistIcon && (
        <ToggleButton
          value="check"
          className={classes.toggleButtonStyle}
          selected={Boolean(parseInt(item.wishlist))}
          onClick={() => {
            handleWishList(item, storeId);
          }}
        >
          <FavoriteIcon />
        </ToggleButton>
      )}
      {/* This is the Top part of the product Card */}
      <div>
        <div className={classes.avtarStyle}>
          {/* <SideOfferComponent text="Offer" /> */}
          {!isReward ? (
            <img
              alt={item.displayName?.[selectedLocaleLang]}
              src={item?.details?.imageUrl}
              variant="square"
              className={classes.img}
            />
          ) : (
            <img
              alt={item.title?.[selectedLocaleLang]}
              src={item.thumbnailImage}
              variant="square"
              className={classes.img}
            />
          )}
        </div>
        <Box paddingX={{ lg: 3, md: 3, sm: 1, xs: 1 }}>
          <Typography
            className={classes.productText}
            variant="body1"
            color="text.secondary"
          >
            {!isReward
              ? item.displayName?.[selectedLocaleLang]
              : item.title?.[selectedLocaleLang]}
          </Typography>
          {!isReward && item.barCode ? (
            <Typography
              className={classes.barcode}
              variant="body1"
              color="text.secondary"
            >
              {item.barCode}
            </Typography>
          ) : (
            <Typography
              className={classes.barcode}
              variant="body1"
              color="text.secondary"
            ></Typography>
          )}
        </Box>
      </div>
      {/* This is the bottom part of the product Card */}
      <Stack position="relative" paddingX={{ lg: 3, md: 3, sm: 1, xs: 1 }}>
        <Box>
          {!isReward && (
            <Typography
              className={classes.text2}
              variant="body1"
              color="text.secondary"
            >
              {item.unitType}
              {item.conversionMultiplier > 1 &&
                item.conversionMultiplier + " units"}
            </Typography>
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            overflow="hidden"
          >
            <Box>
              {!isReward && (
                <Typography
                  className={classes.text}
                  variant="body1"
                  color="text.secondary"
                >
                  {item?.price?.currency || getEnv().currency}
                  {numberWithCommas(
                    parseFloat(item.discountedPriceTotal).toFixed(2)
                  )}
                </Typography>
              )}
              {isReward && (
                <Typography
                  className={classes.text}
                  variant="body1"
                  color="text.secondary"
                >
                  {item.points}
                </Typography>
              )}
            </Box>
            {!isReward && (
              <Typography
                className={classes.unitPrice}
                variant="body1"
                color="text.secondary"
              >
                {item?.conversionMultiplier > 1 &&
                  `${item.price.currency || getEnv().currency} 
                ${numberWithCommas(
                  parseFloat(item.discountedPricePerUnit)?.toFixed(2)
                )}` + "/unit"}
              </Typography>
            )}
          </Stack>
          {!isReward && item.minimumOrderQuantity > "1" ? (
            <Typography
              className={classes.text1}
              variant="body1"
              color="text.secondary"
            >
              Minimum of {item.minimumOrderQuantity}
            </Typography>
          ) : (
            <Typography
              className={classes.text1}
              variant="body1"
              color="text.secondary"
            ></Typography>
          )}
          {!isReward && item.allowedInMultiplesOf > "1" ? (
            <Typography
              className={classes.text1}
              variant="body1"
              color="text.secondary"
            >
              Sold in multiples of {item.allowedInMultiplesOf}
            </Typography>
          ) : (
            <Typography
              className={classes.text1}
              variant="body1"
              color="text.secondary"
            ></Typography>
          )}
          <Box
            marginBottom={{ md: 2, xs: 2, sm: 2 }}
            justifyContent="center"
            display="flex"
          >
            {!isHome &&
              siteId === "6" &&
              (!productCartSnapshot?.[item.id]?.quantity > 0 ||
                productCartSnapshot?.[item.id]?.quantity == 0) && (
                //   siteId === "6" && (!props.productCartSnapshot?.[product.id]?.quantity > 0 || props.productCartSnapshot?.[product.id]?.quantity == 0) &&
                <Button
                  styleChange={{
                    width: "100%",
                    height: 30,
                    textTransform: "none",
                    padding: "1px",
                    marginTop: "18px",
                    background: "#0047BA",
                    color: "#FFF",
                    lineHeight: "1rem",
                  }}
                  onClick={(quantity) =>
                    handleAddToCartProduct(
                      item,
                      item.minimumOrderQuantity || 1,
                      item?.unitType
                    )
                  }
                >
                  {translate("mx.whatsapp.add_to_cart")}
                </Button>
              )}
            {!isHome &&
              siteId === "6" &&
              productCartSnapshot?.[item.id]?.quantity >=
                item.minimumOrderQuantity && (
                <CartQuantity
                  alignStart
                  minimumOrderQuantity={item.minimumOrderQuantity}
                  multiplier={item.allowedInMultiplesOf}
                  isProduct
                  quantity={productCartSnapshot?.[item.id]?.quantity}
                  onQuantityUpdate={(quantity) =>
                    handleAddToCartProduct(item, quantity, item?.unitType)
                  }
                />
              )}
            {!isHome && siteId === "7" && (
              <CartQuantity
                quantity={productCartSnapshot?.[item.id]?.quantity || 0}
                isProduct={true}
                onQuantityUpdate={(e) =>
                  handelUpdateCart(
                    item,
                    e,
                    productCartSnapshot?.[item.id]?.unitType
                      ? productCartSnapshot?.[item.id]?.unitType
                      : item.price?.baseUnit === "Case"
                      ? "CS"
                      : "EA"
                  )
                }
              />
            )}

            {/* <StyledButton variant="contained" onClick={() => handleCart(item)}>
              Add to Cart
            </StyledButton> */}
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ProductCard;
