import { Box } from "@mui/material";
import React from "react";

export default function BoxCard({
  noPadding,
  children,
  paddingLeft,
  paddingRight,
  paddingY,
  marginBottom,
  marginTop,
  marginX,
  border,
  position,
  noBorder,
  productCartheight,
  styleChange,
  marginRight,
  className
}) {
  return (
    <Box
      paddingLeft={noPadding ? 0 : paddingLeft ? paddingLeft : 2}
      paddingRight={noPadding ? 0 : paddingRight ? paddingRight : 5}
      paddingY={noPadding ? 0 : paddingY ? paddingY : 3}
      marginTop={marginTop || 5}
      marginBottom={marginBottom || 1}
      marginRight={marginRight}
      marginX={marginX || 0}
      border={noBorder ? "none" : border ? border : "1px solid #E1E1E1"}
      borderRadius={"10px"}
      position={position}
      height={productCartheight && "90%"}
      sx={styleChange}
      className={className}
    >
      {children}
    </Box>
  );
}
