import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCartEngagement,
  setCartIsFetchedAction,
  setCheckoutTypeAction
} from "../store/actions/cartEngagement.actions";
import {
  cartIsFetched,
  checkoutTypeSelector
} from "../store/selectors/cartEngagement.selector";
import {
  getAuthToken
} from "../store/selectors/auth.selector";
import { ORDER_TYPES } from "../utils/common";
import { getEnv } from "../utils/urls";

export const useGetCartApiHook = () => {
  const dispatch = useDispatch();
  const siteId = getEnv().siteId;
  const token = useSelector(getAuthToken);
  const isFetched = useSelector(cartIsFetched);
  const checkoutType = useSelector(checkoutTypeSelector);

  // Dispatch Get Products Cart
  const getProductsCart = () => dispatch(getCartEngagement(ORDER_TYPES.ZOR));

  // Dispatch Get Rewards Cart
  const getRewardsCart = () => dispatch(getCartEngagement(ORDER_TYPES.REWARDS));

  // Call both get cart api
  const getCartData = () => Promise.all([getRewardsCart(), getProductsCart()]);

  // Change checkout type REWARDS/ZOR
  const onChangeCheckoutType = (type) => dispatch(setCheckoutTypeAction(type));

  // Check if cart api is already fetched
  const setCartIsFetched = (value) => dispatch(setCartIsFetchedAction(value));

  useEffect(() => {
    (async function () {
      if (token && !isFetched && siteId !== "6") {
        const [rewardsCart, productsCart] = await getCartData();
        setCartIsFetched(true);
        if (!checkoutType) {
          if (rewardsCart?.items?.length && !productsCart?.items?.length) {
            return onChangeCheckoutType(ORDER_TYPES.REWARDS);
          }
          onChangeCheckoutType(ORDER_TYPES.ZOR);
        }
      }
      if (siteId === "6") {
        onChangeCheckoutType(ORDER_TYPES.ZOR);
      }
    })();
  }, [token, isFetched]);
};
