import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  promoContainer: {
    borderRadius: "10px",
    padding: "0 3rem",
    paddingBottom: "2rem",
    position: "relative",
    minHeight: "320px",
    maxWidth: "99%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  imageGrid: {
    width: "320px",
    height: "320px",
    [theme.breakpoints.down("sm")]: {
      height: "240px"
    },
  },
  imageBase: {
    position: "absolute",
    top: "-2.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
  },
  img: {
    [theme.breakpoints.down("lg")]: {
      height: "280px",
      width: "280px"
    },
    [theme.breakpoints.down("sm")]: {
      height: "240px",
      width: "240px"
    },
  },
  promoText: {
    padding: "0.3rem 1.5rem",
    backgroundColor: "#F6EB39",
    display: "inline-block",
    borderRadius: "0 0 20px 20px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1.5rem",
      borderRadius: "0 20px 20px 0",
    },
  },
  slider: {
    "& .slick-dots": {
      bottom: "auto",
      "& li": {
        "& button": {
          "&::before": { fontSize: "1rem" },
        },
      },
      "& li.slick-active": {
        "& button": {
          "&::before": { color: "#000" },
        },
      },
    },
  },
}));
