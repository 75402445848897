import { editOrderCartActionTypes } from "../actions/editOrderCart.action";

export default function editOrderReducer(state = null, action) {
  switch (action.type) {
    case editOrderCartActionTypes.SET_EDIT_ORDER_DATA:
      return {...state,
        ...action.data};
    case editOrderCartActionTypes.UPDATE_EDIT_ORDER_DATA:
      return {
        ...state,
        items: action.data,
      };
    default:
      return state;
  }
}
