import { myAccountActionTypes } from "../actions/myAccount.actions";

export default function myAccountReducer(state = null, action) {
  switch (action.type) {
    case myAccountActionTypes.FETCH_MY_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        myAccountDetails: action.data,
      };
      case myAccountActionTypes.UPDATE_MY_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        myAccountDetails: action.data,
      };

    default:
      return state;
  }
}
