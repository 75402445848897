import { ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { fontSizes } from "../../../styles/variables";
import { useStyles } from "./PromotionalOffers.styles";
const PromotionalOffersCard = ({ promo, display, language = "en" }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid
      container
      className={classes.promoContainer}
      sx={{ backgroundColor: theme.palette.custom.promotionsBg }}
      // spacing={2}
    >
      <Grid
        item
        xs={12}
        sm={5}
        md={5}
        lg={4}
        xl={3}
        className={classes.imageGrid}
      >
        <ButtonBase className={classes.imageBase}>
          <img
            src={promo.images?.[0]?.image}
            height="320"
            width="320"
            className={classes.img}
          />
        </ButtonBase>
      </Grid>
      <Grid item xs={12} sm={7} md={7} lg={8} xl={8}>
        {promo.excerpt[language] && (
          <Typography variant="subtitle2" className={classes.promoText}>
            {promo.excerpt[language]}
          </Typography>
        )}
        {promo.content[language] && (
          <Typography
            component="div"
            marginY={2}
            sx={{ marginX: { xs: 2, md: 0 } }}
            dangerouslySetInnerHTML={{ __html: promo.content[language] }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PromotionalOffersCard;
