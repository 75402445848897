export const allCartEngagementSelector = (state) =>
  state.cartEngagement ? state.cartEngagement : { rewards: null, zor: null };

export const rewardsCartEngagementSelector = (state) =>
  state.cartEngagement?.REWARDS ? state.cartEngagement?.REWARDS : null;

export const productsCartEngagementSelector = (state) =>
  state.cartEngagement?.ZOR ? state.cartEngagement?.ZOR : null;

export const checkoutTypeSelector = (state) =>
  state.cartEngagement?.checkoutType
    ? state.cartEngagement?.checkoutType
    : null;

export const rewardsProductTotalCountSelector = (state) =>
  state.cartEngagement?.REWARDS?.totalCount
    ? state.cartEngagement?.REWARDS?.totalCount
    : 0;
export const productsTotalCountSelector = (state) =>
  state.cartEngagement?.ZOR?.totalCount
    ? state.cartEngagement?.ZOR?.totalCount
    : 0;
export const cartIsFetched = (state) => state?.cartEngagement?.isFetched;
