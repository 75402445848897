import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { fetchMyAccountDetailsAction } from "../../store/actions/myAccount.actions";
import { fetchGetNavigation } from "../../store/actions/navItems.actions";
import { getAuthToken, isAuthenticated } from "../../store/selectors/auth.selector";
import { getAccountData } from "../../store/selectors/myAccount.selector";
import { getFooterNavData, getHeaderNavData } from "../../store/selectors/navItems.selector";
import FontStyles from "../../styles/font-styles";
import GlobalStyles from "../../styles/global-styles";
import Appbar from "../appbar/Appbar.component";
import FooterNew from "../common/footer/FooterNew.component";
import Modals from "../modal/Modals.component";
import Container from "../styled/Container.styled";

const StretchedMain = styled.main`
  flex: 1;
  background:${props => props.theme.palette.custom.defaultPageBg};
`;

const DefaultLayout = ({ children }) => {

  const dispatch = useDispatch();
  const headerData = useSelector(getHeaderNavData);
  const footerData = useSelector(getFooterNavData);
  const token = useSelector(getAuthToken);  // auth token from redux store - references token in okta auth provider
  const isAuth = useSelector(isAuthenticated); // is user authenticated bool from redux store - references isAuthenticated in okta auth provider
  const userData = useSelector(getAccountData);



  // get navigation data from api
  useEffect(() => {
    (function () {
      if (!headerData) {
        dispatch(fetchGetNavigation());
      }
    })();
  }, []);


  //***  My account api will be called if the token is present. so that it will handle the issue when the token is still present even after it got expired.
  useEffect(() => {
    if (token && !userData) {
      dispatch(fetchMyAccountDetailsAction());
    }
  }, [userData, token]);

  return (
    <Container>
      <FontStyles />
      <GlobalStyles />
      <Appbar isAuth={isAuth} headerData={headerData} />
      <StretchedMain>
        <Outlet />
      </StretchedMain>
      <FooterNew isAuth={isAuth} footerData={footerData} />
      {/* Importing All Modals */}
      <Modals />
    </Container>
  );
};

DefaultLayout.propTypes = {};

export default DefaultLayout;
