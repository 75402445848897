import Close from "@mui/icons-material/Close";
import {
  // Paper,
  Dialog,
  DialogContent,
  DialogTitle, Grid
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { toggleModalAction } from "../../store/actions/modals.actions";
import { useDefaultModalStyles } from "./Modal.styles";

const DefaultModal = (props) => {
  const { open, children, header, id } = props;
  const classes = useDefaultModalStyles();
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(toggleModalAction(id, false));
  };
  return (
    <Dialog
      open={open}
      scroll="body"
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: { borderRadius: "24px" } }}
    >
      {/* <Paper className={classes.paper}> */}
      {header && (
        <DialogTitle id="scroll-dialog-title">
          <Grid
            container
            direction="row"
            style={{ justifyContent: "space-between" }}
          >
            <Grid item md={10} xs={10} className={classes.title}>
              {header}
            </Grid>
            <Grid item className={classes.closeIcon}>
              <Close onClick={onClose} />
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {/* </Paper> */}
    </Dialog>
  );
};

export default DefaultModal;
