import { modalsActionTypes } from "../actions/modals.actions";

export default function modalsReducer(state = null, action) {
  switch (action.type) {
    case modalsActionTypes.TOGGLE_MODAL:
      return {
        ...state,
        [action.key]: action.data,
      };
    // case modalsActionTypes.MODAL:

    //   return {
    //     ...state,
    //     modal: action.data
    //   }
    default:
      return state;
  }
}
