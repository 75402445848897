import { adaptV4Theme, createTheme } from "@mui/material/styles";
import { colors } from "./styles/variables";
import { getEnv } from "./utils/urls";
const defaultTheme = createTheme(adaptV4Theme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: { 500: colors.primary },
    common: {
      black: "#000",
      white: "#FFF"
    },
    success: {
      main: "#91C24C"
    },
    custom: {
      primary: "#00537D",
      disable: "#dddddd",
      warning: '#DB1E36',
      black: "#000",
      white: "#ffffff",
      transparent: "rgba(0, 0, 0, .3)",
      //Page background for default layout
      defaultPageBg: "",
      //Header
      headerBg: "#FFFFFF",
      headerMenuItem: "#000",
      headerMenuItemBg: "#0047BA",
      headerNavBarBg: "#0047BA",
      headerMobileMenuItem: "#FFFFFF",
      //Footer
      footerBg: "#003182",
      footerHeading: "#FFFFFF",
      footerSubHeading: "#FFFFFFB3",
      footerCopyRight: "#FFFFFFB3",
      //Footer Unauth
      footerUnAuthBg: '#E9E9E9',
      footerUnAuthCopyRight: '#616161',
      //Banner comp
      bannerBg: "#DB1E36",
      bannerItem: "#FFFFFF",
      //Promotions(Home PAge)
      promotionsBg: "#EDEDED",
      //Product Card & Rewards Common Card
      productCardBg: "#FFFFFF",
      productCardText: "#171717",
      productCardSubText: "#818181",
      bottomInputText: "#5C5C5C",
      // Common Button Styles
      //Button 1 (Discover Products)
      buttonBg1: "#0047BA",
      buttonText1: "#FFFFFF",
      //Button 2 (Content points)
      buttonBg2: "#FFFFFF",
      buttonText2: "#0047BA",
      // Button 3 (sign In button colors both normal and on hover)
      buttonBg3: '#0047BA',
      buttonHoverBg3: '#1555BC',
      //SignIn colors in case of error
      errorLabel: "#DB1E36",
      // Order Confirmation colors
      orderDetailTitle: "#616161CC",
      orderDetailDesc: "#1E1E1E",
      //Order History Detail Page Colors
      orderDetailCardColor1: "#5C5C5C",
      orderDetailCardColor2: "#1E1E1E",
      orderSummaryCardDetailColor1: "#616161",
      orderSummaryCardTotalColor: '#1E1E1E',
      //Edit Order Page
      addProductsButtonColor: "#0047BA",
      color1: "#009ADC",
      color2: "#0076B3",
      color3: "#063F5E",
      color4: "#E5ECF8",
      color5: "#008000",
      color6: "#0DAF00",
      color7: "#299D49",
      color8: "#DDFFE6",
      color9: "#ffcc00",
      color10: "#e2b502",
      color11: "#c7c7c7",
      color12: "#f2f4f8",
      color13: "#c4c4c4",
      color14: "#464646",
      color15: "#4b4b4b",
      color16: "#535353",
      color17: "#dddddd",
      color18: "#eaebee",
      color19: "#D92736",
      color20: "#B9222E",
      color21: "#CE443B",
      color22: "#FFCAC6",
      color23: "#002791",
      color24: "#193C9C",
      color25: "#0047BA",
      color26: "#003182"
    },
  },
  customFont: {
    font10: "0.625rem", //10px
    font11: "0.6875rem", //11px
    font12: "0.75rem", //12px
    font14: "0.875rem", //14px
    font16: "1rem", //16px
    font17: "1.0625rem", //17px
    font18: "1.125rem", //18px
    font20: "1.25rem", //20px
    font22: "1.375rem", //22px,
    font23: "1.4375rem", //23px
    font24: "1.5rem", //24px
    font26: "1.625rem", //26px
    font28: "1.75rem", //28px
    font30: "1.875rem", //30px
    font36: "2.25rem", //36px
    font42: "2.625rem", //42px
    font48: "3rem", //48px
    font60: "3.75rem", //60px
    font64: "4rem", //60px
    font80: "5rem", //80px
    font90: "5.625rem", //90px
  },
  customShadow: {
    none: "none",
    shad1: "0px 4px 10px #00000029",
    shad2: "0px 3px 6px #00000029",
    shad3: "10px 1px 13px #00000029",
    shad4: "6px 7px 13px #00000055",
    shad5: "0 0 7px #00000066",
    shad6: "0px 2px 7px #00000066",
    shad7: "0px 1px 14px #D1DFF272"
  },
  typography: {
    // fontFamily: 'Roboto'
  }
}));
const modifiedTheme = createTheme(adaptV4Theme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: { 500: colors.primary },
    common: {
      black: "#000",
      white: "#FFF"
    },
    success: {
      main: "#91C24C"
    },
    custom: {
      primary: "#00537D",
      disable: "#dddddd",
      black: "#000",
      white: "#ffffff",
      transparent: "rgba(0, 0, 0, .3)",
      //Page background for default layout
      defaultPageBg: "linear-gradient(to bottom, #fbb517 0%, #ffe600 100%)",
      //Header
      headerBg: "#ffe600",
      headerMenuItem: "brown",
      headerMenuItemBg: "#da291c",
      headerNavBarBg: "#DB1E36",
      headerMobileMenuItem: "#FFFFFF",
      //Footer
      footerBg: "#da291c",
      footerHeading: "#FFFFFF",
      footerSubHeading: "#FFFFFFB3",
      footerCopyRight: "#FFFFFFB3",
      //Footer Unauth
      footerUnAuthBg: '#E9E9E9',
      footerUnAuthCopyRight: '#616161',
      bannerBg: "orange",
      bannerItem: "#FFFFFF",
      //Promotions(Home PAge)
      promotionsBg: "aliceblue",
      //Product Card & Rewards Common Card
      productCardBg: "#da291c",
      productCardText: "#fff",
      productCardSubText: "#fff",
      // Common Button Styles
      //Button 1 (Discover Products)
      buttonBg1: "#0047BA",
      buttonText1: "#FFFFFF",
      //Button 2 (Content points)
      buttonBg2: "#FFFFFF",
      buttonText2: "#0047BA",
      // Order Confirmation colors
      orderDetailTitle: "#616161CC",
      orderDetailDesc: "#1E1E1E",
      //Order History Detail Page Colors
      orderDetailCardColor1: "#5C5C5C",
      orderDetailCardColor2: "#1E1E1E",
      orderSummaryCardDetailColor1: "#616161",
      orderSummaryCardTotalColor: '#1E1E1E',
      //Edit Order Page
      addProductsButtonColor: "#0047BA",


      // banner
      color1: "#009ADC",
      color2: "#0076B3",
      color3: "#063F5E",
      color4: "#E5ECF8",
      color5: "#008000",
      color6: "#0DAF00",
      color7: "#299D49",
      color8: "#DDFFE6",
      color9: "#ffcc00",
      color10: "#e2b502",
      color11: "#c7c7c7",
      color12: "#f2f4f8",
      color13: "#c4c4c4",
      color14: "#464646",
      color15: "#4b4b4b",
      color16: "#535353",
      color17: "#dddddd",
      color18: "#eaebee",
      color19: "#D92736",
      color20: "#B9222E",
      color21: "#CE443B",
      color22: "#FFCAC6",
      color23: "#002791",
      color24: "#193C9C",
      color25: "#0047BA",
      color26: "#003182"
    },
  },
  customFont: {
    font12: "0.75rem", //12px
    font14: "0.875rem", //14px
    font16: "1rem", //16px
    font17: "1.0625rem", //17px
    font18: "1.125rem", //18px
    font20: "1.25rem", //20px
    font22: "1.375rem", //22px,
    font23: "1.4375rem", //23px
    font24: "1.5rem", //24px
    font26: "1.625rem", //26px
    font28: "1.75rem", //28px
    font30: "1.875rem", //30px
    font36: "2.25rem", //36px
    font42: "2.625rem", //42px
    font48: "3rem", //48px
    font60: "3.75rem", //60px
    font64: "4rem", //60px
    font80: "5rem", //80px
    font90: "5.625rem", //90px
  },
  customShadow: {
    none: "none",
    shad1: "0px 4px 10px #00000029",
    shad2: "0px 3px 6px #00000029",
    shad3: "10px 1px 13px #00000029",
    shad4: "6px 7px 13px #00000055",
    shad5: "0 0 7px #00000066",
    shad6: "0px 2px 7px #00000066",
    shad7: "0px 1px 14px #D1DFF272"
  },
}));
const theme = getEnv().theme === "modifiedTheme" ? modifiedTheme : defaultTheme;
// const theme = getEnv().theme === "modifiedTheme" ? defaultTheme : modifiedTheme;
export default theme;
