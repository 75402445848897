import React from "react";
import WelcomeContent from '../components/welcome/WelcomeContent';

const WelcomePage = () =>{
    return(
        <div>
            <WelcomeContent/>
        </div>
    );
};

export default WelcomePage;
