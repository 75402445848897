import { makeStyles } from "@mui/styles";
import { fontSizes } from "../../styles/variables";
export const useStyles = makeStyles((theme) => ({
  box: {
    width: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 48px)",
    },
  },
  heading: {
    fontSize: `${theme.customFont.font36}`,
    fontWeight: '900',
    [theme.breakpoints.down("sm")]: {
      fontSize: `${theme.customFont.font28}`,
    },
  },
  subHeading: {
    fontSize: `${theme.customFont.font17}`,
    fontWeight: 'normal'
  }
}));
