import { getMethod, postMethod } from "../../utils/api";
import {
  createAddActivityData,
  formatContentData,
  formatHomeContentWithTags,
} from "../../utils/common";
import { getContentAPIUrl, activityUrl, getEnv } from "../../utils/urls";
import { getAccountDetailsAPI } from "./auth.actions";
export const contentDataActionTypes = {
  FETCH_CONTENT_API_SUCCESS: "FETCH_CONTENT_API_SUCCESS",
  FETCH_CONTENT_API_FAILURE: "FETCH_CONTENT_API_FAILURE",
  UPDATE_CONTENT_KEY_DATA: "UPDATE_CONTENT_KEY_DATA",
  ADD_ACTIVITY_SUCCESS: "ADD_ACTIVITY_SUCCESS",
};

const fetchContentDataSuccessAction = (data) => {
  return {
    type: contentDataActionTypes.FETCH_CONTENT_API_SUCCESS,
    data,
  };
};

export const contentDataKeyUpdateAction = (key, data) => {
  return {
    type: contentDataActionTypes.UPDATE_CONTENT_KEY_DATA,
    key,
    data,
  };
};

export const getAllContent = () => async (dispatch) => {
  try {
    let data = await getMethod(getContentAPIUrl);
    let payload = { items: [], contentType: [] };
    if (data?.data) {
      let items = data?.data || [];
      let formatData = formatContentData(items);
      payload = { items, ...formatData };
    }
    dispatch(fetchContentDataSuccessAction(payload));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getHomeContent = (storeName, token) => async (dispatch) => {
  const protectedTags = getEnv().protectedContentTags;
  let protectedTagsArray = protectedTags?.replaceAll("[","").replaceAll("]","").split(",");
  try {
    const tags = token ? protectedTagsArray : ["Welcome"];
    let queryParams = "";
    tags && tags.forEach((tag, i) => {
      if (i === 0) {
        queryParams += `tags[]=${tag}`;
      } else {
        queryParams += `&tags[]=${tag}`;
      }
    });
    if(token && storeName){
      queryParams += `&storeId=${storeName}`;
    }

    let contentUrl = `${getContentAPIUrl}?${queryParams}`;

    let data = await getMethod(contentUrl);
    if (data?.data) {
      let filterData = formatHomeContentWithTags(data.data);
      dispatch(fetchContentDataSuccessAction({ ...filterData }));
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addActivity = (article) => async (dispatch) => {
  try {
    let body = createAddActivityData(article);
    let data = await postMethod(activityUrl, body);
    if (data.success) {
      dispatch(getAccountDetailsAPI());
    } else {
      console.log("Activity points error");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getActivity = () => async (dispatch) => {
  try {
    let data = await getMethod(activityUrl);
    console.log(data);
  } catch (error) {
    console.log(error);
  }
};
