import {getMethod} from "../../utils/api";
import {getContentAPIUrl} from "../../utils/urls";

// action types for welcome page
export const welcomePageActionTypes = {
    SET_WELCOME_PAGE_CONTENT: "SET_WELCOME_PAGE_CONTENT",
    FETCH_WELCOME_PAGE_CONTENT_START: "FETCH_WELCOME_PAGE_CONTENT_START",
};


// set the welcome content api response in store
export const setWelcomePageContent = (data) => {
    return {
        type: welcomePageActionTypes.SET_WELCOME_PAGE_CONTENT,
        data
    }
}


// log the start of fetching welcome content to view in redux devtools
export const fetchWelcomePageContentStart = (data = null) => {
    return {
        type: welcomePageActionTypes.FETCH_WELCOME_PAGE_CONTENT_START,
        data
    }
}


// fetch content for welcome page
export const fetchWelcomePageContent = () => async (dispatch, getState) => {
    const latestState = getState();

    dispatch(fetchWelcomePageContentStart(null));
    return await getMethod((getContentAPIUrl + '?tags[]=Welcome'))
        .then((data) => {
            console.log('getWelcomePageContent');
            console.log(data.data);
            dispatch(setWelcomePageContent(data.data))
        })
        .catch((err) => console.log(err))
}