import moment from "moment";

// TO GET FORMATED PRICE TO 2 DECIMALS
export const getFormatedPrice = (num) => {
  return Math.round((parseInt(num) + Number.EPSILON) * 100) / 100;
};

// TO GET FORMATED DATE
export const getFormatedDate = (date, formatType) => {
  return moment(date).format(formatType);
};

// TO GET CURRENT DATE
export const getCurrentDate = () => {
  return moment().format("YYYY-MM-DD");
};

// TO GET SELECTED ASSOCIATED CUSTOMER
export const getSelectedAssociatedCustomer = () => {
  return localStorage.getItem("selectedCustomerID");
};

// TO GET SELECTED ASSOCIATED CUSTOMER Type
export const getSelectedAssociatedCustomerType = () => {
  return localStorage.getItem("selectedCustomerType");
};

// TO GET SELECTED ASSOCIATED CUSTOMER Sap ID
export const getSelectedAssociatedCustomerSapId = () => {
  return localStorage.getItem("selectedCustomerSapId");
};

// TO GET SELECTED ASSOCIATED CUSTOMER Credit Amount
export const getSelectedAssociatedCustomerCreditAmt = () => {
  return localStorage.getItem("selectedCustomerCreditAmt");
};
// TO GET THE LAST
export const showLastNDigits = (n, phoneNumber, char) => {
  if (!phoneNumber) {
    return null;
  }
  if (char === "*") {
    return "******" + phoneNumber.slice(n);
  }
  return "XXXXXX" + phoneNumber.slice(n);
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//This function only works for object arrays.
export const shuffleObjectArray = (array) => {
  let shuffleArray = [...array];
  for (var i = shuffleArray.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = Object.assign({}, shuffleArray[i]);
    shuffleArray[i] = Object.assign({}, shuffleArray[j]);
    shuffleArray[j] = Object.assign({}, temp);
  }
  return shuffleArray;
};
//This function concatenates the address object
export const addressConcatenator = (address) => {
  return (
    (address?.addressLine1 ? address.addressLine1 + "," : "") +
    " " +
    (address?.addressLine2 ? address?.addressLine2 + "," : "") +
    " " +
    (address?.city ? address.city + "," : "") +
    " " +
    (address?.state ? address?.state + "," : "") +
    " " +
    (address?.country ? address?.country : "") +
    " " +
    (address?.countryCode ? address?.countryCode : "")
  );
};

//List of all unit types 
export const availableUnitTypes = [{ id: 1, code: "EA", name: "Each" },
{ id: 2, code: "CS", name: "Cases" },
{ id: 3, code: "DSY", name: "Display" }];

//Function which accepts saleUoms array and returns matching unit type in comparison with the available unit types.
export const fetchUnitTypeName = (saleUom) => {
  let filteredUnitObj = availableUnitTypes.find(item => item.code === saleUom[0]?.code);
  return filteredUnitObj?.name;
}
