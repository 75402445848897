import React from "react";
import { CartIcon, DivCart, SpanCart } from "./Appbar.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
export default function CartIconButton({ cartTotalCount }) {
  return (
    <CartIcon to="/cart-engagement">
      <FontAwesomeIcon icon={faShoppingCart} size="lg" color="#1e1e1e"/>
      <DivCart>
        <SpanCart>{cartTotalCount}</SpanCart>
      </DivCart>
    </CartIcon>
  );
}
