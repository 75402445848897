// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// TEMPORARY COMMENTS KEY
// Addding this so we know what routes are in what backend & if they're in swagger or not
// ✓ swagger -- means it is documented in swagger
// x swagger -- means it is not documented in swagger
// ✓ cep-b2b-global -- means it is a route in the repo cep-b2b-global
// ✓ cep-b2b-auth -- means it is a route in the repo cep-b2b-auth
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const getEnv = () => {
  let languageEnv;
  if (localStorage.getItem("language")) {
    languageEnv = localStorage.getItem("language");
  } else {
    localStorage.setItem("language", process.env.REACT_APP_LANGUAGE_LOCAL);
    languageEnv = process.env.REACT_APP_LANGUAGE_LOCAL;
  }
  if (process.env.REACT_APP_HOST_LOCAL === window.location.hostname) {
    return {
      host: process.env.REACT_APP_HOST_WP_DEV,
      url: process.env.REACT_APP_BASE_URL_WP_DEV, //process.env.REACT_APP_CEP_DEV_OKTA_ENABLED
      siteId: process.env.REACT_APP_SITE_ID_WP,
      xApiKey: process.env.REACT_APP_XAPIKEY_WP_DEV,
      currency: process.env.REACT_APP_CURRENCY_WP,
      language: process.env.REACT_APP_LANGUAGE_WP,
      displayName: process.env.REACT_APP_DISPLAY_NAME_WP,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_WP,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_WP,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_WP,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_WP,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_WP,
      oktaUrl: process.env.REACT_APP_OKTA_URL,
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
      oktaClientId: process.env.REACT_APP_OKTA_CLIENTID,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      oktaLogin: process.env.REACT_APP_WP_DEV_OKTA_ENABLED,
      loyaltyEnabled: process.env.REACT_APP_WP_DEV_LOYALTY_ENABLED === "true",
      protectedContentTags: process.env.REACT_APP_WP_PROTECTED_CONTENT_TAGS,
      theme: process.env.REACT_APP_THEME
    };
  }
  if (process.env.REACT_APP_HOST_WP_DEV === window.location.hostname) {
    return {
      host: process.env.REACT_APP_HOST_WP_DEV,
      url: process.env.REACT_APP_BASE_URL_WP_DEV, //process.env.REACT_APP_CEP_DEV_OKTA_ENABLED
      siteId: process.env.REACT_APP_SITE_ID_WP,
      xApiKey: process.env.REACT_APP_XAPIKEY_WP_DEV,
      currency: process.env.REACT_APP_CURRENCY_WP,
      language: process.env.REACT_APP_LANGUAGE_WP,
      displayName: process.env.REACT_APP_DISPLAY_NAME_WP,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_WP,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_WP,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_WP,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_WP,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_WP,
      oktaUrl: process.env.REACT_APP_OKTA_URL,
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
      oktaClientId: process.env.REACT_APP_OKTA_CLIENTID,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      oktaLogin: process.env.REACT_APP_WP_DEV_OKTA_ENABLED,
      loyaltyEnabled: process.env.REACT_APP_WP_DEV_LOYALTY_ENABLED === "true",
      protectedContentTags: process.env.REACT_APP_WP_PROTECTED_CONTENT_TAGS,
      theme: process.env.REACT_APP_THEME,
    };
  } else if (process.env.REACT_APP_HOST_CN_DEV === window.location.hostname) {
    // CN env details
    return {
      host: process.env.REACT_APP_HOST_CN_DEV,
      url: process.env.REACT_APP_BASE_URL_CN_DEV,
      siteId: process.env.REACT_APP_SITE_ID_CN,
      xApiKey: process.env.REACT_APP_XAPIKEY_CN_DEV,
      currency: process.env.REACT_APP_CURRENCY_CN,
      language: languageEnv,
      displayName: process.env.REACT_APP_DISPLAY_NAME_CN,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_CN,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_CN,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_CN,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_CN,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_CN,
      theme: "theme2",
    };
  } else if (process.env.REACT_APP_HOST_CN_PROD === window.location.hostname) {
    // CN env details
    return {
      host: process.env.REACT_APP_HOST_CN_DEV,
      url: process.env.REACT_APP_BASE_URL_CN_PROD,
      siteId: process.env.REACT_APP_SITE_ID_CN,
      xApiKey: process.env.REACT_APP_XAPIKEY_CN_PROD,
      currency: process.env.REACT_APP_CURRENCY_CN,
      language: languageEnv,
      displayName: process.env.REACT_APP_DISPLAY_NAME_CN,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_CN,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_CN,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_CN,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_CN,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_CN,
    };
  } else if (process.env.REACT_APP_HOST_CEP_DEV === window.location.hostname) {
    return {
      host: process.env.REACT_APP_HOST_CEP_DEV,
      url:
        process.env.REACT_APP_CEP_DEV_OKTA_ENABLED === "true"
          ? process.env.REACT_APP_BASE_URL_OKTA_CEP_DEV
          : process.env.REACT_APP_BASE_URL_CEP_DEV, //process.env.REACT_APP_CEP_DEV_OKTA_ENABLED
      siteId: process.env.REACT_APP_SITE_ID_CEP,
      xApiKey: process.env.REACT_APP_XAPIKEY_CEP_DEV,
      currency: process.env.REACT_APP_CURRENCY_CEP,
      language: process.env.REACT_APP_LANGUAGE_CEP,
      displayName: process.env.REACT_APP_DISPLAY_NAME_CEP,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_CEP,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_CEP,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_CEP,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_CEP,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_CEP,
      oktaUrl: process.env.REACT_APP_OKTA_URL,
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
      oktaClientId: process.env.REACT_APP_OKTA_CLIENTID,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      oktaLogin: process.env.REACT_APP_CEP_DEV_OKTA_ENABLED,
      loyaltyEnabled: process.env.REACT_APP_CEP_DEV_LOYALTY_ENABLED === "true",
      protectedContentTags: process.env.REACT_APP_CEP_PROTECTED_CONTENT_TAGS,
    };
  } else if (process.env.REACT_APP_HOST_AU_DEV === window.location.hostname) {
    // AU env details
    return {
      host: process.env.REACT_APP_HOST_AU_DEV,
      url:
        process.env.REACT_APP_AU_DEV_OKTA_ENABLED === "true"
          ? process.env.REACT_APP_BASE_URL_OKTA_CEP_DEV
          : process.env.REACT_APP_BASE_URL_AU_DEV, //process.env.REACT_APP_AU_DEV_OKTA_ENABLED
      siteId: process.env.REACT_APP_SITE_ID_AU,
      xApiKey: process.env.REACT_APP_XAPIKEY_AU_DEV,
      currency: process.env.REACT_APP_CURRENCY_AU,
      language: process.env.REACT_APP_LANGUAGE_AU,
      displayName: process.env.REACT_APP_DISPLAY_NAME_AU,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_AU,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_AU,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_AU,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_AU,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_AU,
      oktaUrl: process.env.REACT_APP_OKTA_URL,
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
      oktaClientId: process.env.REACT_APP_OKTA_CLIENTID,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      oktaLogin: process.env.REACT_APP_AU_DEV_OKTA_ENABLED,
      loyaltyEnabled: process.env.REACT_APP_CEP_DEV_LOYALTY_ENABLED === "true",
    };
  } else if (process.env.REACT_APP_HOST_AU_PROD === window.location.hostname) {
    // AU env details
    return {
      host: process.env.REACT_APP_HOST_AU_DEV,
      url: process.env.REACT_APP_BASE_URL_AU_PROD,
      siteId: process.env.REACT_APP_SITE_ID_AU,
      xApiKey: process.env.REACT_APP_XAPIKEY_AU_PROD,
      currency: process.env.REACT_APP_CURRENCY_AU,
      language: process.env.REACT_APP_LANGUAGE_AU,
      displayName: process.env.REACT_APP_DISPLAY_NAME_AU,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_AU,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_AU,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_AU,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_AU,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_AU,
    };
  } else if (process.env.REACT_APP_HOST_NZ_DEV === window.location.hostname) {
    // AU env details
    return {
      host: process.env.REACT_APP_HOST_NZ_DEV,
      url: process.env.REACT_APP_BASE_URL_NZ_DEV,
      siteId: process.env.REACT_APP_SITE_ID_NZ,
      xApiKey: process.env.REACT_APP_XAPIKEY_NZ_DEV,
      currency: process.env.REACT_APP_CURRENCY_NZ,
      language: process.env.REACT_APP_LANGUAGE_NZ,
      displayName: process.env.REACT_APP_DISPLAY_NAME_NZ,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_NZ,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_NZ,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_NZ,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_NZ,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_NZ,
      oktaUrl: process.env.REACT_APP_OKTA_URL,
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
      oktaClientId: process.env.REACT_APP_OKTA_CLIENTID,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
    };
  } else if (process.env.REACT_APP_HOST_NZ_PROD === window.location.hostname) {
    // AU env details
    return {
      host: process.env.REACT_APP_HOST_NZ_DEV,
      url: process.env.REACT_APP_BASE_URL_NZ_PROD,
      siteId: process.env.REACT_APP_SITE_ID_NZ,
      xApiKey: process.env.REACT_APP_XAPIKEY_NZ_PROD,
      currency: process.env.REACT_APP_CURRENCY_NZ,
      language: process.env.REACT_APP_LANGUAGE_NZ,
      displayName: process.env.REACT_APP_DISPLAY_NAME_NZ,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_NZ,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_NZ,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_NZ,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_NZ,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_NZ,
    };
  } else {
    return {
      // host: window.location.hostname,
      // xApiKey: process.env.REACT_APP_XAPIKEY_CN_DEV,
      // // url: `${window.location.protocol}//${window.location.hostname}`,
      // url: process.env.REACT_APP_BASE_URL_CN_DEV,
      // siteId: process.env.REACT_APP_SITE_ID_CN,
      // currency: process.env.REACT_APP_CURRENCY_CN,
      // language: process.env.REACT_APP_LANGUAGE_CN,
      // displayName: process.env.REACT_APP_DISPLAY_NAME_CN,
      // apOpenId: process.env.REACT_APP_OPENID,
      // layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE,
      // displayGridView: process.env.REACT_APP_DISPLAY_GRID_CN,
      // displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_CN,
      // displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_CN,
      // displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_CN,

      host: process.env.REACT_APP_HOST_CEP_DEV,
      url:
        process.env.REACT_APP_CEP_DEV_OKTA_ENABLED === "true"
          ? process.env.REACT_APP_BASE_URL_OKTA_CEP_DEV
          : process.env.REACT_APP_BASE_URL_CEP_DEV, //process.env.REACT_APP_CEP_DEV_OKTA_ENABLED
      siteId: process.env.REACT_APP_SITE_ID_CEP,
      xApiKey: process.env.REACT_APP_XAPIKEY_CEP_DEV,
      currency: process.env.REACT_APP_CURRENCY_CEP,
      language: process.env.REACT_APP_LANGUAGE_CEP,
      displayName: process.env.REACT_APP_DISPLAY_NAME_CEP,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_CEP,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_CEP,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_CEP,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_CEP,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_CEP,
      oktaUrl: process.env.REACT_APP_OKTA_URL,
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
      oktaClientId: process.env.REACT_APP_OKTA_CLIENTID,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      oktaLogin: process.env.REACT_APP_CEP_DEV_OKTA_ENABLED,
      loyaltyEnabled: process.env.REACT_APP_CEP_DEV_LOYALTY_ENABLED === "true",
    };
  }
};

// ----------------------------------
// ----------------------------------
// GENERAL ROUTES FOR PAGES
// ----------------------------------
// ----------------------------------
export const homeAPIUrl = `${getEnv().url}/home`;

// ----------------------------------
// ----------------------------------
// API ENDPOINTS
// ----------------------------------
// ----------------------------------

// ---- Product API endpoint ----
export const productListAPIUrl = `${getEnv().url}/product`; // productList -- ✓ swagger,
export const salesforceProductListAPIUrl = `${getEnv().url}/salesforce/price_list`;

// ---- Navigation API endpoint ----
export const getNavigationsAPIUrl = `${getEnv().url}/navigation`; // getNavigations -- ✓ swagger,

// ---- Order API endpoints ----
export const getOrderHistoryAPIUrl = `${getEnv().url}/order`; // ✓ swagger, ✓ cep-b2b-global
export const getOrderDetailsAPIUrl = `${getEnv().url}/order`; // ✓ swagger, ✓ cep-b2b-global
export const createOrderAPIUrl = `${getEnv().url}/order`; // createOrder - ✓ swagger, ✓ cep-b2b-global
export const cancelOrderAPIUrl = `${getEnv().url}/order`; // ✓ swagger, ✓ cep-b2b-global
export const deleteOrderAPIUrl = `${getEnv().url}/order`; // ✓ swagger, ✓ cep-b2b-global
export const orderInfoAPIUrl = `${getEnv().url}/getOrderSKUDetails`; // x

// ---- Account API endpints ----
export const getCustomerDetailsAPIUrl = `${getEnv().url}/account`; // ✓ swagger,
export const getAccountDetailsAPIUrl = `${getEnv().url}/account`; // ✓ swagger,

// ---- Cart API endpoints ----
// export const addCartAPIUrl = `${url}/addCart`;
export const getCartAPIUrl = `${getEnv().url}/getCart`; // x swagger, ✓ cep-b2b-global
export const updateCartAPIUrl = `${getEnv().url}/updateCart`; // x swagger, ✓ cep-b2b-global
export const clearCartAPIUrl = `${getEnv().url}/clearCart`; // x swagger, ✓ cep-b2b-global
export const getCartEngagementUrl = `${getEnv().url}/cart`; // ✓ swagger, ✓ cep-b2b-global

// ---- Rewards API endpoint ----
export const getRewardsAPIUrl = `${getEnv().url}/rewards`; // ✓ swagger,
export const activityUrl = `${getEnv().url}/activity`; // ✓ swagger, ✓ cep-b2b-global

// ---- Content API endpoint ----
export const getContentAPIUrl = `${getEnv().url}/content`; // ✓ swagger,
export const getContentFaqsAPIUrl = `${getEnv().url}/content/faqs`; // ✓ swagger,

// ---- Auth API endpoints ----
export const registerUserValidateProfileUrl = `${getEnv().url
  }/auth/validateProfile`; // ✓ swagger, ✓ cep-b2b-auth
export const registerUserConfirmCodeUrl = `${getEnv().url}/auth/verifyCode`; // ✓ swagger, ✓ cep-b2b-auth
export const registerUserAuthUrl = `${getEnv().url}/auth`; // ✓ swagger, ✓ cep-b2b-auth
export const forgotPwdEndpoint = `${getEnv().url}/auth/forgotpwd`; // ✓ swagger, ✓ cep-b2b-auth
export const verifyRecoveryTokenEndpoint = `${getEnv().url
  }/auth/forgotpwd/verifyRecovery`; // ✓ swagger, ✓ cep-b2b-auth
export const resetPasswordEndpoint = `${getEnv().url
  }/auth/credentials/reset_password`; // ✓ swagger, ✓ cep-b2b-auth
export const newChangePasswordEndpoint = `${getEnv().url}/auth/changepwd`; // ✓ swagger, ✓ cep-b2b-auth
// ---- Stores ----
export const getStoreAPIUrl = `${getEnv().url}/auth/stores`; // x swagger, ✓ cep-b2b-auth

// ---- Delivery Estimate ----
export const deliveryEstimateAPIUrl = `${getEnv().url}/order/deliveryEstimate`; // x swagger, ✓ cep-b2b-global
export const holidayListAPIUrl = `${getEnv().url}/HolidayList`; // x swagger, ✓ cep-b2b-global

// -----------------------
// -----------------------
// ---- Miscellaneous ----
// -----------------------
// -----------------------
// Need to determine which of these routes are real and if so, what repo they are in

export const getTranslations = `${getEnv().url}/translation`; // getTranslations
export const accessTokenAPIUrl = `${getEnv().url}/auth/login`; //accessToken
export const changePasswordUrl = `${getEnv().url}/changePassword`; //changePassword
export const checkSupervisorUrl = `${getEnv().url}/account/supervisor`; //checkSupervisor
export const validateTokenAPIUrl = `${getEnv().url}/token/validate`; //validateToken
export const forgotPasswordAPIUrl = `${getEnv().url}/forgotPassword`;
export const specialProductListAPIUrl = `${getEnv().url}/specialProductList`;

export const productBrandsAPIUrl = `${getEnv().url}/product/brand`; // productBrands
export const productSubBrandsAPIUrl = `${getEnv().url}/brands/sub`; //productSubBrands
export const productWishListAPIUrl = `${getEnv().url}/wishlist`; //addwishlistitem
export const productRemoveToWishListAPIUrl = `${getEnv().url
  }/removewishlistitem`; //removewishlistitem

export const postCustomerDetailsAPIUrl = `${getEnv().url}/account`;

export const getMyAccountUrl = `${getEnv().url}/myAccount`;
export const getHelpInfoUrl = `${getEnv().url}/getHelpInfo`;
export const getContactUsInfoUrl = `${getEnv().url}/getContactUsInfo`;
export const getTermsDetailsAPIUrl = `${getEnv().url}/getCheckoutCondition`;

export const creditAmountAPIUrl = `${getEnv().url}/creditAmount`;
export const statementOfAccountAPIUrl = `${getEnv().url}/statementOfAccounts`;
export const returnMaterialReasonCodeAPIUrl = `${getEnv().url
  }/returnReasonCodes`;
export const createReturnOrderAPIUrl = `${getEnv().url}/createReturnOrder`;
export const downloadPDFReturnOrderAPIUrl = `${getEnv().url
  }/downloadInvoicePDF`;
export const addReturnOrderImagesAPIUrl = `${getEnv().url
  }/addReturnOrderImages`;
export const getWishListAPIUrl = `${getEnv().url}/wishlist`;
export const clearWishListAPIUrl = `${getEnv().url}/clearwishlist`;
export const orderReportAPIUrl = `${getEnv().url}/reports/orderReport`;
export const getSuggestedOrderAPIUrl = `${getEnv().url}/orders/suggested`;
export const getSuggestedOrderDetailAPIUrl = `${getEnv().url}/orders/suggested`;
export const getCustomerArchiveStatusAPIUrl = `${getEnv().url
  }/getCustomerArchiveStatus`;

// API Header with Site Id
export const apiHeaderWithSiteId = {
  "X-Api-Key": getEnv().xApiKey,
  "Content-Type": "application/json",
  // "Content-Language": process.env.REACT_APP_LANGUAGE,
  "Content-Language": getEnv().language,
  SiteId: getEnv().siteId,
};

// OKTA Flag
export const isOktaLogin = getEnv().oktaLogin === "true";
