export const editOrderCartActionTypes = {
  SET_EDIT_ORDER_DATA: "SET_EDIT_ORDER_DATA",
  UPDATE_EDIT_ORDER_DATA: "UPDATE_EDIT_ORDER_DATA"
};

export const setEditCartDataAction = (data, key) => {
  return {
    type: editOrderCartActionTypes.SET_EDIT_ORDER_DATA,
    data,
  };
};

export const updateEditOrderCartQuantityAction = (data) => {
    return {
        type: editOrderCartActionTypes.UPDATE_EDIT_ORDER_DATA,
        data
    }
}

