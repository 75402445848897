import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import { useStyles } from "./PromotionalOffers.styles";
import { colors, fontSizes } from "../../../styles/variables";
import PromotionalOffersCard from "./PromotionalOffersCard.component";
import { useSelector } from "react-redux";
// import { selectedLanguage } from "../../../store/selectors/language.selector";
import { LocalContext } from "../../../LocalProvider";

const settings = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 5000,
  dots: true,
  arrows: false,
};

const PromotionalOffers = ({ contentData }) => {
  const classes = useStyles();
  // const language = useSelector(selectedLanguage);
  const { selectedLocaleLang } = useContext(LocalContext);

  if (!contentData?.length) {
    return null;
  }
  return (
    <Box sx={{ marginY: "2rem" }}>
      <Box marginBottom={8}>
        <Typography variant="h4" fontSize={fontSizes.font26} fontWeight="bold">
          Promotions
        </Typography>
        <Typography
          variant="caption"
          fontSize={fontSizes.font14}
          color={colors.color16}
        >
          Take advantage of promotional offers.
        </Typography>
        <Box marginBottom={8} marginTop={4}>
          <Slider {...settings} className={classes.slider}>
            {contentData.map((item) => (
              <Box paddingTop={5} marginX={{xs: 1, md: 2}}>
                <PromotionalOffersCard
                  promo={item}
                  key={item.contentId}
                  language={selectedLocaleLang}
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(PromotionalOffers);
