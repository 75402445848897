import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getAuthToken } from "../store/selectors/auth.selector";

const ProtectedRoute = ({ children }) => {
  const token = useSelector(getAuthToken);
  console.log(token);
  const location = useLocation();
  const path = location.pathname; // https://exmaple.com/?path=/products
  if (!token) {
    return <Navigate to="/login" state= {{path: path}} replace/>;
  }
  return children;
};

export default ProtectedRoute;
