import { makeStyles } from "@mui/styles";
import { colors } from "../../styles/variables";

export const useStyles = makeStyles((theme) => ({
    button:{
        '& .MuiButton-startIcon': {
            marginRight: '4px'
        },
        '& .MuiButton-endIcon': {
            marginLeft: '8px'
        } 
    },
    formControl: {
        margin: theme.spacing(0.5),
        minWidth: 90,
        "& > div": {
            backgroundColor: "white",
            "&::before": {
                borderBottom: "none",
                "&::focus": {
                    backgroundColor: colors.color19,
                },
            },
            "&::after": {
                borderBottom: "none",
            },
        },
    },
    formControl1: {
        margin: theme.spacing(0.5),
        minWidth: 90,
        "& > div": {
            backgroundColor: "transparent",
            color: 'white',
            "&::before": {
                borderBottom: "none",
                "&::focus": {
                    backgroundColor: colors.red,
                },
            },
            "&::after": {
                borderBottom: "none",
            },
        },
    },
    menuItemStyle: {
        padding: 0,
    },
    loggedInItemsLabel: {
        width: "100%",
        padding: "0.5rem",
    },
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    icon: {
        marginTop: '8px',
        fill: "#1E1E1E"
    }
})
);