import { postMethod } from "../../utils/api";
import {
  registerUserAuthUrl,
  registerUserConfirmCodeUrl,
  registerUserValidateProfileUrl,
} from "../../utils/urls";

export const registerUserActionTypes = {
  UPDATE_REGISTER_USER_DATA: "UPDATE_REGISTER_USER_DATA",
  FETCHING_REGISTER_USER_DATA: "FETCHING_REGISTER_USER_DATA",
};

export const updateRegisterUserData = (data) => ({
  type: registerUserActionTypes.UPDATE_REGISTER_USER_DATA,
  payload: data,
});
export const fetchingUserData = (data) => ({
  type: registerUserActionTypes.FETCHING_REGISTER_USER_DATA,
  payload: data,
});

export const validateRegisterProfileAsync = (data) => async (dispatch) => {
  try {
    dispatch(fetchingUserData(true));
    let resp = await postMethod(registerUserValidateProfileUrl, data);
    dispatch(fetchingUserData(false));
    return resp;
  } catch (error) {
    console.log(error);
    dispatch(fetchingUserData(false));
    return {
      success: success.false,
      msg: error.msg || "Something went wrong!",
    };
  }
};
export const confirmCodeProfileAsync = (data) => async (dispatch) => {
  try {
    dispatch(fetchingUserData(true));
    let resp = await postMethod(registerUserConfirmCodeUrl, data);
    dispatch(fetchingUserData(false));
    return resp;
  } catch (error) {
    console.log(error);
    dispatch(fetchingUserData(false));
    return {
      success: success.false,
      msg: error.msg || "Something went wrong!",
    };
  }
};
export const createPasswordProfileAsync = (data) => async (dispatch) => {
  try {
    dispatch(fetchingUserData(true));
    let resp = await postMethod(registerUserAuthUrl, data);
    dispatch(fetchingUserData(false));
    return resp;
  } catch (error) {
    console.log(error);
    dispatch(fetchingUserData(false));
    return { success: false, msg: error.msg || "Something went wrong!" };
  }
};
